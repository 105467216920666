<template>
    <v-select
        type="text"
        :modelValue="modelLocal"
        @update:modelValue="handleChange"
        v-model:raw="modelRaw"
        :items="localItems"
        :item-value="itemValue"
        :item-title="itemTitle"
        :placeholder="placeholder"
        :required="required"
        :autocomplete="autocomplete"
        :error-messages="errorMessages"
        :menu-props="{ closeOnContentClick: !('multiple' in $attrs) }"
        :return-object="true"
        :translated="translated"
        :multiple="'multiple' in $attrs"
    >
        <!-- Override the single item template because divider in items not implemented yet in Vuetify 3 -->
        <template
            v-if="!('multiple' in $attrs)"
            v-slot:item="{item, props}"
        >
            <v-list-item v-bind="props" :title="item.title" :value="item.value" />
            <v-divider v-if="item.props.divider === true" />
        </template>
    </v-select>

<!--    <pre><small>-->
<!--        modelLocal : {{ modelLocal }} <br>-->
<!--        modelFinal : {{ modelFinal }} <br>-->
<!--        modelRaw : {{ modelRaw }} <br>-->
<!--        items : {{ localItems }}-->
<!--    </small></pre>-->
</template>

<script>
export default {

    emits: ['update:modelValue', 'update:modelRaw', 'change', 'other'],

    created() {
        //console.log('created with value', this.modelValue)

        // If a model is passed as props
        if(this.modelValue) {

            // Prefill the translated helper field if the initial model is an object and if it contains a translation array
            if(this.translated){
                if(this.modelValue && typeof this.modelValue === 'object' && 'translations' in this.modelValue) {
                    this.modelValue[this.itemTitle] = this.modelValue.translations[this.$i18n.locale][this.itemTitle]
                }
            }

            // Single item
            if(!Array.isArray(this.modelValue)) {

                // If the model is a value, store it directly in the private property + force a retrieval using the full object
                if(typeof this.modelValue === 'string') {
                    this._modelLocal = this.modelValue
                    this.modelLocal = this.modelRaw
                }
                // If the model is an object, store it with the getter to update all models
                else{
                    this.modelLocal = this.modelValue
                }
            }
            // Multiple items
            else{
                if(this.modelValue.length && typeof this.modelValue[0] === 'string') { // NOTE : we use the first item to detect the model type
                    this._modelLocal = this.modelValue
                    this.modelLocal = this.modelRaw
                }
                else{
                    this.modelLocal = this.modelValue
                }
            }
        }
        else{
            // If the model is null, but we asked for a 'other' item, choose this item
            if(this.modelValue === null && this.itemOther && this.localItems.length){
                this.modelLocal = this.localItems[0]

            }
        }

        this.isInitialized = true
    },

    props: {
        // Initial model (could be a raw value (IRI) or an Object)
        modelValue: {
            required: true,
            default: null,
        },
        // List of choices
        items: {
            required: true,
        },
        // Value used internally when choosing an item
        itemValue: {
            required: true,
        },
        // Text used for display when choosing an item
        itemTitle: {
            required: true,
        },
        itemOther: {
            required: false,
            type: String,
        },
        placeholder: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
        // Tell if itemTitle has to be retrieved in a translation object
        translated: {
            required: false,
            type: Boolean,
            default: false,
        },
        // Return the full object or just the itemValue
        returnObject: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            _modelLocal: null,
            isInitialized: false,
        }
    },

    computed: {

        // Model used to store the local value of the select. The value sent to the parent component will be based on this value, but converted in the desired format (Object or Value)
        modelLocal: {
            get() {
                return this._modelLocal
            },
            set(value) {
                this._modelLocal = value

                if(this.modelFinal !== undefined){
                    this.$emit('update:modelValue', this.modelFinal);
                }
                if(this.modelRaw !== undefined){
                    this.$emit('update:modelRaw', this.modelRaw);
                }
            }
        },

        // Helper that return the full model (Object)
        modelRaw() {
            const model = this._modelLocal
            let value = null

            // If the list of choices is already available, retrieve the full model from that list
            if(model && this.localItems && this.localItems.length){

                if(!Array.isArray(model)) {
                    value = this.retrieveObject(model)
                }
                else{
                    value = model.map(item => this.retrieveObject(item))
                }
            }
            // If the list of choices is not already available (for instance if the choices are loaded asynchronously or filtered by another field before)
            else{
                value = model
            }

            return value
        },

        // Helper that return the wanted model (value or Object) based on the returnObject props
        modelFinal(){
            const model = this._modelLocal
            let value = null

            if(model){
                // If we want a value
                if(!this.returnObject) {
                    // Single
                    if(!Array.isArray(model)){
                        value = this.retrieveValue(model)
                    }
                    // Multiple
                    else{
                        value = model.map(item => this.retrieveValue(item))
                    }
                }
                // If we want an object
                else{
                    value = this.modelRaw // Send directly the full model
                }

                return value
            }

        },

        // Helper to automatically fill the translated text one level higher (this way we don't have to search in the nested objects)
        localItems() {

            // Add a first item "Other" if necessary
            if(this.itemOther) {

                const item = {
                    [this.itemValue]: 'other',
                    name: this.itemOther,
                    props: { divider: true },
                }

                if(this.items.length === 0 || (this.items.length > 0 && this.items[0][this.itemValue] !== item[this.itemValue])){
                    this.items.unshift(item)
                }

            }

            if(this.items && this.translated){
                return this.items.map((item) => {
                    if('translations' in item) {
                        item[this.itemTitle] = item.translations[this.$i18n.locale][this.itemTitle]
                    }
                    return item
                })
            }
            else{
                return this.items
            }
        },
    },

    methods: {

        retrieveValue(model) {

            if(typeof model === 'string'){

                // Special case for returning null on the choice 'other'
                if(model === 'other'){
                    this.$emit('other', this.modelFinal)
                    return null
                }

                return model
            }
            else if(typeof model === 'object') {

                // Special case for returning null on the choice 'other'
                if(model[this.itemValue] === 'other'){
                    this.$emit('other', this.modelFinal)
                    return null
                }

                return model[this.itemValue]
            }
        },

        retrieveObject(model) {
            if(typeof model === 'object'){
                return this.localItems.find(item => item[this.itemValue] === model[this.itemValue])
            }
            else{
                return this.localItems.find(item => item[this.itemValue] === model)
            }
        },

        handleChange(value) {

            // When we choose a new value, use the modelLocal setter to set the new value and automatically update all models
            this.modelLocal = value

            // Trigger an event to know that the value changed (we can't listen to update:modelValue because this will be trigger on load too)
            this.$emit('change', this.modelFinal)
        },

    },

    watch: {

        // Listen for changes in the list of choices (that could eventually change in the parent component after an asynchronous call)
        items: {
            handler(newVal, oldVal) {
                // We use the modelLocal setter to force an update by passing the full data object, the setter will recompute the value from the updated list of choices
                this.modelLocal = this.modelRaw
            }
        }

    }

}
</script>
