<template>
    <v-textarea
        type="text"
        v-model="modelLocal"
        :placeholder="placeholder"
        :required="required"
        :autocomplete="autocomplete"
        :error-messages="errorMessages"
        auto-grow
        :class="{ 'with-button': button }"
    >
<!--        <template v-slot:append="{ modelValue }">-->
<!--            <span v-html="modelValue"></span>-->
<!--        </template>-->

        <template v-if="button"
                  v-slot:append="{ }"
        >
            <v-btn class="button button-small"
                   @click.native="$emit('click:button', null)"
            >
                {{ button }}
            </v-btn>
        </template>

    </v-textarea>
</template>

<script>
export default {

    emits: ['update:modelValue', 'click:button'],

    props: {
        modelValue: {
            required: true,
        },
        placeholder: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
        button: {
            required: false,
            type: String,
        },
    },

    computed: {

        modelLocal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },

    },

}
</script>
