<template>
    <div>
        <PageHeader noburger
                    :class="{ 'additional-offset': $isSmallScreen() }"
        >
            <template v-slot:left>
                <div class="h2">{{ $t('app.page.parameters.agency.title') }}</div>
            </template>

            <template v-slot:right>
                <transition name="fade">
                    <div class="buttons" v-if="validator">

                        <v-btn class="button button-small button-secondary no-text-mobile"
                               :v-if="authStore?.undertaker?.enabled && authStore?.undertakerConfig?.pageUndertaker"
                               :disabled="isWaiting || validator.$invalid || validator.$dirty"
                               :href="authStore?.undertakerConfig?.pageUndertaker"
                               target="_blank"
                               append-icon="icon-eye-opened"
                        >
                            {{ $t('app.global.actions.preview') }}
                        </v-btn>

                        <v-btn class="button button-alt button-small no-text-mobile no-icon-desktop"
                               :disabled="isWaiting || validator.$invalid || !validator.$dirty"
                               :append-icon="'icon-save'"
                               @click="handleSave"
                        >
                            {{ $t('app.global.actions.save_modifications') }}
                        </v-btn>

                    </div>
                </transition>
            </template>
        </PageHeader>

        <PageContent>

            <Overlay :visible="isWaiting || !isLoaded"></Overlay>
            <transition name="fade">
                <div class="page-limiter"
                     v-if="isLoaded"
                >

                    <div>{{ $t('app.page.parameters.agency.content.intro.text') }}</div>

                    <Notice :isVisible="isImportantFieldsEmpty">{{ $t('app.page.parameters.agency.content.intro.notice') }}</Notice>

                    <Form
                        :data="data"
                        :validations="validations"
                        ref="form"
                        @mounted="handleFormMounted"
                        @valid="handleSubmit"
                    >
                        <v-col :md="12">
                            <div class="title h1">{{ $t('app.page.parameters.agency.content.details.title') }}</div>
                        </v-col>

                        <Input
                            type="InputTranslation"
                            :cols="12"
                            :label="$t('app.page.parameters.agency.form.name.label')"
                            :placeholder="$t('app.page.parameters.agency.form.name.placeholder')"
                            v-model="data.undertaker.translations"
                            field="name"
                            path="data.undertaker.translations.{locale}.name"
                            required
                            important
                        >
                        </Input>

                        <Input
                            :cols="6"
                            :label="$t('app.page.parameters.agency.form.address1.label')"
                            :placeholder="$t('app.page.parameters.agency.form.address1.placeholder')"
                            v-model="data.undertaker.address1"
                            path="data.undertaker.address1"
                            required
                            important
                        >
                        </Input>

                        <Input
                            :cols="6"
                            :label="$t('app.page.parameters.agency.form.address2.label')"
                            :placeholder="$t('app.page.parameters.agency.form.address2.placeholder')"
                            v-model="data.undertaker.address2"
                            path="data.undertaker.address2"
                        >
                        </Input>

                        <Input
                            type="InputAutocomplete"
                            :cols="6"
                            :label="$t('app.page.parameters.agency.form.city.label')"
                            :placeholder="$t('app.page.parameters.agency.form.city.placeholder')"
                            v-model="data.undertaker.city"
                            path="data.undertaker.city"
                            @search="autocompleteCity"
                            itemValue="@id"
                            itemTitle="text"
                            :translated="true"
                            required
                            important
                            @update:modelValue="validator?.$touch()"
                        >
                        </Input>

                        <Input
                            type="InputPhone"
                            :cols="6"
                            :label="$t('app.page.parameters.agency.form.phone.label')"
                            :placeholder="$t('app.page.parameters.agency.form.phone.placeholder')"
                            v-model="data.undertaker.phone"
                            path="data.undertaker.phone"
                            required
                            important
                        >
                        </Input>

                        <v-col :md="12">
                            <div class="text-notice">
                                {{ $t('app.global.messages.required_fields') }}
                            </div>
                            <div class="spacer"></div>
                            <div class="title h1">{{ $t('app.page.parameters.agency.content.about.title') }}</div>
                            <div class="">{{ $t('app.page.parameters.agency.content.about.text') }}</div>
                        </v-col>

                        <Input
                            type="InputTranslation"
                            textarea
                            :cols="12"
                            :label="$t('app.page.parameters.agency.form.description.label')"
                            :placeholder="$t('app.page.parameters.agency.form.description.placeholder')"
                            v-model="data.undertaker.translations"
                            field="description"
                            path="data.undertaker.translations.{locale}.description"
                            important
                        >
                        </Input>

                        <Input
                            type="InputTranslation"
                            textarea
                            :cols="12"
                            :label="$t('app.page.parameters.agency.form.emphasis.label')"
                            :placeholder="$t('app.page.parameters.agency.form.emphasis.placeholder')"
                            v-model="data.undertaker.translations"
                            field="emphasis"
                            path="data.undertaker.translations.{locale}.emphasis"
                            :rows="2"
                        >
                        </Input>

                        <v-col :md="12">
                            <div class="spacer"></div>
                            <div class="title h1">{{ $t('app.page.parameters.agency.content.services.title') }}</div>
                            <div class="">{{ $t('app.page.parameters.agency.content.services.text') }}</div>

                            <div class="service-offering-categories">
                                <template v-for="serviceOfferingCategory in globalStore.config.serviceOfferingCategories">
                                    <div class="service-offering-category"
                                         v-if="serviceOfferingCategory.published"
                                    >

                                        <div v-if="serviceOfferingCategory?.picto?.picture"
                                             class="service-offering-category-icon"
                                        >
                                            <img :src="serviceOfferingCategory.picto.picture.media_preview">
                                        </div>

                                        <div class="name h3">
                                            {{ this.$trans(serviceOfferingCategory, 'name')}}
                                        </div>

                                        <div class="service-offerings">
                                            <template v-for="serviceOffering in serviceOfferingCategory.serviceOfferings">
                                                <div class="service-offering"
                                                     v-if="serviceOffering.published"
                                                >
                                                    <div class="name">
                                                        {{ this.$trans(serviceOffering, 'name')}}
                                                    </div>
                                                    <v-switch
                                                        v-model="data.undertaker.serviceOfferings"
                                                        :value="serviceOffering['@id']"
                                                    ></v-switch>

                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </v-col>

                        <v-row class="padded" no-gutters>
                            <v-col :md="9">
                                <div class="spacer"></div>
                                <div class="title h1">{{ $t('app.page.parameters.agency.content.localisation.title') }}</div>
                                <div class="">{{ $t('app.page.parameters.agency.content.localisation.text') }}</div>
                            </v-col>

                            <v-col
                                v-if="globalStore.config.pageHelpIframeGoogleMaps"
                                md="auto" align-self="center" class="ml-auto">
                                <v-btn class="button button-small block"
                                       append-icon="icon-link-external"
                                       target="_blank"
                                       :href="globalStore.config.pageHelpIframeGoogleMaps"
                                >
                                    {{ $t('app.page.parameters.agency.content.localisation.button') }}
                                </v-btn>
                            </v-col>
                        </v-row>

                        <Input
                            :cols="12"
                            :label="$t('app.page.parameters.agency.form.mapIframe.label')"
                            :placeholder="$t('app.page.parameters.agency.form.mapIframe.placeholder')"
                            v-model="data.undertaker.mapIframe"
                            path="data.undertaker.mapIframe"
                        >
                        </Input>

                        <v-col :md="12">
                            <div class="spacer"></div>
                            <div class="title h1">{{ $t('app.page.parameters.agency.content.pictures.title') }}</div>
                            <div class="font-weight-bold">{{ $t('app.page.parameters.agency.content.pictures.format') }}</div>
                            <div class="">{{ $t('app.page.parameters.agency.content.pictures.text_main') }}</div>
                        </v-col>

                        <Input
                            type="InputUpload"
                            :cols="6"
                            :label="$t('app.page.parameters.agency.form.pictures.main.label')"
                            placeholder=""
                            v-model="data.undertaker.mainPicture"
                            path="data.undertaker.mainPicture"
                            subentity
                            required
                            important
                        >
                        </Input>

                        <Input
                            type="InputUpload"
                            :cols="6"
                            :label="$t('app.page.parameters.agency.form.pictures.logo.label')"
                            placeholder=""
                            v-model="data.undertaker.logo"
                            path="data.undertaker.logo"
                            subentity
                            required
                            important
                        >
                        </Input>

                        <Input
                            type="InputUpload"
                            :cols="12"
                            :label="$t('app.page.parameters.agency.form.pictures.other.label')"
                            :placeholder="$t('app.page.parameters.agency.content.pictures.text_other')"
                            v-model="data.undertaker.pictures"
                            path="data.undertaker.pictures"
                            subentity
                            multiple
                        >
                        </Input>

                        <v-col :md="12">
                            <div class="spacer"></div>
                            <div class="title h1">{{ $t('app.page.parameters.agency.content.announcement.title') }}</div>
                            <div class="">{{ $t('app.page.parameters.agency.content.announcement.text') }}</div>
                        </v-col>

                        <Input
                            :cols="12"
                            :label="$t('app.page.parameters.agency.form.announcementUrl.label')"
                            :placeholder="$t('app.page.parameters.agency.form.announcementUrl.placeholder')"
                            v-model="data.undertaker.announcementUrl"
                            path="data.undertaker.announcementUrl"
                        >
                        </Input>

                    </Form>

<!--                    <pre><small>{{ data.undertaker }}</small></pre>-->

                </div>
            </transition>
        </PageContent>

    </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store'
import { useAuthStore } from '@/stores/auth.store'
import { useMessageStore } from '@/stores/message.store'

import PageHeader from "@/components/commons/PageHeader.vue"
import PageContent from "@/components/commons/PageContent.vue"
import Overlay from "@/components/commons/Overlay.vue"
import Form from "@/components/commons/Form.vue"
import Input from "@/components/commons/Input.vue"
import Notice from "@/components/commons/Notice.vue"
import { required, isGoogleMaps, url } from '@/helpers.validators'

import UndertakersService from '@/middleware/undertakers';
import { autocompleteCity } from '@/helpers.autocomplete'
import { helpers } from "@vuelidate/validators"

export default {
    components: { PageHeader, PageContent, Overlay, Form, Input, Notice },

    setup() {
        const globalStore = useGlobalStore()
        const authStore = useAuthStore()
        const messageStore = useMessageStore()

        return {
            globalStore,
            authStore,
            messageStore,
        }
    },

    data() {
        return {
            data: {
                undertaker: {
                    address1: null,
                    address2: null,
                    phone: null,
                    city: null,
                    mapIframe: null,
                    announcementUrl: null,
                    mainPicture: null,
                    logo: null,
                    pictures: null,
                    translations: {
                        fr: {
                            name: null,
                            description: null,
                            emphasis: null,
                        },
                    },
                },
            },
            validations: {
                data: {
                    undertaker: {
                        address1: { required },
                        // address2: { required },
                        phone: { required },
                        city: { required },
                        mapIframe: {
                            isGoogleMaps : helpers.withMessage(
                                this.$t('app.validations.iframegooglemaps'), // Custom error message
                                isGoogleMaps
                            )
                        },
                        announcementUrl: { url },
                        // mainPicture: { required },
                        // logo: { required },
                        // pictures: { required },
                        translations: {
                            fr: {
                                name: { required },
                                description: { required },
                                // emphasis: { required },
                            },
                        },
                    },
                },
            },
            validator: null,
            isWaiting: false,
            isLoaded: false,
        }
    },

    mounted() {

        // Load the initial data
        this.loadData()

    },


    computed: {

        isImportantFieldsEmpty() {
            return this.$isNullOrEmpty(this.data.undertaker.translations, 'name')
                || this.$isNullOrEmpty(this.data.undertaker.address1)
                || this.$isNullOrEmpty(this.data.undertaker.city)
                || this.$isNullOrEmpty(this.data.undertaker.phone)
                || this.$isNullOrEmpty(this.data.undertaker.translations, 'description')
                || this.$isNullOrEmpty(this.data.undertaker.logo?.picture?.id)
                || this.$isNullOrEmpty(this.data.undertaker.mainPicture?.picture?.id)
        }

    },

    methods: {

        /* region [imported method binding] */

        autocompleteCity: autocompleteCity,

        /* endregion */

        /* region [data manipulation] */

        loadData() {

            UndertakersService.get(this.authStore.undertakerId)
            .then((response) => {
                this.data.undertaker = this.$extract(response)
                this.isLoaded = true
            })

        },

        /* endregion */

        /* region [event handlers] */

        handleFormMounted(validator){
            this.validator = validator
        },

        handleSave(){
            this.$refs.form.submit()
        },

        handleSubmit(){

            this.isWaiting = true

            UndertakersService.update(this.authStore.undertakerId, this.data.undertaker)
            .then((response) => {
                this.messageStore.addInfo(this.$t('app.global.messages.saved_with_success'))
                this.validator.$reset(); // Reset the form validator state
            })
            .finally(() => {
                this.isWaiting = false
            });
        },

        /* endregion */
    },

}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.page-header {
    top: $header-height + 1px; // Override the sticky position

    &.additional-offset{
        top: $header-height + $header-height + 1px;
    }
}

::v-deep(.form){
    padding-top: 20px;
}

.service-offering-categories{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 20px;

    @media (min-width: $s-mobile-md) {
        grid-template-columns: repeat(2, 1fr);
    }

    .service-offering-category{
        background-color: #ffffff;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .service-offering-category-icon{
            width: 100px;
            height: 100px;
            margin-bottom: 20px;

            img{
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        > .name{
            margin-bottom: 20px;
        }

        .service-offerings{
            width: 100%;

            .service-offering{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &:not(:last-child):not(:only-child){
                    border-bottom: 1px solid $c-border;
                }

                > .name {

                }

                &::v-deep(.v-selection-control){
                    justify-content: flex-end;
                }
            }
        }
    }
}

.title{
    margin-bottom: 8px;
}
</style>

