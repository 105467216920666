import { defineStore } from 'pinia'
export const useMessageStore = defineStore('message', {
    state: () => ({
        messages: [],
    }),
    getters: {
    },
    actions: {

        addError(text, delay) {
            this.addMessage(text, delay, 'error')
        },

        addInfo(text, delay) {
            this.addMessage(text, delay, 'info')
        },

        addMessage(text, delay = 5000, type = 'message') {

            // Instantiate the message
            let message = {
                text: text,
                type: type,
                delay: delay,
            }

            // Create a timeout to automatically remove the message from the list after a delay
            message.timeout = setTimeout(() => {
                const index = this.messages.indexOf(message);
                if (index > -1) { // only splice array when item is found
                this.messages.splice(index, 1);
                }
            }, message.delay)

            // Add the message to the queue
            this.messages.push(message)
        },

        clearMessages(){

            // Clear all timeouts
            for (const message of this.messages) {
                clearTimeout(message.timeout)
            }

            // Clear the queue
            this.messages = []
        },
    },
})
