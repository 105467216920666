<template>
    <v-text-field
        type="text"
        v-model="modelLocal"
        :placeholder="placeholder"
        :required="required"
        :autocomplete="autocomplete"
        :error-messages="getErrors()"
    ></v-text-field>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { helpers } from "@vuelidate/validators"
import { isPhoneNumber, formatPhoneNumber } from '@/helpers.validators.js'

export default {

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            required: true,
        },
        placeholder: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
        country: {
            required: false,
            type: String,
            default: 'FR'
        },
    },

    data() {
        return {
            phone: null,
            phoneRaw: null,
        }
    },

    setup() {
        return {
            v$: useVuelidate(),
        }
    },

    validations() {
        return {
            modelLocal: {
                isPhoneNumber : helpers.withMessage(
                    this.$t('app.validations.phone'), // Custom error message
                    isPhoneNumber(this.country) // Passing a reactive property to the custom validator
                )
            },
        }
    },

    computed: {

        modelLocal: {
            get() {
                return this.modelValue
            },
            set(value) {
                // If the phone format is valid, the final value is formatted, if not, we return the raw value
                const formattedValue = formatPhoneNumber(value, this.country) ?? value

                this.$emit('update:modelValue', formattedValue)
            }
        },

    },

    methods: {

        getErrors(){
            // Concat errors from parent and local validators
            return [...this.errorMessages, ...this.v$.modelLocal.$errors.map(e => e.$message)]
        },

    },

}
</script>

<style lang="scss" scoped>

</style>
