<template>
    <v-dialog
        v-model="_isVisible"
        width="auto"
        max-width="500px"
        scrim="#000000"
    >
        <v-card>

            <v-btn
                class="button-close button button-small button-invisible"
                icon="icon-cross"
                @click="handleClickCancel"
            >
            </v-btn>

            <v-card-title class="h1">
                {{ title }}
            </v-card-title>


            <v-form
                @submit.stop.prevent="handleClickSubmit"
            >

                <v-card-text>
                    {{ text }}

                    <div class="spacer"></div>

                    <v-text-field
                        class="input"
                        :class="{ 'small' : inputSmall }"
                        :type="inputType"
                        v-model="input"
                        :autocomplete="false"
                        autofocus
                    >
                    </v-text-field>

                </v-card-text>

                <v-card-actions>

                    <v-btn
                        class="button button-neutral"
                        @click="handleClickCancel"
                    >
                        {{ buttonNo ?? $t('app.global.actions.cancel') }}
                    </v-btn>

                    <v-btn
                        class="button"
                        @click="handleClickSubmit"
                    >
                        {{ buttonYes ?? $t('app.global.actions.send') }}
                    </v-btn>

                </v-card-actions>

            </v-form>

        </v-card>
    </v-dialog>
</template>

<script>
export default {

    emits: ['submit', 'cancel'],

    props: {
        isVisible: {
            required: true,
            type: Boolean,
        },
        title: {
            required: true,
            type: String,
        },
        text: {
            required: true,
            type: String,
        },
        buttonYes: {
            required: false,
            type: String,
        },
        buttonNo: {
            required: false,
            type: String,
        },
        inputType: {
            required: false,
            type: String,
            default: 'text',
        },
        inputSmall: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    data () {
        return {
            _isVisible: null,
            input: null,
        }
    },

    mounted() {
        this._isVisible = this.isVisible
    },

    methods: {

        handleClickCancel(){
            this.$emit('cancel')
        },

        handleClickSubmit(){
            this.$emit('submit', this.input)
        },

    },

    watch: {
        isVisible(value) {

            this._isVisible = value

            // When the popup become visible, empty the field
            if (this._isVisible) {
                this.input = null
            }
        }
    },

}
</script>

<style lang="scss" scoped>

.button-close{
    position: absolute;
    top: 0;
    right: 0;
}

.input{
    margin: 0 auto;

    &::v-deep(input) {
        text-align: center;
        font-size: 22px;
        padding: 8px;
    }

    &.small{
        width: 100px;
    }
}
</style>