<template>
    <div>

        <PageHeader noburger
                    :class="{ 'additional-offset': $isSmallScreen() }"
        >
            <template v-slot:left>
                <div class="h2">{{ $t('app.page.parameters.establishments.title') }}</div>
            </template>
        </PageHeader>

        <PageContent>
            <Overlay :visible="isWaiting || !isLoaded"></Overlay>
            <transition name="fade">
                <div class="page-limiter"
                     v-if="isLoaded"
                >

                    <div>{{ $t('app.page.parameters.establishments.content.intro.text') }}</div>

                    <Notice :isVisible="isImportantFieldsEmpty">{{ $t('app.page.parameters.establishments.content.intro.notice') }}</Notice>

                    <div class="heading">
                        <div class="h2">{{ $t('app.page.parameters.establishments.content.funerariums.title') }} (*)</div>
                        <v-btn class="button button-small button-add"
                               @click="handleAddEstablishment(CATEGORY_FUNERARIUM)"
                        >
                            {{ $t('app.page.parameters.establishments.content.funerariums.add') }}
                        </v-btn>
                    </div>

                    <div class="establishments funerariums">
                        <TransitionGroup name="fade">
                            <div
                                v-for="funerarium in filterEstablishmentsByCategory(CATEGORY_FUNERARIUM)"
                                :key="funerarium.id"
                                class="establishment funerarium"
                                :class="{ 'empty-price' : isMandatoryEstablishmentPricesEmpty(funerarium) }"
                            >
                                <div class="infos">
                                    <div class="name">
                                        {{ this.$trans(funerarium.establishment, 'name')}}
                                    </div>
                                    <div class="address">
                                        {{ funerarium.establishment.fullAddress }}
                                    </div>
                                </div>

                                <div class="buttons">
                                    <v-btn class="button button-small button-neutral button-edit"
                                           :disabled="isDeleting"
                                           @click="handleEditEstablishment(funerarium)"
                                    >
                                        {{ $t('app.global.actions.see') }}
                                    </v-btn>
                                    <v-btn class="button button-icon button-invisible button-delete"
                                           icon="icon-trash"
                                           :disabled="isDeleting"
                                           @click="handleDeleteEstablishment(funerarium)"
                                    >
                                    </v-btn>
                                </div>

                            </div>
                        </TransitionGroup>
                    </div>


                    <div class="heading">
                        <div class="h2">{{ $t('app.page.parameters.establishments.content.crematoriums.title') }} (*)</div>
                        <v-btn class="button button-small button-add"
                               @click="handleAddEstablishment(CATEGORY_CREMATORIUM)"
                        >
                            {{ $t('app.page.parameters.establishments.content.crematoriums.add') }}
                        </v-btn>
                    </div>

                    <div class="establishments crematoriums">
                        <TransitionGroup name="fade">
                            <div
                                v-for="crematorium in filterEstablishmentsByCategory(CATEGORY_CREMATORIUM)"
                                :key="crematorium.id"
                                class="establishment crematorium"
                                :class="{ 'empty-price' : isMandatoryEstablishmentPricesEmpty(crematorium) }"
                            >
                                <div class="infos">
                                    <div class="name">
                                        {{ this.$trans(crematorium.establishment, 'name')}}
                                    </div>
                                    <div class="address">
                                        {{ crematorium.establishment.fullAddress }}
                                    </div>
                                </div>

                                <div class="buttons">
                                    <v-btn class="button button-small button-neutral button-edit"
                                           :disabled="isDeleting"
                                           @click="handleEditEstablishment(crematorium)"
                                    >
                                        {{ $t('app.global.actions.see') }}
                                    </v-btn>
                                    <v-btn class="button button-icon button-invisible button-delete"
                                           icon="icon-trash"
                                           :disabled="isDeleting"
                                           @click="handleDeleteEstablishment(crematorium)"
                                    >
                                    </v-btn>
                                </div>

                            </div>
                        </TransitionGroup>
                    </div>

                    <div class="text-notice">
                        {{ $t('app.global.messages.required_fields') }}
                    </div>
<!--                    <pre style="line-height: 1;"><small>{{ data }}</small></pre>-->

                </div>
            </transition>
        </PageContent>

        <teleport to="#contextual-drawer">
            <transition name="fade">
                <div v-if="globalStore.isDrawerOpened"
                     class="stretcher"
                >

                    <template v-if="globalStore.drawerMode === 'establishment'">

                        <!-- Passing the key here will automatically recreate the component if the id change -->
                        <FormEstablishment
                            :key="undertakerEstablishmentId"
                            :id="undertakerEstablishmentId"
                            :establishmentCategory="undertakerEstablishmentCategory"
                            :existingEstablishments="data.establishments.map(item => item.establishment)"
                            :services="services"
                            @cancel="handleCancel()"
                            @create="handleSubmit()"
                            @update="handleSubmit()"
                        />

                    </template>

                </div>
            </transition>
        </teleport>

    </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store'
import { useAuthStore } from '@/stores/auth.store'
import { useMessageStore } from '@/stores/message.store'

import PageHeader from "@/components/commons/PageHeader.vue"
import PageContent from "@/components/commons/PageContent.vue"
import Overlay from "@/components/commons/Overlay.vue"
import Notice from "@/components/commons/Notice.vue"
import FormEstablishment from "@/components/forms/FormEstablishment.vue"

import ServicePricingCategoriesService from '@/middleware/servicePricingCategories'
import EstablishmentCategoriesService from '@/middleware/establishmentCategories'
import UndertakersService from '@/middleware/undertakers'
import UndertakerEstablishmentsService from '@/middleware/undertakerEstablishments'

export default {
    components: { PageHeader, PageContent, Overlay, Notice, FormEstablishment },

    setup() {
        const globalStore = useGlobalStore()
        const authStore = useAuthStore()
        const messageStore = useMessageStore()

        return {
            globalStore,
            authStore,
            messageStore,
        }
    },

    data() {
        return {
            data: null,
            servicePricingCategories: null,
            establishmentCategories: null,
            undertakerEstablishmentId: null,
            undertakerEstablishmentCategory: null,
            validations: {
                data: {
                },
            },
            validator: null,
            isWaiting: false,
            isDeleting: false,
            isEstablishmentCategoriesLoaded: false,
            isEstablishmentsLoaded: false,
            isPricingCategoriesLoaded: false,
        }
    },

    created() {

        // Set the constants here, because standard constants will not be available in vue templates
        this.CATEGORY_FUNERARIUM = 'funeral-home';
        this.CATEGORY_CREMATORIUM = 'crematorium';

    },

    mounted() {

        // Load the initial data
        this.loadData()

    },

    computed: {

        // Helper to know that everything is loaded
        isLoaded() {
            return this.isEstablishmentCategoriesLoaded && this.isEstablishmentsLoaded && this.isPricingCategoriesLoaded
        },

        isImportantFieldsEmpty() {

            // Check that all mandatory prices in the establishments are filled
            const allMandatoryEstablishmentPricesFilled = this.data.establishments.some((establishment) => {
                    return this.isMandatoryEstablishmentPricesEmpty(establishment)
                }
            );

            // There is at least one funerarium, one crematorium, and mandatory prices are filled
            return this.filterEstablishmentsByCategory(this.CATEGORY_FUNERARIUM).length === 0
                || this.filterEstablishmentsByCategory(this.CATEGORY_CREMATORIUM).length === 0
                || allMandatoryEstablishmentPricesFilled
        },

    },
    methods: {

        /* region [data manipulation] */

        loadData() {

            // Retrieve the necessary establishment categories
            EstablishmentCategoriesService.getByCodes([this.CATEGORY_FUNERARIUM, this.CATEGORY_CREMATORIUM])
            .then((response) => {
                this.establishmentCategories = this.$extract(response)
                this.isEstablishmentCategoriesLoaded = true
            })

            // Retrieve the pricing categories that are specific to establishment categories
            ServicePricingCategoriesService.getAllSpecific()
            .then((response) => {
                this.services = this.$extract(response)
                this.isPricingCategoriesLoaded = true
            })

            // Retrieve the establishments list
            this.refreshData()
        },

        refreshData() {

            this.isWaiting = true
            // Retrieve the current undertaker establishments
            UndertakersService.getEstablishments(this.authStore.undertakerId)
                .then((response) => {
                    this.data = this.$extract(response)
                    this.isEstablishmentsLoaded = true
                })
                .finally(() => {
                this.isWaiting = false
            });
        },

        /* endregion */

        /* region [event handlers] */

        handleAddEstablishment(establishmentCategoryCode) {
            // Set the id to null to create a new entry
            this.undertakerEstablishmentId = null

            // Set the category object to make it accessible by the form
            this.undertakerEstablishmentCategory = this.establishmentCategories.filter((category) => category.code === establishmentCategoryCode).shift()

            // Open the drawer
            this.globalStore.drawerMode = 'establishment'
            this.globalStore.isDrawerOpened = true
        },

        handleEditEstablishment(undertakerEstablishment) {

            const establishmentCategoryCode = undertakerEstablishment.establishment.category.code

            // Set the id to make it accessible by the form
            this.undertakerEstablishmentId = undertakerEstablishment.id

            // Set the category object to make it accessible by the form
            this.undertakerEstablishmentCategory = this.establishmentCategories.filter((category) => category.code === establishmentCategoryCode).shift()

            // Open the drawer
            this.globalStore.drawerMode = 'establishment'
            this.globalStore.isDrawerOpened = true
        },

        handleDeleteEstablishment(establishment) {

            // Delete the establishment
            this.isDeleting = true
            UndertakerEstablishmentsService.delete(establishment.id)
            .then((response) => {
                // this.messageStore.addInfo(this.$t('app.global.messages.saved_with_success'))

                this.data.establishments = this.data.establishments.filter((item) => item.id !== establishment.id)

                // Refresh the data
                this.refreshData()
            })
            .finally(() => {
                this.isDeleting = false
            });

        },

        handleCancel() {
            // Close the drawer
            this.globalStore.drawerMode = null
            this.globalStore.isDrawerOpened = false
        },

        handleSubmit() {
            // Close the drawer
            this.globalStore.drawerMode = null
            this.globalStore.isDrawerOpened = false

            // Refresh the data
            this.refreshData()
        },

        /* endregion */

        /* region [helpers] */

        // Filter the establishments list to retrieve only a specific category of establishments
        filterEstablishmentsByCategory(establishmentCategoryCode) {
            return this.data.establishments.filter((establishment) => {
                if(establishment.establishment.category.code === establishmentCategoryCode) {
                    return establishment
                }
            })
        },

        // Check that all mandatory prices in the establishment are filled
        isMandatoryEstablishmentPricesEmpty(establishment) {

            return establishment.servicePricings.some(
                (servicePricing) => {
                    return servicePricing.servicePricing.mandatory ? servicePricing.price === null || servicePricing.price === 0 : false
                }
            )

        },

        /* endregion */

    },

}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.page-header {
    top: $header-height + 1px; // Override the sticky position

    &.additional-offset{
        top: $header-height + $header-height + 1px;
    }
}

.heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.establishments{
    $gap: 20px;
    display: flex;
    flex-direction: column;
    gap: $gap;
    margin-top: $gap;
    margin-bottom: $gap;

    .establishment{
        background-color: #ffffff;
        padding: 30px;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border: 1px solid transparent;
        transition: border $t-duration ease;

        @media (min-width: $s-mobile) {
            flex-direction: row;
        }

        &.empty-price{
            border-color: $c-primary;
        }

        .infos{

            .name{
                font-weight: bold;
            }

            .address{

            }

        }

        .buttons{
            display: flex;
            align-items: center;
            gap: 10px;
            flex: 1;

            @media (min-width: $s-mobile) {
                flex: none;
                margin-left: auto;
            }

            .button-edit{
                flex: 1;
            }

        }
    }
}
</style>

