<template>
    <v-text-field
        type="text"
        v-model="modelLocal"
        :placeholder="placeholder"
        :required="required"
        :autocomplete="autocomplete"
        :error-messages="errorMessages"
    ></v-text-field>
</template>

<script>
export default {

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            required: true,
        },
        placeholder: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
    },

    computed: {

        modelLocal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },

    },

}
</script>
