<template>
    <div>

        <PageHeader noburger
                    :class="{ 'additional-offset': $isSmallScreen() }"
        >
            <template v-slot:left>
                <div class="h2">{{ $t('app.page.parameters.products.title') }}</div>
            </template>
        </PageHeader>

        <PageContent>
            <Overlay :visible="isWaiting || !isLoaded"></Overlay>
            <transition name="fade">
                <div class="page-limiter"
                     v-if="isLoaded"
                >
                    <div>{{ $t('app.page.parameters.products.content.intro.text') }}</div>


                    <template v-for="productCategory in this.productCategories">

                        <div class="product-category">

                            <div class="heading">
                                <div class="h2">
                                    {{ productCategory.translations[$i18n.locale].name }}
                                </div>
                                <v-btn class="button button-small button-add"
                                       @click="handleAddProduct(productCategory)"
                                >
                                    {{ $t('app.global.actions.add') }}
                                </v-btn>
                            </div>

                            <div class="products">

                                <TransitionGroup name="fade">
                                    <template v-for="product in filterProductsByCategory(productCategory)"
                                              :key="product.id"
                                    >

                                        <v-card class="product"
                                                @click="handleEditProduct(product)"
                                        >
                                            <div class="wrapper">

                                                <div class="picture-and-infos">
                                                    <div class="picture">
                                                        <img v-if="product?.mainPicture?.picture?.media_preview"
                                                             :src="product.mainPicture.picture.media_preview"
                                                        >
                                                    </div>

                                                    <div class="infos">
                                                        <div class="name">
                                                            {{ this.$trans(product, 'name') }}
                                                        </div>

                                                        <div class="material">
                                                            {{ this.$trans(product, 'material') }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="price-and-buttons">
                                                    <div class="price">
                                                        {{ product.price }} €
                                                    </div>

                                                    <div class="buttons">
                                                        <v-btn class="button button-icon button-invisible button-delete"
                                                               icon="icon-trash"
                                                               :disabled="isDeleting"
                                                               @click.stop="handleDeleteProduct(product)"
                                                        >
                                                        </v-btn>
                                                    </div>
                                                </div>

                                            </div>
                                        </v-card>

                                    </template>
                                </TransitionGroup>

                            </div>
                        </div>

                    </template>

<!--                    <pre style="line-height: 1;"><small>{{ data }}</small></pre>-->

                </div>
            </transition>
        </PageContent>

        <teleport to="#contextual-drawer">
            <transition name="fade">
                <div v-if="globalStore.isDrawerOpened">

                    <template v-if="globalStore.drawerMode === 'product'">

                    <!-- Passing the key here will automatically recreate the component if the id change -->
                    <FormProduct
                        :key="productId"
                        :id="productId"
                        :productCategory="productCategory"
                        :productCategories="productCategories"
                        :productUsages="productUsages"
                        :funeralKinds="funeralKinds"
                        @cancel="handleCancel()"
                        @create="handleSubmit()"
                        @update="handleSubmit()"
                    />

                    </template>

                </div>
            </transition>
        </teleport>

    </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store'
import { useAuthStore } from '@/stores/auth.store'
import { useMessageStore } from '@/stores/message.store'

import PageHeader from "@/components/commons/PageHeader.vue"
import PageContent from "@/components/commons/PageContent.vue"
import Overlay from "@/components/commons/Overlay.vue"
import FormProduct from "@/components/forms/FormProduct.vue"

import UndertakersService from '@/middleware/undertakers'
import ProductsService from '@/middleware/products'
import ProductCategoriesServices from '@/middleware/productCategories'
import ProductUsagesServices from '@/middleware/productUsages'
import FuneralKindsService from '@/middleware/funeralKinds'

export default {
    components: { PageHeader, PageContent, Overlay, FormProduct },

    setup() {
        const globalStore = useGlobalStore()
        const authStore = useAuthStore()
        const messageStore = useMessageStore()

        return {
            globalStore,
            authStore,
            messageStore,
        }
    },

    data() {
        return {
            data: null,
            productCategories: null,
            productUsages: null,
            funeralKinds: null,
            productId: null,
            productCategory: null,
            isWaiting: false,
            isDeleting: false,
            isProductsLoaded: false,
            isProductCategoriesLoaded: false,
            isProductUsagesLoaded: false,
            isFuneralKindsLoaded: false,
        }
    },

    mounted() {

        // Load the initial data
        this.loadData()

    },

    computed: {

        // Helper to know that everything is loaded
        isLoaded() {
            return this.isProductsLoaded && this.isProductCategoriesLoaded && this.isProductUsagesLoaded && this.isFuneralKindsLoaded
        },

    },

    methods: {

        /* region [data manipulation] */

        loadData() {

            // Retrieve the product categories
            ProductCategoriesServices.getAll()
            .then((response) => {
                this.productCategories = this.$extract(response)
                this.isProductCategoriesLoaded = true
            })

            // Retrieve the product usages
            ProductUsagesServices.getAll()
            .then((response) => {
                this.productUsages = this.$extract(response)
                this.isProductUsagesLoaded = true
            })


            // Retrieve the funeral kinds
            FuneralKindsService.getAll()
            .then((response) => {
                this.funeralKinds = this.$extract(response)
                this.isFuneralKindsLoaded = true
            })

            // Retrieve the products list
            this.refreshData()

        },

        refreshData() {
            this.isWaiting = true

            // Retrieve the current undertaker products
            UndertakersService.getProducts(this.authStore.undertakerId)
            .then((response) => {
                this.data = this.$extract(response)
                this.isProductsLoaded = true
            })
            .finally(() => {
                this.isWaiting = false
            })

        },

        /* endregion */

        /* region [event handlers] */

        handleAddProduct(productCategory) {

            // Set the id to null to create a new entry
            this.productId = null

            // Set the category object to make it accessible by the form
            this.productCategory = productCategory['@id'] // NOTE: it's the productCategory IRI

            // Open the drawer
            this.globalStore.drawerMode = 'product'
            this.globalStore.isDrawerOpened = true
        },

        handleEditProduct(product) {

            // Set the id to make it accessible by the form
            this.productId = product.id

            // Set the category object to make it accessible by the form
            this.productCategory = product.productCategory // NOTE: it's the productCategory IRI

            // Open the drawer
            this.globalStore.drawerMode = 'product'
            this.globalStore.isDrawerOpened = true
        },

        handleDeleteProduct(product) {

            // Delete the product
            this.isDeleting = true
            ProductsService.delete(product.id)
            .then((response) => {
                // this.data.establishments = this.data.establishments.filter((item) => item.id !== establishment.id)

                // Refresh the data
                this.refreshData()
            })
            .finally(() => {
                this.isDeleting = false
            });

        },

        handleCancel() {
            // Close the drawer
            this.globalStore.drawerMode = null
            this.globalStore.isDrawerOpened = false
        },

        handleSubmit() {
            // Close the drawer
            this.globalStore.drawerMode = null
            this.globalStore.isDrawerOpened = false

            // Refresh the data
            this.refreshData()
        },

        /* endregion */

        /* region [helpers] */

        // Filter the products list to retrieve only a specific category of products
        filterProductsByCategory(productCategory) {
            return this.data.products.filter((product) => {
                if(product.productCategory === productCategory['@id']) {
                    return product
                }
            })
            .sort((a, b) => {
                // Sort by id
                if (a.id !== b.id) {
                    return a.id - b.id;
                }
            })
        },

        /* endregion */

    },

}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.page-header {
    top: $header-height + 1px; // Override the sticky position

    &.additional-offset{
        top: $header-height + $header-height + 1px;
    }
}

.product-category{
    margin-top: 30px;
    margin-bottom: 30px;

    .heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .products{
        display: flex;
        flex-direction: column;
        gap: 12px;

        .product{
            padding: 10px 10px;

            .wrapper{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            .picture-and-infos{
                display: flex;
                align-items: center;
                gap: 10px 0;
                flex: 1;
                flex-basis: 100%;

                @media (min-width: $s-mobile-sm) {
                    flex-basis: auto;
                }
            }

            .price-and-buttons{
                display: flex;
                align-items: center;
                margin-left: auto;
            }

            .picture{
                flex: none;
                width: 50px;
                height: 50px;
                border-radius: 10px;
                border: solid 1px $c-border;
                overflow: hidden;
                margin-right: 20px;

                > img{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }

            }
            .name{
                font-weight: bold;
                margin-right: 10px;
            }
            .material{
                text-transform: uppercase;
                font-weight: 300;
                font-size: 14px;
                margin-right: 10px;
            }
            .price{
                margin-left: auto;
                font-weight: bold;
            }

            .buttons{
                display: flex;
                align-items: center;
                margin-left: 10px;
                gap: 10px;
            }

        }
    }
}

</style>

