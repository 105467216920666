import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {
    // getAll: () => {
    //     return axios.get(fosRouter.generate('api_undertakers_get_collection'))
    // },
    // getPage: (page = 1, itemsPerPage, published = null) => {
    //     let route = `${fosRouter.generate('api_undertakers_get_collection')}?page=${page}&itemsPerPage=${itemsPerPage}`
    //     if(published !== null && published !== ''){
    //         route += `&published=${published}`
    //     }
    //     return axios.get(route)
    // },
    get: (id) => {
        return axios.get(`${fosRouter.generate('api_undertakers_get_item')}/${id}`)
    },
    // create: (data) => {
    //     return axios.post(fosRouter.generate('api_undertakers_post_collection'), data)
    // },
    update: (id, data) => {
        return axios.put(`${fosRouter.generate('api_undertakers_put_item')}/${id}`, data)
    },
    // delete: (id) => {
    //     return axios.delete(`${fosRouter.generate('api_undertakers_delete_item')}/${id}`)
    // },
    // search: (search) => {
    //     return axios.get(`${fosRouter.generate('api_undertakers_get_collection')}?search=${search}`)
    // },

    getPricings: (id) => {
        return axios.get(`${fosRouter.generate('api_undertakers_get_pricings_item')}/${id}`)
    },
    updatePricings: (id, data) => {
        return axios.put(`${fosRouter.generate('api_undertakers_put_pricings_item')}/${id}`, data)
    },
    getEstablishments: (id) => {
        return axios.get(`${fosRouter.generate('api_undertakers_get_establishments_item')}/${id}`)
    },
    getProducts: (id) => {
        return axios.get(`${fosRouter.generate('api_undertakers_get_products_item')}/${id}`)
    },

    checkParametersPassword: (id, data) => {
        return axios.post(`${fosRouter.generate('api_check_parameters_password')}/${id}`, data)
    },
}