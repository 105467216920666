
// region [CUSTOM VALIDATORS]

import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js"
import { helpers } from "@vuelidate/validators"

// Custom validator for google maps iframe
export const isGoogleMaps = helpers.regex(
    /^<iframe src="https:\/\/www\.google\.com\/maps\/embed\?/, // Starts by `<iframe src="https://www.google.com/maps/embed?`
    /.+">/, // One or more characters
    /<\/iframe>$/, // Ends by `</iframe>`
);

// Custom validator for secure password
export const isSecure = helpers.regex(
    /.{10,}/, // At least 10 characters
    /(?=.*?[a-z])/, // At least one lowercase letter
    /(?=.*?[A-Z])/, // At least one uppercase letter
    /(?=.*?[0-9])/, // At least one number
    /(?=.*?[\(\)\+\=\:#?!@€$%^&*-])/, // At least one special character from this list `()+=:#?!@€$%^&*-`
);

// Custom validator for phone numbers (will validate if the field is not null or blank)
export const isPhoneNumber = (country) => helpers.withParams(
    { type: 'isPhoneNumber', value: country },
    (value) => value === null || value === '' || isValidPhoneNumber(value, country)
)

// Phone number formatting helper (will format if the number is valid, return null otherwise)
export const formatPhoneNumber = (value, country) => isValidPhoneNumber(value, country) ? parsePhoneNumber(value, country).number : null

// endregion

// region [TRANSLATED VALIDATORS]

import { createI18n } from 'vue-i18n'
import { i18nConfig } from '@/config.i18n'
import * as validators from "@vuelidate/validators"

// Load the translations
const i18n = createI18n(i18nConfig)

// Customize the message path for retrieving the translations
const messagePath = ({ $validator }) => `app.validations.${$validator}`

// Create the i18n message instance
const { createI18nMessage } = validators
const withI18nMessage = createI18nMessage({
    t: i18n.global.t.bind(i18n),
    messagePath
})

// Redefine each validator with the withI18nMessage function
export const required = withI18nMessage(validators.required)
export const email = withI18nMessage(validators.email)
export const integer = withI18nMessage(validators.integer)
export const url = withI18nMessage(validators.url)

// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })

// endregion

