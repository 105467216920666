import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {

    // Retrieve the global configuration data
    getConfig: () => {
        return axios.get(fosRouter.generate('api_config_data'))
    },

    // Retrieve the configuration data related to the undertaker
    getConfigUndertaker: (id) => {
        return axios.get(`${fosRouter.generate('api_config_undertaker_data')}/${id}`)
    },

    sendEmailRegistration(
        firstname, lastname, company, email, phone
    ) {
        const data = {
            firstname: firstname,
            lastname: lastname,
            company: company,
            email: email,
            phone: phone,
        }
        return axios.post(fosRouter.generate('api_mail_user_registration'), data)
    },

    sendEmailInquirySummary(
        email, firstname, lastname,
        placementPlace, placementDate, placementTime,
        ceremonyPlace, ceremonyDate, ceremonyTime,
        cremationPlace, cremationDate, cremationTime,
        inhumationPlace, inhumationDate, inhumationTime
    ) {
        const data = {
            email: email,
            firstname: firstname,
            lastname: lastname,
            placementPlace: placementPlace,
            placementDate: placementDate,
            placementTime: placementTime,
            ceremonyPlace: ceremonyPlace,
            ceremonyDate: ceremonyDate,
            ceremonyTime: ceremonyTime,
            cremationPlace: cremationPlace,
            cremationDate: cremationDate,
            cremationTime: cremationTime,
            inhumationPlace: inhumationPlace,
            inhumationDate: inhumationDate,
            inhumationTime: inhumationTime,
        }
        return axios.post(fosRouter.generate('api_mail_funeral_summary'), data)
    },

    sendEmailConfigurator(
        recipientEmail, recipientFirstname, recipientLastname,
        adviserFirstname, adviserGender,
        configuratorLink
    ) {
        const data = {
            recipientEmail: recipientEmail,
            recipientFirstname: recipientFirstname,
            recipientLastname: recipientLastname,
            adviserFirstname: adviserFirstname,
            adviserGender: adviserGender,
            configuratorLink: configuratorLink,
        }
        return axios.post(fosRouter.generate('api_mail_send_configurator'), data)
    },
}