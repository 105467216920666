<template>
    <div>
        <v-autocomplete
            :placeholder="placeholder"
            :required="required"
            :autocomplete="autocomplete"
            :error-messages="errorMessages"
            :loading="isSearching"
            v-model="modelLocal"
            v-model:modelObject="modelObject"
            v-model:search="search"
            :items="localItems"
            :item-value="itemValue"
            :item-title="itemTitle"
            return-object
            :clearable="clearable"
            :menu-props='{ maxHeight: 400 }'
            @update:modelValue="handleChange"
            @update:menu="handleMenuChange"
            @click:clear="handleClear"
            :multiple="'multiple' in $attrs"
            :class="{'multiple' : 'multiple' in $attrs}"
            no-filter
            menu-icon=""
        >
            <!-- NOTE: On multiple autocomplete fields, we use the first item as a fake placeholder -->
            <template
                v-if="'multiple' in $attrs"
                v-slot:selection
            >
                <div class="placeholder">{{ placeholder }}</div>
            </template>

            <template v-slot:no-data>
                <div v-if="!isSearching && search && search.length && localItems && localItems.length === 0">
                    <v-list-item>
                        {{ $vuetify.locale.t('$vuetify.autocomplete.noDataText') }}
                    </v-list-item>
                </div>
                <div v-else-if="!isSearching">
                    <v-list-item>
                        {{ $vuetify.locale.t('$vuetify.autocomplete.noSearchText') }}
                    </v-list-item>
                </div>
            </template>

<!--            <template v-slot:chip="{ props, item }">-->
<!--                <v-chip-->
<!--                    v-bind="props"-->
<!--                    :text="item.raw[this.itemTitle]"-->
<!--                    close-icon="$close"-->
<!--                ></v-chip>-->
<!--            </template>-->
        </v-autocomplete>
    </div>
</template>

<script>
import { debounce } from '@/helpers.global'

export default {

    emits: ['update:modelValue', 'update:modelRaw', 'change', 'search'],

    created() {

        this.debouncedSearch = debounce( (value, self) => {

            // Handle empty value
            if (!value) {
                self.items = [];
            }

            // Prevent simultaneous searches
            if (self.isSearching) {
                return
            }

            // Emit an event to trigger the search function in the parent component. We pass the search string and a reference to the component to update its state
            this.$emit('search', value, self)

        }, this.debounceDelay); // Debounce delay

    },

    mounted() {
        // Load the parent model into a local object (allows us to display the already selected value)
        if(this.modelValue){
            this.modelLocal = this.modelValue

            // If there is a value
            if(this.modelValue[this.itemTitle]){

                // Trigger a search to prefill the choices
                this.debouncedSearch(this.modelValue[this.itemTitle], this)

                // Trigger a change to update all related fields (note that the list of choices will not be updated at this time)
                this.handleChange()
            }
        }

        this.isInitialized = true
    },

    props: {
        modelValue: {
            required: true,
        },
        itemValue: {
            required: true,
        },
        itemTitle: {
            required: true,
        },
        // Tell if itemTitle has to be retrieved in a translation object
        translated: {
            required: false,
            type: Boolean,
            default: false,
        },
        placeholder: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
        debounceDelay: {
            required: false,
            type: Number,
            default: 200
        },
        clearable: {
            required: false,
            type: Boolean,
            default: false
        },
        returnObject: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            modelLocal: null, // Will store a local copy of the model
            modelObject: null, // Will store a local copy of the full model
            items: [], // Contains the search results
            search: null, // Current search string
            isSearching: false, // Flag activated when we wait for the server response
            isOpened: false, // Flag to know when the result list is opened
            isInitialized: false,
        }
    },
    
    computed: {

        localItems() {
            if(this.translated){
                return this.items.map((item) => {
                    if('translations' in item) {
                        item[this.itemTitle] = item.translations[this.$i18n.locale][this.itemTitle]
                    }
                    return item
                })
            }
            else{
                return this.items
            }
        },

    },

    methods: {

        handleChange() {

            let model = null

            if(this.modelLocal){

                // Single item
                if(!Array.isArray(this.modelLocal)) {
                    if(this.returnObject){
                        model = this.modelLocal
                    }
                    else{
                        model = this.modelLocal[this.itemValue]
                    }
                }
                // Multiple items
                else{

                    // Remove duplicates
                    this.modelLocal = [...new Map(this.modelLocal.map(item => [item[this.itemValue], item])).values()]

                    if(this.returnObject){
                        model = this.modelLocal
                    }
                    else{
                        model = this.modelLocal.map(item => item[this.itemValue])
                    }
                }
            }
            else{
                model = this.modelLocal
            }

            this.$emit('update:modelValue', model)
            this.$emit('update:modelRaw', this.modelLocal)
            if(this.isInitialized){
                this.$emit('change')
            }
        },

        handleClear() {
            this.modelLocal = null
            this.search = null
            this.handleChange()
        },

        handleMenuChange(event) {
            this.isOpened = event

            // Empty the result list if the menu is closed
            if (!this.isOpened) {
                this.items = [];
            }
        },
    },

    watch: {

        search(value) {

            // When the select is not open (to prevent a search when selecting an item from the result)
            if (!this.isOpened) {
                return
            }

            // When the search value is empty (focus on empty field or remove all the text)
            if (!value) {
                this.items = []; // Empty the result list
                return
            }

            // Debounce search
            this.debouncedSearch(value, this)

        },

    },
}
</script>
