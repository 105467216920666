<template>
    <v-navigation-drawer
        class="drawer-right"
        v-model="globalStore.isDrawerOpened"
        location="right"
        temporary
        touchless
        @update:modelValue="handleStateChange"
    >
        <template v-slot:prepend>
            <div class="close-button">

                <div class="curved-tab" src="@images/curved-tab-big.svg"></div>

                <v-btn class="button button-neutral button-round"
                       icon="$next"
                       @click.stop="handleClickClose"
                ></v-btn>
            </div>
        </template>

        <div id="contextual-drawer">

        </div>

<!--        <v-container>-->
<!--            <v-row>-->
<!--                <v-col id="contextual-drawer">-->

<!--                </v-col>-->
<!--            </v-row>-->
<!--        </v-container>-->
    </v-navigation-drawer>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store';

export default {

    setup() {
        const globalStore = useGlobalStore()
        return {
            globalStore,
        }
    },

    methods: {

        handleClickClose(event) {
            this.globalStore.isDrawerOpened = false
            this.handleStateChange(this.globalStore.isDrawerOpened)
        },

        handleStateChange(event){
            if(!event){
                this.globalStore.drawerMode = null
            }
        },

    }

}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

#contextual-drawer{
    $pad-x: 50px;

    display: flex;
    flex-direction: column;

    padding: 60px $pad-x 20px $pad-x !important;
    background-color: $c-light;
    min-height: 100%;

    &::v-deep(.buttons-sticky) {
        padding-top: 20px;
    }

    &::v-deep(.stretcher) {
        flex: 1;
        display: flex;
        flex-direction: column;

        .buttons-sticky{
            margin-top: auto;
        }
    }

    &::v-deep(.title) {
        margin-bottom: 6px;
    }

    &::v-deep(.intro) {
        margin-bottom: 16px;
        font-size: 17px;
    }


}
</style>



