// Sentry
import '@/config.sentry'

// Vue
import { createApp } from 'vue'
import App from '@/components/App.vue'
const main = createApp(App)

// Axios
import '@/config.axios'

// i18n
import { createI18n, useI18n } from 'vue-i18n'
import { i18nConfig } from '@/config.i18n'
const i18n = createI18n(i18nConfig)
main.use(i18n)

// Store
import { createPinia } from 'pinia'
const pinia = createPinia()
main.use(pinia)

// Router front
import router from '@/routers/app.router'
main.use(router)

// Vuetify
import { createVuetify } from 'vuetify'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { vuetifyConfig } from '@/config.vuetify'
vuetifyConfig.locale = {
    adapter: createVueI18nAdapter({ i18n, useI18n }) // Use an adapter to make vuetify translatable
}
const vuetify = createVuetify(vuetifyConfig)
main.use(vuetify)

import { dayjs } from '@/config.dayjs'
main.config.globalProperties.$dayjs = dayjs // Make dayjs available everywhere

// Plugins
import helpers from '@/helpers.vue'
main.use(helpers)

// Mount the app
main.mount('#app')