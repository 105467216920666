<template>
    <div>
        <div class="wrapper">

            <div class="left">

                <div class="picture">

                    <div class="logo">
                        <img src="@images/logo-pro.svg">
                    </div>

                    <div class="background">
                        <slot name="picture"></slot>
                    </div>
                </div>

            </div>

            <div class="right">
                <slot name="content"></slot>
            </div>
        </div>

    </div>
</template>


<script>
export default {

    props: {
    },

    data() {
        return {
        }
    },

}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.wrapper{
    display: flex;
    min-height: 100vh;
}

.left{

    .picture{
        height: 100%;
        width: 100%;

        @media (min-width: $s-tablet) {
            position: relative;
        }

        .logo{
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);

            @media (min-width: $s-tablet) {
                filter: invert(1);
                left: 90px;
                transform: none;
            }

            img{
                height: 24px;

                @media (min-width: $s-tablet) {
                    height: 18px;
                }
            }
        }

        .background{
            height: 100%;
            width: 100%;
            overflow: hidden;

            &::v-deep(img) {
                display: none;
                object-fit: cover;
                width: 100%;
                height: 100%;

                @media (min-width: $s-tablet) {
                    display: block;
                }
            }
        }
    }
}

.right{
    background: url('../../../../images/pattern_light.jpg');
    flex: 1 0 66%;
    padding: 90px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;

    @media (min-width: $s-mobile-md) {
        padding: 90px 40px 40px 40px;
    }

    @media (min-width: $s-tablet) {
        padding: 80px 120px;
        justify-content: center;
    }


    &::v-deep(.container){

        background-color: white;
        width: 100%;
        padding: 30px;
        max-width: 500px;

        @media (min-width: $s-mobile-md) {
            padding: 50px;
        }

        &.container-large{
            max-width: 800px;
        }

        &.container-dense{
            padding: 20px 30px;
            text-align: center;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px 30px;

            @media (min-width: $s-mobile-md) {
                text-align: left;
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            .text{
                font-weight: bold;
            }
        }

        .title{
            margin-bottom: 20px;
        }

        .subtitle{
            margin-bottom: 10px;
        }
    }
}

::v-deep(.button[type="submit"]) {
    margin-top: 20px;
}

</style>