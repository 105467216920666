import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {
    getAll: () => {
        return axios.get(fosRouter.generate('api_establishments_get_collection'))
    },
    // getFiltered: (category, city) => {
    //     return axios.get(`${fosRouter.generate('api_establishments_get_collection')}?category.code=${category}&city=${city}&moderated=true&published=true`)
    // },
    // getFilteredCustom: (category, city, user) => {
    //     return axios.get(`${fosRouter.generate('api_establishments_get_collection')}?category.code=${category}&city=${city}&creator=${user}&moderated=false`)
    // },
    getFilteredCustom: (category = null, city = null, user = null, religion = null, locale = 'fr') => {
        let route = `${fosRouter.generate('config_autocomplete_establishment', {_locale : locale})}?getAllTranslations=true`
        if(category !== null){
            route += `&categoryCode=${category}`
        }
        if(city !== null){
            route += `&cityId=${city}`
        }
        if(user !== null){
            route += `&creatorId=${user}`
        }
        if(religion !== null){
            route += `&religionId=${religion}`
        }
        return axios.get(route)
    },
}