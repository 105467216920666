<template>

    <Overlay :visible="isWaiting"></Overlay>

    <Transition name="fade">
        <div v-if="data">

            <!-- No response : display the form -->
            <template v-if="!response">

                <Form
                    :data="data"
                    :validations="validations"
                    ref="form"
                    @mounted="handleFormMounted"
                    @valid="handleValid"
                    no-gutters
                >

                    <div class="title h2">{{ $t('app.form.send_configurator.title') }}</div>

                    <div class="intro">{{ $t('app.form.send_configurator.recipient.text') }}</div>

                    <Input
                        type="InputText"
                        :label="$t('app.form.send_configurator.recipient.email.label')"
                        :placeholder="$t('app.form.send_configurator.recipient.email.placeholder')"
                        v-model="data.recipient.email"
                        path="data.recipient.email"
                        required
                    >
                    </Input>

                    <Input
                        type="InputText"
                        :label="$t('app.form.send_configurator.recipient.lastname.label')"
                        :placeholder="$t('app.form.send_configurator.recipient.lastname.placeholder')"
                        v-model="data.recipient.lastname"
                        path="data.recipient.lastname"
                        required
                    >
                    </Input>

                    <Input
                        type="InputText"
                        :label="$t('app.form.send_configurator.recipient.firstname.label')"
                        :placeholder="$t('app.form.send_configurator.recipient.firstname.placeholder')"
                        v-model="data.recipient.firstname"
                        path="data.recipient.firstname"
                        required
                    >
                    </Input>

                    <div class="spacer"></div>

                    <div class="intro">{{ $t('app.form.send_configurator.adviser.text') }}</div>

                    <Input
                        type="InputText"
                        :label="$t('app.form.send_configurator.adviser.firstname.label')"
                        :placeholder="$t('app.form.send_configurator.adviser.firstname.placeholder')"
                        v-model="data.adviser.firstname"
                        path="data.adviser.firstname"
                        required
                    >
                    </Input>

                    <Input
                        type="InputSelect"
                        :label="$t('app.form.send_configurator.adviser.gender.label')"
                        :placeholder="$t('app.form.send_configurator.adviser.gender.placeholder')"
                        v-model="data.adviser.gender"
                        path="data.adviser.gender"
                        :items="globalStore.config.genders"
                        itemValue="value"
                        itemTitle="label"
                        :translated="false"
                        required
                    >
                    </Input>

                </Form>

                <div class="buttons buttons-sticky">
                    <v-btn
                        v-if="validator"
                        class="button button-small block"
                        type="submit"
                        :disabled="isWaiting || validator.$invalid"
                        @click="handleSubmit()"
                    >
                        {{ $t('app.global.actions.send') }}
                    </v-btn>

                    <v-btn
                        class="button button-small button-neutral block"
                        @click="handleCancel()"
                    >
                        {{ $t('app.global.actions.cancel') }}
                    </v-btn>
                </div>

            </template>

            <!-- Response = OK -->
            <template v-if="response && response.status === 200">

                <v-row>
                    <div class="title h2">{{ $t('app.form.send_configurator.success.title') }}</div>

                    <div class="intro">{{ $t('app.form.send_configurator.success.intro') }}</div>
                </v-row>

                <div class="buttons buttons-sticky">
                    <v-btn
                        class="button button-small button-neutral block"
                        @click="handleCancel()"
                    >
                        {{ $t('app.global.actions.close') }}
                    </v-btn>
                </div>

            </template>

            <!-- Response = Error -->
            <template v-if="response && response.status !== 200">

                <v-row>
                    <div class="title h2">{{ $t('app.global.messages.error') }}</div>

                    <div class="intro">{{ response.data.message }}</div>
                </v-row>

                <div class="buttons buttons-sticky">
                    <v-btn
                        class="button button-small button-neutral block"
                        @click="handleCancel()"
                    >
                        {{ $t('app.global.actions.close') }}
                    </v-btn>
                </div>

            </template>

        </div>
    </Transition>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store.js';
import { useGlobalStore } from '@/stores/global.store.js';

import GlobalService from '@/middleware/global';

import Overlay from "@/components/commons/Overlay.vue"
import Form from "@/components/commons/Form.vue"
import Input from "@/components/commons/Input.vue"

import { required, email } from '@/helpers.validators'

export default {

    emits: ['cancel', 'submit'],

    setup() {
        const authStore = useAuthStore()
        const globalStore = useGlobalStore()
        return {
            authStore,
            globalStore,
        }
    },

    components: { Overlay, Form, Input },

    props: {
    },

    data() {
        return {
            isWaiting: true,
            data: {
                recipient: {
                    email: null,
                    firstname: null,
                    lastname: null,
                },
                adviser: {
                    firstname: null,
                    gender: null,
                },
            },
            validations: {
                data: {
                    recipient: {
                        email: { required, email },
                        firstname: { required },
                        lastname: { required },
                    },
                    adviser: {
                        firstname: { required },
                        gender: { required },
                    },
                },
            },
            validator: null,
            response: null,
        };
    },

    mounted() {

        // The form is ready
        this.isWaiting = false

    },

    methods: {

        /* region [event handlers] */

        handleFormMounted(validator){
            this.validator = validator
        },

        handleCancel(){
            this.$emit('cancel', event)
        },

        handleSubmit(){
            this.$refs.form.submit()
        },

        handleValid(){
            this.isWaiting = true

            GlobalService.sendEmailConfigurator(
                this.data.recipient.email,
                this.data.recipient.firstname,
                this.data.recipient.lastname,
                this.data.adviser.firstname,
                this.data.adviser.gender,
                this.authStore.undertakerConfig.pageConfigurator + '?avatar=' + this.data.adviser.gender,
            )
            .then((response) => {
                this.response = response
                this.$emit('submit', response)
            })
            .catch((error) => {
                this.response = error.response
            })
            .finally(() => {
                this.isWaiting = false
            });
        },

        /* endregion */

    },
}
</script>

<style lang="scss" scoped>

</style>
