<template>
    <!-- NOTE! since the timePicker is not already release for Vuetify 3, fallback to the default browser input -->
    <v-text-field
        type="time"
        v-model="modelLocal"
        :placeholder="placeholder"
        :required="required"
        :autocomplete="autocomplete"
        :error-messages="errorMessages"
    ></v-text-field>
</template>

<script>
export default {

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            required: true,
        },
        placeholder: {
            required: false,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
    },

    computed: {

        modelLocal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },

    },

}
</script>

<style lang="scss" scoped>
@import "~styles/../_pure-icons";

.v-text-field{
    &::v-deep(input) {
        text-transform: uppercase;

        &::-webkit-calendar-picker-indicator {
            opacity: 0;
        }
        &:after {
            position: absolute;
            z-index: -1;

            font-family: 'pure-icons';
            content: map-get($pure-icons-map, 'time');
            font-size: 18px;
            right: 20px;
        }
    }
}
</style>