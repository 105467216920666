import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {
    get: (id) => {
        return axios.get(`${fosRouter.generate('api_products_get_item')}/${id}`)
    },
    // getAll: (undertaker) => {
    //     return axios.get(`${fosRouter.generate('api_products_get_collection')}?published=true`)
    // },
    // getAllFromUndertaker: (undertaker) => {
    //     return axios.get(`${fosRouter.generate('api_products_get_collection')}?published=true&undertaker=${undertaker}`)
    // },
    // getFiltered: (undertaker, category) => {
    //     return axios.get(`${fosRouter.generate('api_products_get_collection')}?&published=true&undertaker=${undertaker}&productCategory=${category}`)
    // },
    update: (id, data) => {
        return axios.put(`${fosRouter.generate('api_products_put_item')}/${id}`, data)
    },
    create: (data) => {
        return axios.post(fosRouter.generate('api_products_post_collection'), data)
    },
    delete: (id) => {
        return axios.delete(`${fosRouter.generate('api_products_delete_item')}/${id}`)
    },
}