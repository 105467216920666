import axios from "axios"
import fosRouter from '@/routers/api.router'

export const supportedStatuses = ['pending', 'processing', 'finished', 'archived', 'abandoned']

export default {
    get: (id) => {
        return axios.get(`${fosRouter.generate('api_inquiries_get_item')}/${id}`)
    },
    // getAll: () => {
    //     return axios.get(`${fosRouter.generate('api_inquiries_get_collection')}`)
    // },
    // getAll: (undertaker) => {
    //     return axios.get(`${fosRouter.generate('api_inquiries_get_collection')}?undertaker=${undertaker}`)
    // },
    getPage: (page = 1, itemsPerPage, undertaker, inquiryStatus = null, inquiryKind = null) => {
        let route = `${fosRouter.generate('api_inquiries_get_collection')}?page=${page}&itemsPerPage=${itemsPerPage}&undertaker=${undertaker}`
        if(inquiryStatus !== null){
            route += `&status=${inquiryStatus}`
        }
        if(inquiryKind !== null && inquiryKind !== 'all'){
            route += `&inquiryKind.code=${inquiryKind}`
        }
        return axios.get(route)
    },
    search: (undertaker, search) => {
        let route = `${fosRouter.generate('api_inquiries_get_collection')}?undertaker=${undertaker}&search=${search}`
        let statuses = supportedStatuses.map((status) => { return 'status[]=' + status }).join('&');
        route += `&${statuses}`
        return axios.get(route)
    },
    getPrices: (id) => {
        return axios.get(`${fosRouter.generate('api_inquiry_update_prices')}/${id}`)
    },
    updateStatus: (id, data) => {
        return axios.put(`${fosRouter.generate('api_inquiries_put_status_item')}/${id}`, data)
    },
    update: (id, data) => {
        return axios.put(`${fosRouter.generate('api_inquiries_put_item')}/${id}`, data)
    },

    getDeathNoticePdf: (id) => {
        return axios.get(`${fosRouter.generate('api_inquiry_death_notice_download')}/${id}`, {
            responseType: 'blob',
        })
    },

}