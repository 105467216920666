<template>

    <Overlay :visible="isWaiting"></Overlay>

    <Transition name="fade">
        <div v-if="data"
             class="stretcher"
        >

            <Form
                :data="data"
                :validations="validations"
                ref="form"
                @mounted="handleFormMounted"
                @valid="handleValid"
                no-gutters
            >

                <div class="title h2">{{ $t(`app.page.parameters.establishments.form.${establishmentCategory.code}.edit.title`) }}</div>

                <div class="intro">{{ $t(`app.page.parameters.establishments.form.${establishmentCategory.code}.edit.intro`) }}</div>

                <div
                    v-if="id"
                    class="establishment"
                >
                    <div class="name">
                        {{ this.$trans(data.establishment, 'name')}}
                    </div>
                    <div class="address">
                        {{ data.establishment.fullAddress }}
                    </div>
                </div>
                <template
                    v-else
                >

                    <Input
                        type="InputAutocomplete"
                        :label="$t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.city.label`)"
                        :placeholder="$t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.city.placeholder`)"
                        v-model="city"
                        path="city"
                        @search="autocompleteCity"
                        itemValue="@id"
                        itemTitle="text"
                        @update:modelValue="handleCityChange"
                        return-object
                    >
                    </Input>

                    <Input
                        v-if="city"
                        type="InputSelect"
                        :cols="12"
                        :label="$t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.establishment.label`)"
                        :placeholder="$t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.establishment.placeholder`)"
                        v-model="establishmentSelected"
                        path="establishmentSelected"
                        :items="cityFilteredEstablishments"
                        itemValue="@id"
                        itemTitle="name"
                        :translated="true"
                        :returnObject="true"
                        @change="handleEstablishmentSelected"
                    >
                    </Input>

                    <div v-if="establishmentSelected && establishmentSelected['@id'] !== 0"
                         class="establishment"
                    >
                        <div class="field">
                            <v-label>{{ $t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.address1.label`) }}</v-label>
                        </div>
                        {{ establishmentSelected.fullAddress }}
                    </div>

                    <template v-if="isCreatingEstablishment">
                        <Input
                            :label="$t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.address1.label`)"
                            :placeholder="$t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.address1.placeholder`)"
                            v-model="data.establishment.address1"
                            path="data.establishment.address1"
                            required
                        >
                        </Input>

                        <Input
                            type="InputTranslation"
                            :label="$t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.name.label`)"
                            :placeholder="$t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.name.placeholder`)"
                            v-model="data.establishment.translations"
                            field="name"
                            path="data.establishment.translations.{locale}.name"
                            required
                        >
                        </Input>
                    </template>

                </template>

                <div class="service-pricing-categories"
                     v-if="data.establishment"
                >
                    <template v-for="servicePricingCategory in getServicePricingCategoriesForEstablishmentCategory()">
                        <div class="service-pricing-category"
                             v-if="servicePricingCategory.published"
                        >
                            <div class="name h2">
                                {{ this.$trans(servicePricingCategory, 'name')}}
                            </div>

                            <div class="service-pricings">
                                <template v-for="servicePricing in servicePricingCategory.servicePricings">
                                    <div class="service-pricing"
                                         v-if="servicePricing.published"
                                    >
                                        <Input
                                            class="price"
                                            type="InputText"
                                            :label="this.$trans(servicePricing, 'name')"
                                            placeholder="0"
                                            suffix="€"
                                            :value="getServicePricingPrice(servicePricing.code)"
                                            @input="setServicePricingPrice($event, servicePricing.code)"
                                            :important="servicePricing.mandatory && $isNullOrEmpty(getServicePricingPrice(servicePricing.code))"
                                            :required="servicePricing.mandatory"
                                        >
                                        </Input>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>

            </Form>

            <div class="buttons buttons-sticky">
<!--                <v-btn-->
<!--                    v-if="city && !data.establishment"-->
<!--                    class="button button-secondary button-small block"-->
<!--                    :disabled="isWaiting"-->
<!--                    @click="handleNewEstablishment()"-->
<!--                >-->
<!--                    {{ $t(`app.page.parameters.establishments.form.${establishmentCategory.code}.add.establishment.other_button`) }}-->
<!--                </v-btn>-->

                <v-btn
                    v-if="data.establishment && validator"
                    class="button button-small block"
                    type="submit"
                    :disabled="isWaiting || validator.$invalid || !validator.$dirty"
                    @click="handleSubmit()"
                >
                    {{ $t('app.global.actions.save') }}
                </v-btn>

                <v-btn
                    class="button button-small button-neutral block"
                    @click="handleCancel()"
                >
                    {{ $t('app.global.actions.cancel') }}
                </v-btn>
            </div>

        </div>
    </Transition>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store.js';

import UndertakerEstablishmentsService from '@/middleware/undertakerEstablishments'
import EstablishmentsService from '@/middleware/establishments'
import { autocompleteCity } from '@/helpers.autocomplete'

import Overlay from "@/components/commons/Overlay.vue"
import Form from "@/components/commons/Form.vue"
import Input from "@/components/commons/Input.vue"

import { required, integer } from '@/helpers.validators'
import { helpers, requiredIf } from "@vuelidate/validators"

export default {

    emits: ['cancel', 'create', 'update'],

    setup() {
        const authStore = useAuthStore()
        return {
            authStore,
        }
    },

    components: { Overlay, Form, Input },

    props: {
        id:{
            required: false,
            default: null,
        },
        establishmentCategory:{
            required: true,
        },
        existingEstablishments:{
            required: true,
        },
        services: {
            required: true,
        },
    },

    data() {
        return {
            isWaiting: true,
            isCreatingEstablishment: false,
            data: null,
            dataDefault: {
                // translations: this.$initTranslations(),
                undertaker: this.authStore.undertaker['@id'],
                establishment: null,
                servicePricings: this.getServicePricingCategoriesForEstablishmentCategory().flatMap(servicePricingCategory => {
                    return servicePricingCategory.servicePricings.flatMap(servicePricing => {
                        return {
                            price: null,
                            servicePricing: { // NOTE : we could directly pass the retrieved servicePricing object, but it will be more complicated to manage on persist (because it contains too much information, like subentities, and the API will try to persist them)
                                '@id': servicePricing['@id'],
                                id: servicePricing.id,
                                code: servicePricing.code,
                                published: servicePricing.published,
                                mandatory: servicePricing.mandatory,
                            },
                        }
                    })
                })
            },
            city: null,
            cityFilteredEstablishments: [],
            establishmentSelected: null,
            validator: null,
        };
    },

    mounted() {

        // Check if an id is defined to choose between edit or create mode
        if(this.id){
            // Show the loader
            this.isWaiting = true

            // Load the data
            this.loadItem(this.id)
            .then((response) => {
                // Extract the data in the current item
                this.data = this.$extract(response)

                // Hide the loader
                this.isLoaded = true
            })
            .finally(() => {
                // The form is ready
                this.isWaiting = false
            })
        }
        else {
            // Initialize the current item with a copy of the initial state
            this.data = { ...this.dataDefault }

            // The form is ready
            this.isWaiting = false
        }

    },


    computed: {

        // We use a computed property for validations because its dynamic and we need it to remain reactive when passed to the form subcomponent
        validations() {
            const validations = {
                // city: { required }, // NOTE : We can't validate this in a subcomponent since its not included in the passed "data" variable and therefore not accessible in Form
                data: {
                    establishment: { required },
                    servicePricings: {
                        $each: helpers.forEach({
                            price: {
                                required: requiredIf((modelValue, siblings, index, collectionSiblings, vm) => {
                                    return /*siblings.servicePricing.category.published &&*/ siblings.servicePricing.published && siblings.servicePricing.mandatory;
                                }),
                                integer,
                            },
                        })
                    },
                },
            }
            if (this.isCreatingEstablishment) {
                validations.data.establishment = {
                    address1: { required },
                    translations: {
                        fr: {
                            name: { required },
                        },
                    },
                }
            }
            return validations
        }
    },

    methods: {

        /* region [imported method binding] */

        autocompleteCity: autocompleteCity,

        /* endregion */

        /* region [data manipulation] */

        loadItem(id) {
            return UndertakerEstablishmentsService.get(id)
        },

        updateItem(id, data) {
            return UndertakerEstablishmentsService.update(id, data)
        },

        createItem(data) {
            return UndertakerEstablishmentsService.create(data)
        },

        getEstablishmentsByCity(city){
            this.isWaiting = true

            EstablishmentsService.getFilteredCustom(this.establishmentCategory.code, city.id, this.authStore.id)
            .then((response) => {
                this.cityFilteredEstablishments = this.$extract(response)

                // Add disabled attribute to already associated establishments
                this.cityFilteredEstablishments = this.cityFilteredEstablishments.map((establishment) => {
                    if (this.existingEstablishments.some(
                        (existingEstablishment) => existingEstablishment.id === establishment.id
                    )) {
                        return { ...establishment, props: { disabled: true } }; // NOTE : workaround for list-item props https://github.com/vuetifyjs/vuetify/issues/16792
                    }
                    return establishment;
                });

                // Add a first item "Other" when we want to create a new establishment
                this.cityFilteredEstablishments.unshift({
                    '@id': 0,
                    name: this.$t(`app.page.parameters.establishments.form.${this.establishmentCategory.code}.add.establishment.other_text`),
                    props: { divider: true },
                })

                // Reset the current selected establishment because the list changed
                this.establishmentSelected = null
                this.data.establishment = null
            })
            .finally(() => {
                this.isWaiting = false
            });
        },

        /* endregion */

        /* region [event handlers] */

        handleFormMounted(validator){
            this.validator = validator
        },

        handleCancel(){
            this.$emit('cancel', event)
        },

        handleSubmit(){
            this.$refs.form.submit()
        },

        handleValid(){
            this.isWaiting = true

            // Check if an id is defined to choose between edit or create
            if(this.id) {
                this.updateItem(this.id, this.data)
                .then((response) => {
                    this.$emit('update', event)
                })
                .finally(() => {
                    this.isWaiting = false
                });
            }
            else{
                this.createItem(this.data)
                .then((response) => {
                    this.$emit('create', event)
                })
                .finally(() => {
                    this.isWaiting = false
                });
            }
        },

        handleCityChange(event){
            // Reset state because the city changed
            this.cityFilteredEstablishments = []
            this.establishmentSelected = null
            this.isCreatingEstablishment = false

            // Retrieve the establishments for this city
            this.getEstablishmentsByCity(event)
        },

        handleEstablishmentSelected(event){

            // Reset state because the establishment changed
            this.isCreatingEstablishment = false

            // Try to retrieve the selected establishment IRI
            const establishmentIri = this.establishmentSelected['@id']

            // If we selected "Other", trigger the establishment creation, else update the data
            if(establishmentIri === 0){
                this.handleNewEstablishment()
            }
            else{
                this.data.establishment = establishmentIri
            }

        },

        handleNewEstablishment(){

            // Automatically select the first item (it should be "other")
            // TODO! this is not working
            this.establishmentSelected = this.cityFilteredEstablishments[0]

            // Create the structure for the new establishment
            this.data.establishment = {
                published: true,
                moderated: false,
                address1: null,
                city: this.city['@id'],
                category: this.establishmentCategory['@id'],
                creator: '/api/users/' + this.authStore.id,
                translations: this.$initTranslations(),
            }

            // Set a flag to know we are creating a new establishment
            this.isCreatingEstablishment = true
        },

        /* endregion */

        /* region [helpers] */

        getServicePricingCategoriesForEstablishmentCategory() {
            // console.log(this.establishmentCategory.code)
            // console.log(this.services.filter((item)=> item.establishmentCategory.code === this.establishmentCategory.code))
            return this.services.filter((item)=> item.establishmentCategory.code === this.establishmentCategory.code)
        },

        // NOTE : since the model is retrieved dynamically, we can't use the standard v-model attribute, instead we use a :value + event binding.
        getServicePricingModel(code) {
            return this.data.servicePricings.filter((item)=> item.servicePricing.code === code)[0]
        },

        getServicePricingPrice(code) {
            const tempData = this.getServicePricingModel(code)
            return tempData ? tempData.price : null
        },

        setServicePricingPrice (event, code) {
            let tempData = this.getServicePricingModel(code)
            tempData.price = parseFloat(event.target.value) ? parseFloat(event.target.value) : null
        },

        /* endregion */
    },
}
</script>

<style lang="scss" scoped>

.establishment{
    width: 100%;
    background-color: #ffffff;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 30px;

    .name{
        font-weight: bold;
    }

    .address{

    }
}

.service-pricing-categories{
    width: 100%;
}

.service-pricing-category{

    &:not(:last-child){
        margin-bottom: 30px;
    }

    > .name{
        margin-bottom: 10px;
    }

    .service-pricing{
        margin-bottom: 10px;

        > .name{
            margin-bottom: 10px;
        }
    }
}

.buttons{
    margin-top: 20px;
}

</style>
