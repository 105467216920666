<template>
    isVisible: {{isVisible}}
    _isVisible: {{_isVisible}}
    <v-dialog
        v-model="_isVisible"
        persistent
        width="auto"
        max-width="500px"
    >
<!--        <template v-slot:activator="{ props }">-->
<!--            <v-btn-->
<!--                color="primary"-->
<!--                v-bind="props"-->
<!--            >-->
<!--                Open Dialog-->
<!--            </v-btn>-->
<!--        </template>-->

        <v-card>

            <v-card-title class="h1">
                {{ title }}
            </v-card-title>

            <v-card-text>
                {{ text }}
            </v-card-text>

            <v-card-actions>

                <v-btn
                    class="button button-small button-neutral"
                    @click="handleClickNo"
                >
                    {{ buttonNo ?? $t('app.global.actions.cancel') }}
                </v-btn>

                <v-btn
                    class="button button-small"
                    @click="handleClickYes"
                >
                    {{ buttonYes ?? $t('app.global.actions.ok') }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
export default {

    emits: ['yes', 'no'],

    props: {
        isVisible: {
            required: true,
            type: Boolean,
        },
        title: {
            required: true,
            type: String,
        },
        text: {
            required: true,
            type: String,
        },
        buttonYes: {
            required: false,
            type: String,
        },
        buttonNo: {
            required: false,
            type: String,
        },
    },

    data () {
        return {
            _isVisible: null,
        }
    },

    mounted() {
        this._isVisible = this.isVisible
    },

    methods: {
        handleClickNo(){
            this.$emit('no')
        },
        handleClickYes(){
            this.$emit('yes')
        },
    },

    watch: {
        isVisible(value) {

            this._isVisible = value

            if (this._isVisible) {
                // Here you would put something to happen when dialog opens up
                console.log("Dialog was opened!")
            } else {
                console.log("Dialog was closed!")
            }
        }
    },

}
</script>

<style lang="scss" scoped>
</style>