import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {
    getAll: () => {
        return axios.get(fosRouter.generate('api_cities_get_collection'))
    },
    search: (search, locale = 'fr', pageLimit = 20) => {
        return axios.get(`${ fosRouter.generate('config_autocomplete_city', {_locale : locale}) }?q=${search}&page_limit=${pageLimit}`)
    },
}