<template>
    <v-text-field
        v-model="modelLocal"
        :placeholder="placeholder"
        :required="required"
        :autocomplete="autocomplete"
        :type="reveal ? 'text' : 'password'"
        :append-inner-icon="reveal ? 'icon-eye-opened' : 'icon-eye-closed'"
        @click:append-inner="reveal = !reveal"
        :error-messages="getErrors()"
    ></v-text-field>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { helpers } from "@vuelidate/validators"
import { isSecure } from '@/helpers.validators.js'

export default {

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            required: true,
        },
        placeholder: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
        secure: {
            required: false,
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            reveal: false,
        }
    },

    computed: {

        modelLocal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },

    },

    setup() {
        return {
            v$: useVuelidate(),
        }
    },

    validations() {

        if(this.secure){
            return {
                modelLocal : {
                    secure : helpers.withMessage(
                        this.$t('app.validations.password'), // Custom error message
                        isSecure
                    )
                },
            }
        }

    },

    methods: {

        getErrors(){

            // If there is a local validation, merge the errors from parent and local validators
            if(this.v$.modelLocal){
                return [...this.errorMessages, ...this.v$.modelLocal.$errors.map(e => e.$message)]
            }
            else{
                return this.errorMessages
            }
        },

    },
}
</script>
