<template>
    <div></div>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store'

export default {

    setup() {
        const authStore = useAuthStore()
        return {
            authStore,
        }
    },

    mounted() {
        this.authStore.logout()
        .finally(() => {
            // Redirect to login page
            this.$router.push({ name: 'login' })
        });
    },

}
</script>



