import { defineStore } from 'pinia'
import { locales } from '@/config.i18n';

export const useGlobalStore = defineStore('global', {
    state: () => ({
        sf: null, // Contain all the symfony variables retrieved from the main template
        count: 0,
        isDrawerOpened: false,
        isBurgerOpened: false,
        drawerMode: null,
        locales: locales,
        undertaker: null,
        config: null
    }),
    getters: {
    },
    actions: {
    },
})
