import axios from "axios"
import router from '@/routers/app.router'
import { useAuthStore } from '@/stores/auth.store'
import { useMessageStore } from '@/stores/message.store'

// Request interceptor
axios.interceptors.request.use(function (config) {
    // Before the request is sent

    // If a token is available, inject it in the request headers
    const authStore = useAuthStore()
    if (authStore.authenticationToken) {
        config.headers.Authorization = `Bearer ${authStore.authenticationToken}`
    }

    return config;

}, function (error) {
    // On request error
    return Promise.reject(error);
});

// Response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    console.error(error)

    // Try to retrieve an error message
    const message = error?.response?.data?.message || error?.response?.data?.detail || error?.response?.data['hydra:description'] || error?.response?.statusText
    if(message){
        const messageStore = useMessageStore()
        messageStore.addError(message)
    }

    // 401 : Token or authentication issue (token expiration, token modification)
    // 403 : Access denied (rejected by the voter because the user is disabled, its undertaker changed, the entity he tries to edit doesn't belong to him)
    if([401, 403].includes(error.response.status)){

        console.error(error.response.data.message)

        if(!error.response.data.noredirect){
            // Clear the token
            const authStore = useAuthStore()
            authStore.logout();

            // Redirect to login page to force a reauthentication
            router.push({ name: 'login' })
        }

    }

    return Promise.reject(error);
});

