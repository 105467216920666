import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {
    getAll: () => {
        return axios.get(fosRouter.generate('api_establishment_categories_get_collection'))
    },
    getByCodes: (codes) => {
        let params = codes.map((code) => { return 'code[]=' + code }).join('&');
        return axios.get(`${fosRouter.generate('api_establishment_categories_get_collection')}?${params}`)
    },
}