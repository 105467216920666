import dayjsInstance from 'dayjs'
// const localizedFormat = require('dayjs/plugin/localizedFormat') // localizedFormat plugin to dynamically format dates with
import { locales, defaultLocale } from '@/config.i18n';

// Import locales dynamically
for (const [index, locale] of locales.entries()) {
    const file = `dayjs/locale/${locale}`
    require(`dayjs/locale/${locale}.js`)
}

// Define the locale used
dayjsInstance.locale(defaultLocale)

// Load plugins
// dayjsInstance.extend(localizedFormat)

export const dayjs = dayjsInstance