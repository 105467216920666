import { defineStore } from 'pinia'
import axios from "axios";
import fosRouter from '@/routers/api.router'
import UndertakersService from '@/middleware/undertakers';
import GlobalService from '@/middleware/global';

// export const AUTHENTICATION_TOKEN_KEY = 'authenticationToken'
export const REFRESH_TOKEN_KEY = 'refreshToken'

export const ROLES = {
    ROLE_USER: "ROLE_USER",
    PUBLIC_ACCESS: "PUBLIC_ACCESS",
}

const ROLES_HIERARCHY = {
    ROLE_USER: [ROLES.ROLE_USER],
}

export const useAuthStore = defineStore('auth', {

    state: () => ({
        authenticationToken: null, // Contain the JSON Web Token for authentication
        refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY), // Contain the token that could refresh the authenticationToken
        id: null,
        email: null,
        roles: [],
        undertakerId: null,
        undertaker: null,
        undertakerConfig: null,
        hasAccessToParameters: false,
    }),

    getters: {
        isAuthenticated() {
            return this.authenticationToken != null
                && this.roles.length > 0
                && this.undertaker !== null
                && this.undertakerConfig !== null
        },
    },

    actions: {

        login(username, password) {

            // Authenticate the user
            return axios.post(fosRouter.generate('jwt_check'), {
                username: username,
                password: password,
            }).then((response) => {

                if (response.status === 200) {
                    // Save its data on front
                    this.saveToken(response)

                    // Load all additional user data
                    return this.getUserData()
                }
                else{
                    return response;
                }
            })
        },

        refresh() {

            // Ask a new authentication token
            return axios.post(fosRouter.generate('jwt_refresh'), {
                refresh_token: this.refreshToken,
            }).then((response) => {
                if (response.status === 200) {
                    // Update the token data
                    this.saveToken(response)

                    // Reload all additional user data
                    return this.getUserData()
                }
                else{
                    return response;
                }
            }).catch((error) => {
                // In case of error, clear everything on front
                this.deleteToken()
                throw(error)
            })
        },

        logout() {
            if(this.refreshToken){
                // Remove the refresh token from the back
                return axios.post(fosRouter.generate('jwt_invalidate'), {
                    refresh_token: this.refreshToken,
                }).then((response) => {
                    return response;
                })
                .finally(() => {
                    // Clear everything on front
                    this.deleteToken()
                });
            }
            else{
                // Clear everything on front
                this.deleteToken()
            }
        },

        getUserData() {

            return Promise.all([
                // Retrieve the undertaker data
                UndertakersService.get(this.undertakerId).then((response) => {
                    this.undertaker = response.data
                    return response
                }),
                // Retrieve the undertaker config
                GlobalService.getConfigUndertaker(this.undertakerId).then((response) => {
                    this.undertakerConfig = response.data
                    return response
                })
            ])
        },

        saveToken(response) {

            // Extracting data from the response
            const authenticationToken = response.data.token
            const refreshToken = response.data.refresh_token
            const id = response.data.data.id
            const email = response.data.data.email
            const roles = response.data.data.roles
            const undertakerId = response.data.data.undertakerId
            const undertakerConfig = response.data.data.undertakerConfig

            // Save the data in the store
            this.authenticationToken = authenticationToken;
            this.refreshToken = refreshToken;
            this.id = id
            this.email = email
            this.roles = roles
            this.undertakerId = undertakerId
            this.undertakerConfig = undertakerConfig

            // Save the data locally
            // localStorage.setItem(AUTHENTICATION_TOKEN_KEY, authenticationToken)
            localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
        },

        deleteToken() {

            // Reset the data of the store
            this.authenticationToken = null;
            this.refreshToken = null;
            this.id = null
            this.email = null
            this.roles = []
            this.undertakerId = null
            this.undertaker = null,
            this.undertakerConfig = null,
            this.hasAccessToParameters = false,

            // Reset the data locally
            // localStorage.removeItem(AUTHENTICATION_TOKEN_KEY)
            localStorage.removeItem(REFRESH_TOKEN_KEY)
        },

        isRoleGranted(role) {

            // If it's a public access or if no role was passed, grant access directly
            if (role === ROLES.PUBLIC_ACCESS || role === undefined) {
                return true
            }

            // Loop on all current user roles, and foreach role, check in the hierarchy if we found the asked role
            let isGranted = false;
            for (const i in this.roles) {
                const roleHierarchy = ROLES_HIERARCHY[this.roles[i]]
                if (roleHierarchy && roleHierarchy.includes(role)) {
                    isGranted = true
                }
            }
            return isGranted

        },
    },
})
