<template>
    <InputPasswordSecure
        v-model="modelLocal"
        :placeholder="placeholder"
        :required="required"
        :autocomplete="autocomplete"
        :type="reveal ? 'text' : 'password'"
        :append-inner-icon="reveal ? 'icon-eye-opened' : 'icon-eye-closed'"
        @click:append-inner="reveal = !reveal"
        :error-messages="errorMessages"
        :secure="false"
    ></InputPasswordSecure>
</template>

<script>
import InputPasswordSecure from "@/components/commons/InputPasswordSecure.vue"

export default {
    components: { InputPasswordSecure },

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            required: true,
        },
        placeholder: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
    },

    data() {
        return {
            reveal: false,
        }
    },

    computed: {

        modelLocal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },

    },

}
</script>
