<template>
    <span
        v-html="this.$highlight(delimiterStart + text + delimiterEnd)"
    >
    </span>
</template>

<script>
export default {
    props: {
        text: String,
        type: Number, // We can pass a type to automatically add delimiters (see computed values below)
    },
    computed: {
        delimiterStart(){
            return (this.type === 1 ? '(((' : '') || (this.type === 2 ? '[[[' : '')
        },
        delimiterEnd(){
            return (this.type === 1 ? ')))' : '') || (this.type === 2 ? ']]]' : '')
        },
    }
}
</script>