<template>
    <v-app>

        <!-- Error message bar -->
        <MessageBar></MessageBar>

        <!-- Right collapsible panel -->
        <DrawerRight></DrawerRight>

        <!-- Left fixed panel -->
        <DrawerLeft></DrawerLeft>

        <!-- Page container -->
        <v-main>
            <v-container fluid class="page">

                <router-view v-slot="{ Component }" class="router-view">
<!--                    <transition name="slide" mode="out-in"> &lt;!&ndash; out-in : current element transitions out first, then, the new element transitions in &ndash;&gt;-->
                        <component :is="Component" />
<!--                    </transition>-->
                </router-view>

<!--                <PopupConfirm-->
<!--                    :isVisible="true"-->
<!--                    title="Lorem ipsum dolor sit amet"-->
<!--                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum urna elit, faucibus ut nisi sed, tristique vehicula nisi. Quisque ante odio, efficitur nec tincidunt sed, aliquet sed diam ?"-->
<!--                ></PopupConfirm>-->

            </v-container>
        </v-main>

    </v-app>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store';
import { useAuthStore, ROLES } from '@/stores/auth.store.js';

import DrawerLeft from "@/components/DrawerLeft.vue";
import DrawerRight from "@/components/DrawerRight.vue";
import MessageBar from "@/components/MessageBar.vue";
import PopupConfirm from "@/components/commons/PopupConfirm.vue";

import GlobalService from '@/middleware/global';

export default {

    components: { DrawerLeft, DrawerRight, MessageBar, PopupConfirm },

    setup() {
        const globalStore = useGlobalStore()
        const authStore = useAuthStore()
        return {
            globalStore,
            authStore,
            ROLES,
        }
    },

    data() {
        return {
            isConfigLoaded: false,
        }
    },

    created() {
        // Retrieve symfony variables and store them
        this.globalStore.sf = window.sf

        // If the user is not authenticated and there is a refreshToken
        if (!this.authStore.isAuthenticated && this.authStore.refreshToken) {

            // Try to get a new authenticationToken
            this.authStore.refresh()
            .then((responses) => {
                if (responses.every(response => response.status === 200)) {
                    // If we are coming form a redirect, we return to the origin page, if not we go to the homepage
                    const redirect = this.$route.redirectedFrom
                    redirect ? this.$router.push(redirect) : this.$router.push({ name: 'home' })
                }
            })
        }

        // Retrieve the global configuration and store it
        GlobalService.getConfig()
        .then((response) => {
            const configRaw = this.$extract(response)
            this.globalStore.config = configRaw
            // console.log(configRaw)
            this.isConfigLoaded = true
        })
    },

}
</script>

<style lang="scss" scoped>

.v-main{
    background: url('../../../images/pattern_light.jpg');
}

</style>


