import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {
    // Returns pricing categories that are not associated with an establishment category
    getAllGeneric: () => {
        return axios.get(`${fosRouter.generate('api_service_pricing_categories_get_collection')}?exists[establishmentCategory]=false`)
    },
    // Returns pricing categories associated with a specific establishment category
    getAllSpecific: () => {
        return axios.get(`${fosRouter.generate('api_service_pricing_categories_get_collection')}?exists[establishmentCategory]=true`)
    },
    // Returns pricing categories associated with a specific establishment category
    getCategory: (establishmentCategory) => {
        return axios.get(`${fosRouter.generate('api_service_pricing_categories_get_collection')}?establishmentCategory=${establishmentCategory}`)
    },
}