<template>
    <Transition name="fade">
        <div class="notice bg-dark"
             v-if="isVisible"
        >
            <v-icon :icon="icon"></v-icon>
            <slot></slot>
        </div>
    </Transition>
</template>

<script>
export default {
    props: {
        icon: {
            required: false,
            default: "icon-warning",
        },
        isVisible: {
            required: false,
            type: Boolean,
            default: true,
        }
    },
}
</script>

<style lang="scss" scoped>

.v-icon{
    font-size: 30px;
}

</style>
