<template>
    <v-snackbar
        multi-line
        :timeout="-1"
        v-model="hasMessages"
    >

        <TransitionGroup name="fade">
            <div
                v-for="(message, index) in messages"
                :key="index"
            >
                {{ message.text }}
            </div>
        </TransitionGroup>

        <template v-slot:actions>
            <v-btn
                icon="$close"
                size="x-small"
                @click="clearMessages"
            >
            </v-btn>
        </template>

    </v-snackbar>
</template>

<script>
import { useMessageStore } from '@/stores/message.store';

export default {

    setup() {
        const messageStore = useMessageStore()
        return {
            messageStore,
        }
    },

    computed: {

        messages() {
            return this.messageStore.messages
        },

        hasMessages() {
            return this.messageStore.messages.length > 0
        },

    },

    methods: {

        clearMessages() {
            this.messageStore.clearMessages()
        },

    },

}
</script>

<style lang="scss" scoped>
</style>



