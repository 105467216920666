<template>
    <v-card class="inquiry"
            :to="{ name: 'inquiries.edit', params: { id: inquiry.id } }"
    >
        <div class="wrapper">

            <div class="main">
                <div class="picture">
                    <img v-if="deceasedCivility && deceasedCivility.avatar" :src="deceasedCivility.avatar.media_preview">
                </div>

                <div class="name h2">
                    <div class="status"
                         v-if="isSearchResult"
                    >
                        {{ $t(`app.inquiries.status.${inquiry.status}.status`) }}
                    </div>

                    {{ deceasedCivility ? $trans(deceasedCivility, 'shortLabel') : '' }}

                    <Highlight class="name"
                               :text="inquiry?.deceasedIdentity?.firstName"
                               :type="2"
                    ></Highlight>

                    {{ inquiry?.deceasedIdentity?.lastName }}

                </div>
            </div>

            <div class="infos">
                <div
                    v-if="inquiry.contactChoice === 'yes'"
                    class="tag tag-rounded icon"
                >
                    <v-icon icon="$phone"></v-icon>
                </div>
                <div class="tag tag-neutral type">
                    {{ $trans(inquiry.inquiryKind, 'name') }}
                </div>
                <div class="tag tag-neutral reference">
                    {{ inquiry.reference }}
                </div>
                <div class="date">
                    {{ formatDate(inquiry.createdAt) }}
                </div>
            </div>

        </div>
    </v-card>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store';
import Highlight from "@/components/commons/Highlight.vue"

export default {

    components: { Highlight },

    setup() {
        const globalStore = useGlobalStore()
        return {
            globalStore,
        }
    },

    props: {
        inquiry:{
            required: true,
        },
        isSearchResult:{
            required: false,
            default: false,
        },
    },

    computed: {
        deceasedCivility(){
            return this.globalStore.config.civilities.find(item => item.code === this.inquiry?.deceasedIdentity?.civility?.code)
        },
    },

    methods: {

        formatDate(date) {

            // If it's today, display the time instead of the date
            if(this.$dayjs().isSame(date, 'day')) {
                return this.$dayjs(date).locale(this.$i18n.locale).format(this.$t('app.format.time-readable'))
            }
            else if(this.$dayjs().isSame(date, 'year')) {
                return this.$dayjs(date).locale(this.$i18n.locale).format(this.$t('app.format.date-readable'))
            }
            // If it's not the same year, display the year
            else {
                return this.$dayjs(date).locale(this.$i18n.locale).format(this.$t('app.format.date-readable-full'))
            }
        },

    },
}
</script>


<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.inquiry{
    margin-bottom: 15px;
    padding: 20px 20px;

    @media (min-width: $s-mobile-md) {
        padding: 25px 30px;
    }

    .wrapper{
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        @media (min-width: $s-mobile-md) {
            flex-wrap: nowrap;
        }
    }

    .main{
        display: flex;
        gap: 20px;

        @media (min-width: $s-mobile-md) {
            align-items: center;
        }

        .picture{
            //display: none;
            height: 40px;
            width: 40px;
            flex: 0 0 auto;

            @media (min-width: $s-mobile-xs) {
                //display: block;
            }

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .name{
        }

        .status{
            font-weight: normal;
            font-size: 12px;
        }
    }

    .infos{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        margin-left: auto;
        gap: 6px 10px;

        @media (min-width: $s-mobile-md) {
            justify-content: flex-end;
            flex-wrap: nowrap;
            flex: 1 0 auto;
        }

        .date{
            font-size: 14px;
        }
    }
}
</style>