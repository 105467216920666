import * as Sentry from '@sentry/browser';

Sentry.init({

    // GENERAL
    dsn: window.sentry_dsn,
    environment: window.env,

    // REPLAYS
    replaysSessionSampleRate: 0.0, // Sample rate. You may want this to be 100% whilein development and sample at a lower rate in production
    replaysOnErrorSampleRate: 1.0, // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.

    integrations: [
        new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
        }),
    ],

});

