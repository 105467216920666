<template>
    <v-overlay
        v-model="modelLocal"
        scroll-strategy="block"
        persistent
        contained
    >
        <v-progress-circular
            :width="3"
            :size="40"
            indeterminate
            style="position:relative"
        ></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
    props: {
        visible:{
            required: true,
            default: false,
        },
    },

    computed: {

        modelLocal: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:modelValue', value)
            },
        },

    },
}
</script>



<style lang="scss" scoped>

.v-overlay{
    //justify-content: flex-end;
    //align-items: flex-start;
    //padding: 10px;

    justify-content: center;
    align-items: flex-start;
    padding-top: 33vh;
}

</style>