<template>
    <div class="page-wrapper">
        <PageHeader>
            <template v-slot:left>
                <div class="h3">Styleguide</div>
            </template>
        </PageHeader>

        <PageContent>
            <div class="page-limiter">

                <hr>
                <br>

                <h2>Icons</h2>
                <br>

                <div class="icons" style="display:flex; flex-wrap:wrap; align-items:center; gap:5px; background-color:white; padding: 5px;">
                    <v-icon icon="icon-account"></v-icon>
                    <v-icon icon="icon-arrow"></v-icon>
                    <v-icon icon="icon-arrow-angle-double"></v-icon>
                    <v-icon icon="icon-arrow-angle-down"></v-icon>
                    <v-icon icon="icon-arrow-angle-left"></v-icon>
                    <v-icon icon="icon-arrow-angle-right"></v-icon>
                    <v-icon icon="icon-arrow-angle-up"></v-icon>
                    <v-icon icon="icon-arrow-down"></v-icon>
                    <v-icon icon="icon-arrow-left"></v-icon>
                    <v-icon icon="icon-arrow-right"></v-icon>
                    <v-icon icon="icon-arrow-up"></v-icon>
                    <v-icon icon="icon-book"></v-icon>
                    <v-icon icon="icon-bookmark"></v-icon>
                    <v-icon icon="icon-burger"></v-icon>
                    <v-icon icon="icon-calendar"></v-icon>
                    <v-icon icon="icon-camera"></v-icon>
                    <v-icon icon="icon-chart"></v-icon>
                    <v-icon icon="icon-chat"></v-icon>
                    <v-icon icon="icon-check"></v-icon>
                    <v-icon icon="icon-checkbox"></v-icon>
                    <v-icon icon="icon-checkbox-checked"></v-icon>
                    <v-icon icon="icon-computer"></v-icon>
                    <v-icon icon="icon-copy"></v-icon>
                    <v-icon icon="icon-cross"></v-icon>
                    <v-icon icon="icon-cursor"></v-icon>
                    <v-icon icon="icon-download"></v-icon>
                    <v-icon icon="icon-edit"></v-icon>
                    <v-icon icon="icon-email"></v-icon>
                    <v-icon icon="icon-euro"></v-icon>
                    <v-icon icon="icon-eye-closed"></v-icon>
                    <v-icon icon="icon-eye-opened"></v-icon>
                    <v-icon icon="icon-filter"></v-icon>
                    <v-icon icon="icon-globe"></v-icon>
                    <v-icon icon="icon-help"></v-icon>
                    <v-icon icon="icon-home"></v-icon>
                    <v-icon icon="icon-info"></v-icon>
                    <v-icon icon="icon-label"></v-icon>
                    <v-icon icon="icon-link-external"></v-icon>
                    <v-icon icon="icon-lock"></v-icon>
                    <v-icon icon="icon-mail"></v-icon>
                    <v-icon icon="icon-map-pin"></v-icon>
                    <v-icon icon="icon-minus"></v-icon>
                    <v-icon icon="icon-phone"></v-icon>
                    <v-icon icon="icon-picture"></v-icon>
                    <v-icon icon="icon-play"></v-icon>
                    <v-icon icon="icon-plus"></v-icon>
                    <v-icon icon="icon-radio"></v-icon>
                    <v-icon icon="icon-radio-checked"></v-icon>
                    <v-icon icon="icon-save"></v-icon>
                    <v-icon icon="icon-search"></v-icon>
                    <v-icon icon="icon-send"></v-icon>
                    <v-icon icon="icon-settings"></v-icon>
                    <v-icon icon="icon-share"></v-icon>
                    <v-icon icon="icon-smartphone"></v-icon>
                    <v-icon icon="icon-star-empty"></v-icon>
                    <v-icon icon="icon-star-half"></v-icon>
                    <v-icon icon="icon-star-full"></v-icon>
                    <v-icon icon="icon-stop"></v-icon>
                    <v-icon icon="icon-suitcase"></v-icon>
                    <v-icon icon="icon-time"></v-icon>
                    <v-icon icon="icon-trash"></v-icon>
                    <v-icon icon="icon-user"></v-icon>
                    <v-icon icon="icon-users"></v-icon>
                </div>


                <br>
                <br>

                <h2>Tags</h2>
                <br>

                <div class="tags" style="display:flex; align-items:center; gap:5px; background-color:white; padding: 5px;">
                    <div class="tag">Normal</div>
                    <div class="tag tag-small">Normal S</div>
                    <div class="tag tag-neutral">Neutral</div>
                    <div class="tag tag-small tag-neutral">Neutral S</div>
                </div>

                <div class="tags" style="display:flex; align-items:center; gap:5px; background-color:white; padding: 5px;">
                    <div class="tag tag-rounded"><v-icon icon="$phone"></v-icon></div>
                    <div class="tag tag-rounded tag-small"><v-icon icon="$phone"></v-icon></div>
                    <div class="tag tag-rounded tag-neutral"><v-icon icon="$phone"></v-icon></div>
                    <div class="tag tag-rounded tag-neutral tag-small "><v-icon icon="$phone"></v-icon></div>
                </div>

                <br>
                <hr>
                <br>
                <h2>Chips</h2>
                <br>


                <div class="chips" style="display:flex; align-items:center; gap:5px; background-color:white; padding: 5px;">
                    <v-chip>Normal</v-chip>
                    <v-chip class="small">Small</v-chip>
                </div>

                <br>
                <hr>
                <br>
                <h2>Forms</h2>
                <br>

                <v-form @submit.stop.prevent="submit">

                    <div class="field">
                        <v-label>Label</v-label>
                        <v-text-field placeholder="Text field"
                                      v-model="field1"
                                      :error-messages="v$.field1.$errors.map(e => e.$message)"
                        ></v-text-field>
                    </div>

                    <div class="field required">
                        <v-label>Label</v-label>
                        <v-text-field placeholder="Text field required"
                                      v-model="field2"
                                      :error-messages="v$.field2.$errors.map(e => e.$message)"
                        ></v-text-field>
                    </div>

                    <div class="field">
                        <v-label>Label</v-label>
                        <v-text-field placeholder="Text field with hint"
                                      hint="This is an hint"
                                      v-model="field3"
                                      :error-messages="v$.field3.$errors.map(e => e.$message)"
                        ></v-text-field>
                    </div>

                    <div class="field">
                        <v-label>Label</v-label>
                        <v-text-field placeholder="Text field with icon" prepend-inner-icon="icon-search"></v-text-field>
                    </div>

                    <div class="field required">
                        <v-label>Label</v-label>
                        <v-select placeholder="Select"
                                  :items="['Option 1', 'Option 2', 'Option 3']"
                                  v-model="field4"
                                  :error-messages="v$.field4.$errors.map(e => e.$message)"
                        ></v-select>
                    </div>

                    <div class="field required">
                        <v-label>Label</v-label>
                        <v-textarea placeholder="Label"
                                    v-model="field5"
                                    :error-messages="v$.field5.$errors.map(e => e.$message)"
                        ></v-textarea>
                    </div>

                    <div class="field">
                    <v-label>Label</v-label>
                    <v-file-input label="File input"></v-file-input>
                    </div>

                    <div class="field">
                        <v-label>Label</v-label>
                        <v-checkbox label="Checkbox"></v-checkbox>
                    </div>

                    <div class="field">
                        <v-label>Label</v-label>
                        <v-radio label="Radio" value="1"></v-radio>
                    </div>

                    <div class="field">
                        <v-label>Label</v-label>
                        <v-switch label="Switch"></v-switch>
                    </div>

                    <v-btn type="submit" class="button">Submit</v-btn>
                </v-form>

                <br>
                <hr>
                <br>

                <h2>Buttons</h2>
                <br>

                <v-btn>Initial</v-btn>
                <br>
                <br>
                <v-btn class="button">Basic</v-btn>
                <v-btn class="button" prepend-icon="$close">Basic</v-btn>
                <v-btn class="button button-small">Basic</v-btn>
                <v-btn class="button button-small" prepend-icon="$close">Basic</v-btn>
                <br>
                <br>
                <v-btn class="button button-alt">Basic alt</v-btn>
                <v-btn class="button button-alt" prepend-icon="$close">Basic alt</v-btn>
                <v-btn class="button button-alt button-small">Basic alt</v-btn>
                <v-btn class="button button-alt button-small" prepend-icon="$close">Basic alt</v-btn>
                <br>
                <br>
                <v-btn class="button button-secondary" prepend-icon="$close">Secondary</v-btn>
                <v-btn class="button button-secondary button-small" prepend-icon="$close">Secondary</v-btn>
                <br>
                <br>
                <v-btn class="button button-secondary-alt" prepend-icon="$close">Secondary alt</v-btn>
                <v-btn class="button button-secondary-alt button-small" prepend-icon="$close">Secondary alt</v-btn>
                <br>
                <br>
                <v-btn class="button button-neutral" prepend-icon="$close">Neutral</v-btn>
                <v-btn class="button button-neutral button-small" prepend-icon="$close">Neutral</v-btn>
                <br>
                <br>
                <v-btn class="button" prepend-icon="$close" disabled>Disabled</v-btn>
                <v-btn class="button button-small" prepend-icon="$close" disabled>Disabled</v-btn>
                <br>
                <br>
                <v-btn icon="$close"></v-btn>
                <v-btn class="button" icon="$close"></v-btn>
                <v-btn class="button">B</v-btn>
                <v-btn class="button button-small" icon="$close"></v-btn>
                <v-btn class="button button-small">B</v-btn>
                <v-btn class="button button-secondary" icon="$close"></v-btn>
                <v-btn class="button button-secondary button-small" icon="$close"></v-btn>
                <v-btn class="button button-neutral" icon="$close"></v-btn>
                <v-btn class="button button-neutral button-small" icon="$close"></v-btn>
                <v-btn class="button button-neutral button-round" icon="$close"></v-btn>
                <v-btn class="button button-neutral button-round button-small " icon="$close"></v-btn>
                <v-btn class="button" icon="$close" disabled></v-btn>
                <v-btn class="button button-small" icon="$close" disabled></v-btn>

                <br>
                <br>
                <hr>
                <br>

            </div>
        </PageContent>

    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import PageHeader from "@/components/commons/PageHeader.vue"
import PageContent from "@/components/commons/PageContent.vue"

export default {

    components: { PageContent, PageHeader },

    data() {
        return {
            field1: null,
            field2: null,
            field3: null,
            field4: null,
            field5: null,
        }
    },

    setup() {
        return {
            v$: useVuelidate(),
        }
    },

    validations() {
        return {
            field1: {  },
            field2: { required },
            field3: {  },
            field4: { required },
            field5: { required },
        }
    },

    methods: {

        async submit (event) {
            const isValid = await this.v$.$validate()
            if (isValid) {
                this.isWaiting = true
            }
        },

    },
}
</script>

<style lang="scss" scoped>
</style>

