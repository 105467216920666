import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore, ROLES } from '@/stores/auth.store'

import PageUserLogin from '@/components/pages/PageUserLogin';
import PageUserLogout from '@/components/pages/PageUserLogout';
import PageUserRegister from '@/components/pages/PageUserRegister';
import PagePasswordReset from '@/components/pages/PagePasswordReset';
import PagePasswordChange from '@/components/pages/PagePasswordChange';
import PageInquiriesList from '@/components/pages/PageInquiriesList';
import PageInquiriesDetail from '@/components/pages/PageInquiriesDetail';
import PageParameters from '@/components/pages/PageParameters';
import PageStyleguide from '@/components/pages/_PageStyleguide';
import PageTest from '@/components/pages/_PageTest';

// Routes configuration
const routes = [
    // Routes (public)
    { path: '/login', name: 'login', component: PageUserLogin, meta: { requiredRole: ROLES.PUBLIC_ACCESS } },
    { path: '/logout', name: 'logout', component: PageUserLogout, meta: { requiredRole: ROLES.PUBLIC_ACCESS } },
    { path: '/password-reset', name: 'password.reset', component: PagePasswordReset, meta: { requiredRole: ROLES.PUBLIC_ACCESS } },
    { path: '/password-change', name: 'password.change', component: PagePasswordChange, props: route => ({ token: route.query.token }), meta: { requiredRole: ROLES.PUBLIC_ACCESS } },
    { path: '/register', name: 'register', component: PageUserRegister, meta: { requiredRole: ROLES.PUBLIC_ACCESS } },

    // Routes (restricted)
    { path: '/inquiries', name: 'inquiries.list', component: PageInquiriesList, meta: { requiredRole: ROLES.ROLE_USER } },
    { path: '/inquiries/:id', name: 'inquiries.edit', component: PageInquiriesDetail, props: route => ({ id: Number(route.params.id) }), meta: { requiredRole: ROLES.ROLE_USER } },
    { path: '/parameters', name: 'parameters', component: PageParameters, meta: { requiredRole: ROLES.ROLE_USER } },
    { path: '/page-test', name: 'page.test', component: PageTest, meta: { requiredRole: ROLES.ROLE_USER } },
    { path: '/page-styleguide', name: 'page.styleguide', component: PageStyleguide, meta: { requiredRole: ROLES.ROLE_USER } },

    // Redirects
    { path: '/home', name: 'home', redirect: { name: 'inquiries.list' } },

    // Not found redirect
    { path: '/:pathMatch(.*)*', name: 'not-found', component: PageUserLogin },
]

// Router instantiation
const appRouter = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

appRouter.beforeEach((to, from, next) => {
    const authStore = useAuthStore()
    const isAuthenticated = authStore.isAuthenticated || to.meta.requiredRole === ROLES.PUBLIC_ACCESS;
    const isGranted = authStore.isRoleGranted(to.meta.requiredRole)

    // Redirect to login page if not logged in
    if (!isAuthenticated) {
        // console.warn('not authenticated')

        next({
            path: '/login',
            replace: true
        })
    }
    // When the user role is not sufficient
    else if (!isGranted) {
        console.warn('not granted')
    }
    else{
        next()
    }
})

export default appRouter