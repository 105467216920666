<template>
    <TemplateLogin>
        <template v-slot:picture>
            <img src="@images/splash-1.jpg">
        </template>

        <template v-slot:content>

            <div class="container">

                <div class="title h1">{{ $t('app.page.user_login.title') }}</div>

                <Form
                    @valid="handleValid"
                    :data="data"
                    :validations="validations"
                    v-slot="{ isFilled }"
                >

                    <Input
                        :cols="12"
                        :label="$t('app.page.user_login.form.email.label')"
                        :placeholder="$t('app.page.user_login.form.email.placeholder')"
                        v-model="data.email"
                        path="data.email"
                        required
                        autocomplete="username"
                    >
                    </Input>

                    <Input
                        type="InputPassword"
                        :cols="12"
                        :label="$t('app.page.user_login.form.password.label')"
                        :placeholder="$t('app.page.user_login.form.password.placeholder')"
                        v-model="data.password"
                        path="data.password"
                        required
                        autocomplete="current-password"
                    >
                    </Input>

                    <v-col md="12" class="text-center">
                        <v-btn
                            class="button"
                            type="submit"
                            :disabled="!isFilled || isWaiting"
                        >
                            {{ $t('app.page.user_login.form.button_submit') }}
                        </v-btn>
                    </v-col>

                    <v-col md="12" class="text-center">
                        <v-btn :to="{ name: 'password.reset'}" class="link">
                            {{ $t('app.page.password_reset.access_button') }}
                        </v-btn>
                    </v-col>

                </Form>

            </div>

            <div class="container container-dense">

                <div class="text">
                    {{ $t('app.page.user_register.access_text') }}
                </div>

                <v-btn :to="{ name: 'register' }" class="button button-secondary">
                    {{ $t('app.page.user_register.access_button') }}
                </v-btn>

            </div>

        </template>
    </TemplateLogin>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store'

import { required, email } from '@/helpers.validators'

import TemplateLogin from "@/components/commons/TemplateLogin.vue"
import Form from "@/components/commons/Form.vue"
import Input from "@/components/commons/Input.vue"

export default {

    components: { TemplateLogin, Form, Input },

    setup() {
        const authStore = useAuthStore()

        return {
            authStore,
        }
    },

    mounted() {
        // this.authStore.logout()
    },

    data() {
        return {
            data: {
                email: null,
                password: null,
            },
            validations : {
                data: {
                    email: { required, email },
                    password: { required },
                },
            },
            isValid: false,
            isWaiting: false,
        }
    },

    methods: {

        handleValid(){

            this.isWaiting = true

            // Try to authenticate
            this.authStore.login(
                this.data.email,
                this.data.password,
            )
            .then((responses) => {
                if (responses.every(response => response.status === 200)) {
                    // If we are coming form a redirect, we return to the origin page, if not we go to the homepage
                    const redirect = this.$route.redirectedFrom
                    redirect ? this.$router.push(redirect) : this.$router.push({ name: 'home' })
                }
            })
            .catch((error) => {
                this.error = error.response.data.message
            })
            .finally(() => {
                this.isWaiting = false
            });

        },

    },

}
</script>

<style lang="scss" scoped>

.link{
    margin-top: 20px;
}

</style>

