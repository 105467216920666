<template>
    <div class="fields">
        <template
            v-for="translation,locale in modelValue"
            :key="translation"
        >
            <InputTextarea
                v-if="textarea"
                v-model="translation[field]"
                :placeholder="placeholder"
                :required="required"
                :autocomplete="autocomplete"
                :suffix="locale.toUpperCase()"
                :error-messages="getErrors(locale)"
                :rows="rows"
            ></InputTextarea>

            <InputText
                v-else
                v-model="translation[field]"
                :placeholder="placeholder"
                :required="required"
                :autocomplete="autocomplete"
                :suffix="locale.toUpperCase()"
                :error-messages="getErrors(locale)"
            ></InputText>
        </template>
    </div>
</template>

<script>
import InputText from "@/components/commons/InputText.vue"
import InputTextarea from "@/components/commons/InputTextarea.vue"

export default {

    inject: ['v$'],

    components: { InputText, InputTextarea },

    props: {
        modelValue: {
            required: true,
        },
        placeholder: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
        field: {
            required: true,
            type: String,
        },
        path: {
            required: true,
            type: String,
        },
        textarea: {
            required: false,
            type: Boolean,
            default: false,
        },
        rows: {
            required: false,
            type: Number,
        },
    },

    methods: {

        getErrors(locale){
            if(this.v$ && this.v$.$errors && this.path){ // NOTE : Since the error path could not be retrieved automatically in Input.vue, we inject the vuelidate instance to look deeper in the errors hierarchy.
                const key = this.path.replace('{locale}', locale)
                return this.v$.$errors.filter((item)=> item.$propertyPath === key).map(e => e.$message)
            }
        },

    },
}
</script>

<style lang="scss" scoped>
.locale {
    text-transform: uppercase;
    align-self: center;
    margin-right: 12px;
}

.fields{

    > * {

        &::v-deep(.v-input__append){
            margin-inline-start: 0px;
        }

        &:not(:last-child):not(:only-child){
            &::v-deep(.v-field) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }


        &:not(:first-child):not(:only-child){
            &::v-deep(.v-field) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &::v-deep(.v-text-field__suffix){
            display: none;
        }

    }
}
</style>
