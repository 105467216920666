<template>
    <v-navigation-drawer
        class="drawer-left"
        v-if="isDrawerAvailable"
        v-model="isDrawerFull"
        :temporary="$isSmallScreen()"
        :permanent="!$isSmallScreen()"
        touchless
        width="294"
        disable-resize-watcher
        disable-route-watcher
    >
        <div class="logo">

            <v-icon
                v-if="this.$isSmallScreen()"
                class="button-burger"
                :icon="'icon-cross'"
                @click.stop="burgerClose"
            >
            </v-icon>

            <img src="@images/logo-app.svg">

        </div>

        <div class="wrapper">

            <div class="undertaker"
                 v-if="authStore.undertaker"
            >
                <v-avatar
                    class="avatar"
                >
                    <img
                        v-if="authStore.undertaker?.logo?.picture"
                        :src="authStore.undertaker.logo.picture.media_preview"
                    >
                    <img
                        v-else
                        src="@images/undertaker-default-logo.jpg"
                    >
                </v-avatar>
                <div class="name h2">
                    {{ this.$trans(authStore.undertaker, 'name')}}
                </div>
            </div>

            <div class="menu-primary">
                <v-list class="text-left" density="compact" nav>
                    <v-list-item class="link"
                                 v-if="this.$isRoleGranted(ROLES.ROLE_USER)"
                                 @click="burgerClose"
                                 :to="{ name: 'inquiries.list'}"
                                 prepend-icon="icon-email"
                                 :title="$t('app.global.menu.inquiries')"
                                 :active="isActiveRoute(['/inquiries'])"
                    ></v-list-item>
                    <v-list-item class="link"
                                 v-if="this.$isRoleGranted(ROLES.ROLE_USER)"
                                 @click="handleOpenParameters"
                                 prepend-icon="icon-settings"
                                 :title="$t('app.global.menu.parameters')"
                                 :active="isActiveRoute(['/parameters'])"
                    ></v-list-item>
                    <v-list-item
                                 v-if="this.globalStore.config?.pageHelp"
                                 class="link"
                                 :href="this.globalStore.config.pageHelp"
                                 target="_blank"
                                 prepend-icon="icon-link-external"
                                 :title="$t('app.global.menu.help')"
                    ></v-list-item>
<!--                    <hr>-->
<!--                    <v-list-item class="link"-->
<!--                                 :to="{ name: 'page.styleguide'}"-->
<!--                                 prepend-icon="icon-picture"-->
<!--                                 title="Styleguide"-->
<!--                    ></v-list-item>-->
<!--                    <v-list-item class="link"-->
<!--                                 :to="{ name: 'page.test'}"-->
<!--                                 prepend-icon="icon-account"-->
<!--                                 title="Tests"-->
<!--                    ></v-list-item>-->
                </v-list>
            </div>

            <div class="menu-secondary">
                <v-btn
                    class="button button-small"
                    v-if="authStore?.undertaker?.enabled && authStore?.undertakerConfig?.pageConfigurator"
                    @click="handleOpenFormSendConfigurator"
                >
                    {{ $t('app.global.menu.send_configurator') }}
                </v-btn>

                <v-btn
                    class="button button-small button-secondary"
                    v-if="authStore?.undertaker?.enabled && authStore?.undertakerConfig?.pageConfigurator"
                    :href="authStore?.undertakerConfig?.pageConfigurator"
                    target="_blank"
                >
                    {{ $t('app.global.menu.create_inquiry') }}
                </v-btn>

                <v-btn
                    class="link"
                    :to="{ name: 'logout'}"
                    @click="burgerClose"
                >
                    {{ $t('app.global.menu.logout') }}
                </v-btn>
            </div>

<!--            <v-select-->
<!--                label="Locale"-->
<!--                v-model="$i18n.locale"-->
<!--                :items="globalStore.locales"-->
<!--            ></v-select>-->

<!--            drawerMode : {{ this.globalStore.drawerMode }}-->


                <PopupQuestion
                    :isVisible="isParametersPasswordPopupVisible"
                    :title="$t('app.popup.parameters_password.title')"
                    :text="$t('app.popup.parameters_password.text')"
                    inputType="password"
                    :inputSmall="true"
                    @submit="handleSubmitParametersPassword"
                    @cancel="handleCancelParametersPassword"
                    @update:modelValue="handleChangeParametersPassword"
                ></PopupQuestion>

        </div>


        <teleport to="#contextual-drawer">
            <transition name="fade">
                <div v-if="globalStore.isDrawerOpened">

                    <template v-if="globalStore.drawerMode === 'send-configurator'">

                        <FormSendConfigurator
                            @cancel="handleCancelFormSendConfigurator"
                            @submit="handleSubmitFormSendConfigurator"
                        />

                    </template>

                </div>
            </transition>
        </teleport>

    </v-navigation-drawer>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store';
import { useMessageStore } from '@/stores/message.store';
import { useAuthStore, ROLES } from '@/stores/auth.store.js';
import FormSendConfigurator from "@/components/forms/FormSendConfigurator.vue"
import PopupQuestion from "@/components/commons/PopupQuestion.vue"

import UndertakersService from '@/middleware/undertakers'

export default {
    components: { FormSendConfigurator, PopupQuestion },

    setup() {
        const globalStore = useGlobalStore()
        const messageStore = useMessageStore()
        const authStore = useAuthStore()
        return {
            globalStore,
            messageStore,
            authStore,
            ROLES,
        }
    },

    data() {
        return {
            isParametersPasswordPopupVisible: false,
        }
    },

    computed: {

        // Returns true if the drawer needs to be showed (by a click on the burger button, or if the screen is large enough)
        isDrawerFull: {
            get() {
                return this.globalStore.isBurgerOpened || !this.$isSmallScreen();
            },
            set(value) {
                this.globalStore.isBurgerOpened = value
            }
        },

        isDrawerAvailable() {
            return this.authStore.isAuthenticated
                && this.$route.meta.requiredRole !== ROLES.PUBLIC_ACCESS // Do not display the drawer on public access pages
                && !['not-found'/*, 'login', 'logout', 'register', 'password-reset', 'password-change'*/].includes(this.$route.name) // Do not display the drawer on specific pages
        },

    },

    methods: {

        /* region [event handlers] */

        handleOpenFormSendConfigurator(event) {

            // Open the drawer
            this.globalStore.drawerMode = 'send-configurator'
            this.globalStore.isDrawerOpened = true

        },

        handleCancelFormSendConfigurator(event) {

            // Close the drawer
            this.globalStore.isDrawerOpened = false
        },

        handleSubmitFormSendConfigurator(event) {

        },

        handleOpenParameters(event) {

            if(!this.authStore.hasAccessToParameters){
                // Open the popup
                this.isParametersPasswordPopupVisible = true
            }
            else{
                // Redirect to the parameters if the user has already given the correct password
                this.$router.push({ name: 'parameters' })
            }

        },

        handleCancelParametersPassword(event) {

            this.isParametersPasswordPopupVisible = false

        },

        handleChangeParametersPassword(value) {

            this.isParametersPasswordPopupVisible = value

        },

        handleSubmitParametersPassword(input) {

            this.isWaiting = true

            UndertakersService.checkParametersPassword(this.authStore.undertakerId, { input : input })
            .then((response) => {

                // Store a boolean to keep access to this page
                this.authStore.hasAccessToParameters = true

                // Redirect to the parameters
                this.$router.push({ name: 'parameters' })

                // Close the menu after navigating
                this.burgerClose()
            })
            .catch((error) => {
                this.messageStore.addInfo(error.response?.message ?? error.response?.data?.detail)
            })
            .finally(() => {
                // Close the popup
                this.isParametersPasswordPopupVisible = false
                this.isWaiting = false
            });

        },


        /* endregion */

        /* region [helpers] */

        // Methods that return true if one of the route passed in parameter matches with the current route. That allows to make a button active with multiple routes (useful for nested routes)
        isActiveRoute(routes) {
            const paths = Array.isArray(routes) ? routes : [routes]
            return paths.some(path => {
                return this.$route.path.startsWith(path)
            })
        },

        burgerClose() {
            this.globalStore.isBurgerOpened = false
        },

        /* endregion */

    },

}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';


.drawer-left{

    .button-burger{
        position: absolute;
        right: 0;
        margin: 0 15px;
    }

    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left:$padding-x;
        padding-right:$padding-x;
        min-height: calc(100% - ($header-height + 1px));
        opacity: 1;
        transition: opacity $t-duration ease $t-duration * 0.5;

        > *{
            width: 100%;
        }

    }

    .logo{
        height: $header-height;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $c-border;
        background-color: white;
        z-index: 1;

        position: sticky;
        top: 0;
        img{
            height: 18px;
        }
    }

    .undertaker{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 50px 0;

        .avatar{
            border: 1px solid $c-border;
            width: 80px;
            height: 80px;
            margin-bottom: 20px;
            border-radius: 8px;

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .name{

        }
    }

    .menu-primary{
        margin-bottom: 37px;
        font-weight: bold;
    }

    .menu-secondary{
        margin-top: auto;
        margin-bottom: $padding-x;

        .button{
            text-align: center;
            width: 100%;

            &:not(:last-child):not(:only-child){
                margin-bottom: 10px;
            }
        }

        .link{
            text-align: center;
            width: 100%;
            margin-top: 8px;
            font-weight: normal;
            text-decoration: underline;
            font-size: 14px;
        }
    }
}
</style>



