import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {
    resetPassword: (data) => {
        return axios.put(`${fosRouter.generate('api_password_reset')}`, data)
    },
    // TODO SECURITY! prevent calling this uri to often
    changePassword: (token, data) => {
        return axios.put(`${fosRouter.generate('api_password_change')}/${token}`, data)
    },
}