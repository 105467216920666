<template>
    <div class="page-wrapper">
        <PageHeader>

            <template v-slot:left>
                <v-btn class="link"
                        prepend-icon="$left"
                       :to="{ name: 'inquiries.list'}"
                >
                    {{ $t('app.global.actions.back') }}
                </v-btn>
            </template>

            <template v-slot:right>
                <v-btn class="button button-alt button-small no-text-mobile no-icon-desktop"
                       v-if="validator"
                       :disabled="isWaiting || validator.$invalid || !validator.$dirty"
                       :append-icon="'icon-save'"
                       @click="handleSave"
                >
                    {{ $t('app.global.actions.save_modifications') }}
                </v-btn>
            </template>

        </PageHeader>

        <PageContent>

            <Overlay :visible="isWaiting"></Overlay>

            <transition name="fade">
                <div class="page-limiter-xl"
                     v-if="isLoaded"
                >

                    <div class="section section-summary">

                        <div class="block block-identity">

                            <div class="picture">
                                <img v-if="deceasedCivility && deceasedCivility.avatar" :src="deceasedCivility.avatar.media_preview">
                            </div>

                            <div class="tags">
                                <div class="tag tag-neutral tag-small type">
                                    {{ $trans(data.inquiry.inquiryKind, 'name') }}
                                </div>
                                <div class="tag tag-neutral tag-small reference">
                                    {{ data.inquiry.reference }}
                                </div>
                            </div>

                            <div class="name h2">

                                {{ deceasedCivility ? $trans(deceasedCivility, 'shortLabel') : '' }}

                                <Highlight class="name"
                                           :text="data.inquiry?.deceasedIdentity?.firstName"
                                           :type="2"
                                ></Highlight>


                                {{ data.inquiry?.deceasedIdentity?.lastName }}
                            </div>

                            <div class="date">
                                {{ $dayjs(data.inquiry.createdAt).locale($i18n.locale).format($t('app.format.datetime-full'))}}
                            </div>

                        </div>

                        <div class="block block-price">
                            <div class="main h2">
                                <div class="text">{{ $t('app.page.inquiries.detail.price.title') }}* :</div>
                                <div class="price"
                                     :class="{ 'need-refresh': isPriceNeedRefresh }"
                                >
                                    {{ data.inquiry.estimateTotalPrice }}€
                                </div>
                            </div>
                            <div class="accessories h3">
                                <div class="text">{{ $t('app.page.inquiries.detail.price.including') }} :</div>
                                <div class="price"
                                     :class="{ 'need-refresh': isPriceNeedRefresh }"
                                >
                                    {{ data.inquiry.estimateProductsPrice }}€
                                </div>
                            </div>
                            <div class="notes text-small">
                                * {{ $t('app.page.inquiries.detail.price.notes') }}
                            </div>
                        </div>

                        <div class="block block-status">
                            <div class="title h3">
                                {{ $t('app.page.inquiries.detail.status.title') }}
                            </div>

                            <div class="statuts">
                                <v-chip-group
                                    filter
                                    mandatory="force"
                                    v-model="data.inquiry.status"
                                    @update:modelValue="handleInquiryStatusChange"
                                    :disabled="data.inquiry.locked"
                                >
                                    <template
                                        v-for="inquiryStatus in inquiryStatuses"
                                        :key="inquiryStatus"
                                    >
                                        <v-chip :value="inquiryStatus">{{ $t(`app.inquiries.status.${inquiryStatus}.status`) }}</v-chip>
                                    </template>
                                </v-chip-group>
                            </div>
                        </div>

                    </div>

                    <div class="section section-comments">

                        <div class="block block-call bg-dark"
                             v-if="data.inquiry.contactChoice === 'yes'"
                        >
                            <div class="title h3">
                                {{ $t('app.page.inquiries.detail.call.title') }} :
                            </div>
                            <div class="phone"
                                 v-if="data.inquiry.authorityIdentity.phone"
                            >
                                <v-icon icon="$phone"></v-icon>
                                <span>{{ data.inquiry.authorityIdentity.phone }}</span>
                            </div>
                        </div>

                        <div class="block block-comment"
                             v-if="data.inquiry.contactComment"
                        >
                            <div class="title h2">
                                {{ $t('app.page.inquiries.detail.comments.title') }}
                            </div>
                            <div class="comment">
                                {{ data.inquiry.contactComment }}
                            </div>
                        </div>

                    </div>

                    <div class="section-tabs">
                        <v-tabs
                            class="mobile-stacked"
                            v-model="currentTab"
                            grow
                        >
                            <v-tab value="authority">{{ $t('app.page.inquiries.detail.authority.tab') }}</v-tab>
                            <v-tab value="deceased">{{ $t('app.page.inquiries.detail.deceased.tab') }}</v-tab>
                            <v-tab value="preparation">{{ $t('app.page.inquiries.detail.preparation.tab') }}</v-tab>
                            <v-tab value="funeral">{{ $t('app.page.inquiries.detail.funeral.tab') }}</v-tab>
                            <v-tab value="products">{{ $t('app.page.inquiries.detail.products.tab') }}</v-tab>
                            <v-tab value="date-time">{{ $t('app.page.inquiries.detail.date-time.tab') }}</v-tab>
                            <v-tab value="death-notice">{{ $t('app.page.inquiries.detail.death-notice.tab') }}</v-tab>
                        </v-tabs>

                        <Form
                            :data="data"
                            :validations="validations"
                            ref="form"
                            @mounted="handleFormMounted"
                            @valid="handleSubmit"
                            no-gutters
                            :noRow="true"
                        >

                            <v-window
                                v-model="currentTab"
                            >
                                <v-window-item
                                    value="authority"
                                    eager
                                    :transition="false"
                                    :reverse-transition="false"
                                >
                                    <Highlight class="title h2" :text="$t('app.page.inquiries.detail.authority.title')"></Highlight>

                                    <v-row>

                                        <v-col :md="12">
                                            <div class="subtitle h3">{{ $t('app.page.parameters.agency.content.details.title') }}</div>
                                        </v-col>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.civility.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.civility.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.civility"
                                            path="data.inquiry.authorityIdentity.civility"
                                            :items="globalStore.config.civilities"
                                            itemValue="@id"
                                            itemTitle="label"
                                            :translated="true"
                                            required
                                        >
                                        </Input>

                                        <Input
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.lastName.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.lastName.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.lastName"
                                            path="data.inquiry.authorityIdentity.lastName"
                                            required
                                        >
                                        </Input>

                                        <Input
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.firstName.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.firstName.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.firstName"
                                            path="data.inquiry.authorityIdentity.firstName"
                                            required
                                        >
                                        </Input>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.relationship.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.relationship.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.relationship"
                                            path="data.inquiry.authorityIdentity.relationship"
                                            :items="globalStore.config.relationships"
                                            itemValue="@id"
                                            itemTitle="label"
                                            :translated="true"
                                            required
                                        >
                                        </Input>

                                        <Input
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.address1.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.address1.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.address1"
                                            path="data.inquiry.authorityIdentity.address1"
                                        >
                                        </Input>

                                        <Input
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.address2.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.address2.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.address2"
                                            path="data.inquiry.authorityIdentity.address2"
                                        >
                                        </Input>

                                        <Input
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.zip.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.zip.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.zip"
                                            path="data.inquiry.authorityIdentity.zip"
                                        >
                                        </Input>

                                        <Input
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.city.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.city.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.city"
                                            path="data.inquiry.authorityIdentity.city"
                                        >
                                        </Input>

                                        <Input
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.email.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.email.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.email"
                                            path="data.inquiry.authorityIdentity.email"
                                            required
                                        >
                                        </Input>

                                        <Input
                                            type="InputPhone"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.authority.form.phone.label')"
                                            :placeholder="$t('app.page.inquiries.detail.authority.form.phone.placeholder')"
                                            v-model="data.inquiry.authorityIdentity.phone"
                                            path="data.inquiry.authorityIdentity.phone"
                                            required
                                        >
                                        </Input>

                                    </v-row>

                                </v-window-item>

                                <v-window-item
                                    value="deceased"
                                    eager
                                    :transition="false"
                                    :reverse-transition="false"
                                >
                                    <Highlight class="title h2" :text="$t('app.page.inquiries.detail.deceased.title')"></Highlight>

                                    <v-container fluid>
                                        <v-row>

                                            <Input
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.civility.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.civility.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.civility"
                                                path="data.inquiry.deceasedIdentity.civility"
                                                :items="globalStore.config.civilities"
                                                itemValue="@id"
                                                itemTitle="label"
                                                :translated="true"
                                                required
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.birthName.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.birthName.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.birthName"
                                                path="data.inquiry.deceasedIdentity.birthName"
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.lastName.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.lastName.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.lastName"
                                                path="data.inquiry.deceasedIdentity.lastName"
                                                required
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.firstName.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.firstName.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.firstName"
                                                path="data.inquiry.deceasedIdentity.firstName"
                                                required
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.profession.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.profession.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.profession"
                                                path="data.inquiry.deceasedIdentity.profession"
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.occupation.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.occupation.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.occupation"
                                                path="data.inquiry.deceasedIdentity.occupation"
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.address1.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.address1.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.address1"
                                                path="data.inquiry.deceasedIdentity.address1"
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.address2.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.address2.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.address2"
                                                path="data.inquiry.deceasedIdentity.address2"
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.zip.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.zip.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.zip"
                                                path="data.inquiry.deceasedIdentity.zip"
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.city.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.city.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.city"
                                                path="data.inquiry.deceasedIdentity.city"
                                            >
                                            </Input>

                                            <Input
                                                type="InputDate"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.birthDate.label')"
                                                v-model="data.inquiry.deceasedIdentity.dateOfBirth"
                                                path="data.inquiry.deceasedIdentity.dateOfBirth"
                                                required
                                            >
                                            </Input>

                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.birthPlace.label')"
                                                :placeholder="$t('app.page.inquiries.detail.deceased.form.birthPlace.placeholder')"
                                                v-model="data.inquiry.deceasedIdentity.placeOfBirth"
                                                path="data.inquiry.deceasedIdentity.placeOfBirth"
                                            >
                                            </Input>

                                            <Input
                                                type="InputDate"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.deathDate.label')"
                                                v-model="data.inquiry.deathDate"
                                                path="data.inquiry.deathDate"
                                            >
                                            </Input>

                                            <Input
                                                type="InputTime"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.deceased.form.deathTime.label')"
                                                v-model="data.inquiry.deathTime"
                                                path="data.inquiry.deathTime"
                                            >
                                            </Input>

                                            <Input
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$trans(globalStore.config.deathPlaceKind, 'label')"
                                                :placeholder="$trans(globalStore.config.deathPlaceKind, 'label')"
                                                v-model="data.inquiry.deathPlaceKind"
                                                path="data.inquiry.deathPlaceKind"
                                                :items="globalStore.config.deathPlaceKind.choices"
                                                itemValue="value"
                                                itemTitle="label"
                                                :translated="true"
                                                @change="handleDeathPlaceKindChange"
                                            >
                                            </Input>

                                            <template
                                                v-if="data.inquiry.deathPlaceKind === 'health-establishment'
                                            ">
                                                <Input
                                                    type="InputAutocomplete"
                                                    :cols="6"
                                                    :label="$t('app.page.inquiries.detail.deceased.form.deathCity.label')"
                                                    :placeholder="$t('app.page.inquiries.detail.deceased.form.deathCity.placeholder')"
                                                    v-model="searchDeathPlaceCity"
                                                    v-model:modelRaw="searchDeathPlaceCityRaw"
                                                    @search="autocompleteCity"
                                                    itemValue="@id"
                                                    itemTitle="text"
                                                    clearable
                                                    @update:modelValue="handleDeathPlaceEstablishmentCityUpdate"
                                                    @change="handleDeathPlaceEstablishmentCityChange"
                                                >
                                                </Input>

                                                <Input
                                                    v-if="searchDeathPlaceCity"
                                                    type="InputSelect"
                                                    :cols="6"
                                                    :label="$t('app.page.inquiries.detail.deceased.form.deathPlace_establishment.label')"
                                                    :placeholder="$t('app.page.inquiries.detail.deceased.form.deathPlace_establishment.label')"
                                                    v-model="data.inquiry.deathPlace.establishment"
                                                    v-model:modelRaw="searchDeathPlaceEstablishment"
                                                    :items="cityFilteredEstablishments"
                                                    itemValue="@id"
                                                    itemTitle="name"
                                                    :itemOther="$t('app.global.actions.other')"
                                                    :translated="true"
                                                    :returnObject="false"
                                                    :loading="isLoadingDeathPlaceEstablishments"
                                                    @update:modelValue="handleDeathPlaceEstablishmentChange"
                                                    @change="validator?.$touch()"
                                                >
                                                </Input>

                                                <v-col :md="6" v-if="data.inquiry.deathPlace.establishment && searchDeathPlaceEstablishment">
                                                    <div class="field">
                                                        <v-label>{{ $t('app.page.inquiries.detail.deceased.form.deathPlace_address1.label') }}</v-label>
                                                        <div>{{ searchDeathPlaceEstablishment?.fullAddress }}</div>
                                                    </div>
                                                </v-col>

                                            </template>

                                            <template
                                                v-if="data.inquiry.deathPlaceKind === null
                                                || data.inquiry.deathPlaceKind === 'home'
                                                || (data.inquiry.deathPlaceKind === 'health-establishment' && searchDeathPlaceEstablishment && data.inquiry.deathPlace.establishment === null)
                                            ">
                                                <Input
                                                    v-if="data.inquiry.deathPlaceKind !== 'home'"
                                                    :cols="6"
                                                    :label="$t('app.page.inquiries.detail.deceased.form.deathPlace_name.label')"
                                                    :placeholder="$t('app.page.inquiries.detail.deceased.form.deathPlace_name.placeholder')"
                                                    v-model="data.inquiry.deathPlace.name"
                                                    path="data.inquiry.deathPlace.name"
                                                >
                                                </Input>
                                                <Input
                                                    :cols="6"
                                                    :label="$t('app.page.inquiries.detail.deceased.form.deathPlace_address1.label')"
                                                    :placeholder="$t('app.page.inquiries.detail.deceased.form.deathPlace_address1.placeholder')"
                                                    v-model="data.inquiry.deathPlace.address1"
                                                    path="data.inquiry.deathPlace.address1"
                                                >
                                                </Input>
                                                <Input
                                                    :cols="6"
                                                    :label="$t('app.page.inquiries.detail.deceased.form.deathPlace_address2.label')"
                                                    :placeholder="$t('app.page.inquiries.detail.deceased.form.deathPlace_address2.placeholder')"
                                                    v-model="data.inquiry.deathPlace.address2"
                                                    path="data.inquiry.deathPlace.address2"
                                                >
                                                </Input>

                                            </template>

                                        </v-row>
                                    </v-container>
                                </v-window-item>

                                <v-window-item
                                    value="preparation"
                                    eager
                                    :transition="false"
                                    :reverse-transition="false"
                                >
                                    <Highlight class="title h2" :text="$t('app.page.inquiries.detail.preparation.title')"></Highlight>

                                    <v-row>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$trans(globalStore.config.funeralHomeCurrent, 'label')"
                                            :placeholder="$trans(globalStore.config.funeralHomeCurrent, 'label')"
                                            v-model="data.inquiry.funeralHomeCurrent"
                                            path="data.inquiry.funeralHomeCurrent"
                                            :items="globalStore.config.funeralHomeCurrent.choices"
                                            itemValue="value"
                                            itemTitle="label"
                                            :translated="true"
                                            @update:modelValue="handleFuneralHomeCurrentChange"
                                        >
                                        </Input>

                                        <template
                                            v-if="data.inquiry.funeralHomeCurrent === 'yes'"
                                        >
                                            <Input
                                                type="InputAutocomplete"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_city.label')"
                                                :placeholder="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_city.placeholder')"
                                                :key="searchFuneralHomeCurrentCityClearer"
                                                v-model="searchFuneralHomeCurrentCity"
                                                v-model:modelRaw="searchFuneralHomeCurrentCityRaw"
                                                @search="autocompleteCity"
                                                itemValue="@id"
                                                itemTitle="text"
                                                clearable
                                                @update:modelValue="handleFuneralHomeCurrentCityUpdate"
                                                @change="handleFuneralHomeCurrentCityChange"
                                            >
                                            </Input>

                                            <Input
                                                v-if="searchFuneralHomeCurrentCity"
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_establishment.label')"
                                                :placeholder="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_establishment.label')"
                                                v-model="data.inquiry.funeralHomeCurrentPlace.establishment"
                                                v-model:modelRaw="searchFuneralHomeCurrent"
                                                :items="cityFilteredFuneralHomes"
                                                itemValue="@id"
                                                itemTitle="name"
                                                :itemOther="$t('app.global.actions.other')"
                                                :translated="true"
                                                :returnObject="false"
                                                :loading="isLoadingFuneralHomeCurrentEstablishments"
                                                @update:modelValue="handleFuneralHomeCurrentEstablishmentChange"
                                                @change="validator?.$touch()"
                                            >
                                            </Input>

                                            <!-- TODO! find a better way to do this : don't use the search model + the data model, trust only one source -->
                                            <v-col :md="6"
                                                   v-if="searchFuneralHomeCurrent && data.inquiry.funeralHomeCurrentPlace && data.inquiry.funeralHomeCurrentPlace.establishment !== null"
                                            >
                                                <div class="field">
                                                    <v-label>{{ $t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_address1.label') }}</v-label>
                                                    <div>{{ searchFuneralHomeCurrent.fullAddress }}</div>
                                                </div>
                                            </v-col>

                                            <!-- If we choose 'other' -->
                                            <template
                                                v-if="searchFuneralHomeCurrent && data.inquiry.funeralHomeCurrentPlace && data.inquiry.funeralHomeCurrentPlace.establishment === null"
                                            >

                                                <Input
                                                    :cols="6"
                                                    :label="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_name.label')"
                                                    :placeholder="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_name.placeholder')"
                                                    v-model="data.inquiry.funeralHomeCurrentPlace.name"
                                                    path="data.inquiry.funeralHomeCurrentPlace.name"
                                                >
                                                </Input>
                                                <Input
                                                    :cols="6"
                                                    :label="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_address1.label')"
                                                    :placeholder="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_address1.placeholder')"
                                                    v-model="data.inquiry.funeralHomeCurrentPlace.address1"
                                                    path="data.inquiry.funeralHomeCurrentPlace.address1"
                                                >
                                                </Input>
                                                <Input
                                                    :cols="6"
                                                    :label="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_address2.label')"
                                                    :placeholder="$t('app.page.inquiries.detail.preparation.form.funeralHomeCurrent_address2.placeholder')"
                                                    v-model="data.inquiry.funeralHomeCurrentPlace.address2"
                                                    path="data.inquiry.funeralHomeCurrentPlace.address2"
                                                >
                                                </Input>

                                            </template>
                                        </template>
                                        <template v-else>

                                            <!-- Displayed when the current undertaker has funeral homes -->
                                            <Input
                                                v-if="undertakerFuneralHomes"
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$trans(globalStore.config.funeralHomeFinal, 'label')"
                                                :placeholder="$trans(globalStore.config.funeralHomeFinal, 'label')"
                                                v-model="data.inquiry.funeralHomeFinal"
                                                path="data.inquiry.funeralHomeFinal"
                                                :items="globalStore.config.funeralHomeFinal.choices"
                                                itemValue="value"
                                                itemTitle="label"
                                                :translated="true"
                                                @update:modelValue="handleFuneralHomeFinalChange"
                                            >
                                            </Input>

                                            <template
                                                v-if="data.inquiry.funeralHomeFinal === 'funeral-home-select'"
                                            >
                                                <Input
                                                    type="InputSelect"
                                                    :cols="6"
                                                    :label="$t('app.page.inquiries.detail.preparation.form.funeralHomeFinal_establishment.label')"
                                                    :placeholder="$t('app.page.inquiries.detail.preparation.form.funeralHomeFinal_establishment.label')"
                                                    v-model="data.inquiry.funeralHomeFinalPlace.establishment"
                                                    v-model:modelRaw="searchFuneralHomeFinal"
                                                    :items="undertakerFuneralHomes"
                                                    itemValue="@id"
                                                    itemTitle="name"
                                                    :translated="true"
                                                    :returnObject="false"
                                                    @update:modelValue="handleFuneralHomeFinalEstablishmentChange"
                                                >
                                                </Input>

                                                <template
                                                    v-if="searchFuneralHomeFinal"
                                                >
                                                    <v-col :md="6">
                                                        <div class="field">
                                                            <v-label>{{ $t('app.page.inquiries.detail.preparation.form.funeralHomeFinal_address1.label') }}</v-label>
                                                            <div>{{ searchFuneralHomeFinal.fullAddress }}</div>
                                                        </div>
                                                    </v-col>
                                                </template>

                                            </template>

                                        </template>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$trans(globalStore.config.treatment, 'label')"
                                            :placeholder="$trans(globalStore.config.treatment, 'label')"
                                            v-model="data.inquiry.treatment"
                                            path="data.inquiry.treatment"
                                            :items="globalStore.config.treatment.choices"
                                            itemValue="value"
                                            itemTitle="label"
                                            :translated="true"
                                        >
                                        </Input>

                                    </v-row>

                                </v-window-item>

                                <v-window-item
                                    value="funeral"
                                    eager
                                    :transition="false"
                                    :reverse-transition="false"
                                >
                                    <Highlight class="title h2" :text="$t('app.page.inquiries.detail.funeral.title')"></Highlight>

                                    <div class="title h3">{{ $t('app.page.inquiries.detail.funeral.content.ceremony.title') }}</div>

                                    <v-row>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$trans(globalStore.config.ceremonyKind, 'label')"
                                            :placeholder="$trans(globalStore.config.ceremonyKind, 'label')"
                                            v-model="data.inquiry.ceremonyKind"
                                            path="data.inquiry.ceremonyKind"
                                            :items="globalStore.config.ceremonyKind.choices"
                                            itemValue="value"
                                            itemTitle="label"
                                            :translated="true"
                                            @update:modelValue="handleCeremonyKindChange"
                                        >
                                        </Input>

                                        <Input
                                            v-if="data.inquiry.ceremonyKind === 'civil'"
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$trans(globalStore.config.ceremonyPlaceKindCivil, 'label')"
                                            :placeholder="$trans(globalStore.config.ceremonyPlaceKindCivil, 'label')"
                                            v-model="data.inquiry.ceremonyPlaceKind"
                                            path="data.inquiry.ceremonyPlaceKind"
                                            :items="globalStore.config.ceremonyPlaceKindCivil.choices"
                                            itemValue="value"
                                            itemTitle="label"
                                            :translated="true"
                                            @change="handleCeremonyPlaceKindChange"
                                        >
                                        </Input>
                                        <Input
                                            v-else-if="data.inquiry.ceremonyKind === 'religious'"
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$trans(globalStore.config.ceremonyPlaceKindReligious, 'label')"
                                            :placeholder="$trans(globalStore.config.ceremonyPlaceKindReligious, 'label')"
                                            v-model="data.inquiry.ceremonyPlaceKind"
                                            path="data.inquiry.ceremonyPlaceKind"
                                            :items="globalStore.config.ceremonyPlaceKindReligious.choices"
                                            itemValue="value"
                                            itemTitle="label"
                                            :translated="true"
                                            @change="handleCeremonyPlaceKindChange"
                                        >
                                        </Input>

                                        <template v-if="data.inquiry.ceremonyKind === 'religious' && data.inquiry.ceremonyPlaceKind === 'worship-place'">

                                            <Input
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.funeral.form.religion.label')"
                                                :placeholder="$t('app.page.inquiries.detail.funeral.form.religion.placeholder')"
                                                v-model="data.inquiry.religion"
                                                path="data.inquiry.religion"
                                                :items="globalStore.config.religions"
                                                itemValue="@id"
                                                itemTitle="label"
                                                :translated="true"
                                                @change="handleReligionChange"
                                            >
                                            </Input>

                                            <Input
                                                type="InputAutocomplete"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_city.label')"
                                                :placeholder="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_city.placeholder')"
                                                :key="searchCeremonyPlaceCityClearer"
                                                v-model="searchCeremonyPlaceCity"
                                                v-model:modelRaw="searchCeremonyPlaceCityRaw"
                                                @search="autocompleteCity"
                                                itemValue="@id"
                                                itemTitle="text"
                                                clearable
                                                @update:modelValue="handleCeremonyPlaceCityUpdate"
                                                @change="handleCeremonyPlaceCityChange"
                                            >
                                            </Input>

                                            <Input
                                                v-if="(searchCeremonyPlaceCity && data.inquiry.ceremonyPlaceKind === 'worship-place' && data.inquiry.religion)"
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_establishment.label')"
                                                :placeholder="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_establishment.placeholder')"
                                                v-model="data.inquiry.ceremonyPlace.establishment"
                                                v-model:modelRaw="searchCeremonyPlaceEstablishment"
                                                path="data.inquiry.ceremonyPlace.establishment"
                                                :items="cityFilteredCeremonyPlaces"
                                                itemValue="@id"
                                                itemTitle="name"
                                                :itemOther="$t('app.global.actions.other')"
                                                :translated="true"
                                                @update:modelValue="handleCeremonyPlaceEstablishmentChange"
                                            >
                                            </Input>

                                        </template>

                                        <Input
                                            v-if="data.inquiry.ceremonyPlaceKind === 'funeral-home'"
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_establishment.label')"
                                            :placeholder="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_establishment.placeholder')"
                                            v-model="data.inquiry.ceremonyPlace.establishment"
                                            v-model:modelRaw="searchCeremonyPlaceEstablishment"
                                            path="data.inquiry.ceremonyPlace.establishment"
                                            :items="undertakerFuneralHomes"
                                            itemValue="@id"
                                            itemTitle="name"
                                            :translated="true"
                                            :returnObject="false"
                                            @update:modelValue="handleCeremonyPlaceEstablishmentChange"
                                        >
                                        </Input>

                                        <Input
                                            v-if="data.inquiry.ceremonyPlaceKind === 'crematorium'"
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_establishment.label')"
                                            :placeholder="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_establishment.placeholder')"
                                            v-model="data.inquiry.ceremonyPlace.establishment"
                                            v-model:modelRaw="searchCeremonyPlaceEstablishment"
                                            path="data.inquiry.ceremonyPlace.establishment"
                                            :items="undertakerCrematoriums"
                                            itemValue="@id"
                                            itemTitle="name"
                                            :translated="true"
                                            :returnObject="false"
                                            @update:modelValue="handleCeremonyPlaceEstablishmentChange"
                                        >
                                        </Input>


                                        <template
                                            v-if="data.inquiry.ceremonyPlace.establishment && searchCeremonyPlaceEstablishment"
                                        >
                                            <v-col :md="6">
                                                <div class="field">
                                                    <v-label>{{ $t('app.page.inquiries.detail.funeral.form.ceremonyPlace_address.label') }}</v-label>
                                                    <div>{{ searchCeremonyPlaceEstablishment.fullAddress }}</div>
                                                </div>
                                            </v-col>
                                        </template>

                                        <!-- If we choose 'other' -->
                                        <template
                                            v-if="searchCeremonyPlaceEstablishment && data.inquiry.ceremonyPlace && data.inquiry.ceremonyPlace.establishment === null"
                                        >
                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_name.label')"
                                                :placeholder="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_name.placeholder')"
                                                v-model="data.inquiry.ceremonyPlace.name"
                                                path="data.inquiry.ceremonyPlace.name"
                                            >
                                            </Input>
                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_address1.label')"
                                                :placeholder="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_address1.placeholder')"
                                                v-model="data.inquiry.ceremonyPlace.address1"
                                                path="data.inquiry.ceremonyPlace.address1"
                                            >
                                            </Input>
                                            <Input
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_address2.label')"
                                                :placeholder="$t('app.page.inquiries.detail.funeral.form.ceremonyPlace_address2.placeholder')"
                                                v-model="data.inquiry.ceremonyPlace.address2"
                                                path="data.inquiry.ceremonyPlace.address2"
                                            >
                                            </Input>
                                        </template>


                                    </v-row>

                                    <div class="spacer"></div>

                                    <div class="title h3">{{ $t('app.page.inquiries.detail.funeral.content.funeral.title') }}</div>

                                    <v-row>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.funeral.form.funeralKind.label')"
                                            :placeholder="$t('app.page.inquiries.detail.funeral.form.funeralKind.placeholder')"
                                            v-model="data.inquiry.funeralKind"
                                            v-model:modelRaw="funeralKindRaw"
                                            path="data.inquiry.funeralKind"
                                            :items="globalStore.config.funeralKinds"
                                            itemValue="@id"
                                            itemTitle="name"
                                            :translated="true"
                                            @change="handleFuneralKindChange"
                                        >
                                        </Input>

                                        <template
                                            v-if="funeralKindRaw && funeralKindRaw.code === 'cremation'"
                                        >

                                            <Input
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.funeral.form.crematorium_establishment.label')"
                                                :placeholder="$t('app.page.inquiries.detail.funeral.form.crematorium_establishment.placeholder')"
                                                v-model="data.inquiry.crematoriumPlace.establishment"
                                                v-model:modelRaw="searchCrematoriumPlaceEstablishment"
                                                :items="undertakerCrematoriums"
                                                itemValue="@id"
                                                itemTitle="name"
                                                :translated="true"
                                                :returnObject="false"
                                            >
                                            </Input>

                                            <template
                                                v-if="searchCrematoriumPlaceEstablishment"
                                            >
                                                <v-col :md="6">
                                                    <div class="field">
                                                        <v-label>{{ $t('app.page.inquiries.detail.funeral.form.crematorium_address.label') }}</v-label>
                                                        <div>{{ searchCrematoriumPlaceEstablishment.fullAddress }}</div>
                                                    </div>
                                                </v-col>
                                            </template>

                                            <Input
                                                v-if="data.inquiry.crematoriumPlace.establishment"
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$trans(globalStore.config.followingCremation, 'label')"
                                                :placeholder="$trans(globalStore.config.followingCremation, 'label')"
                                                v-model="data.inquiry.followingCremation"
                                                path="data.inquiry.followingCremation"
                                                :items="globalStore.config.followingCremation.choices"
                                                itemValue="value"
                                                itemTitle="label"
                                                :translated="true"
                                                @change="handleFollowingCremationChange"
                                            >
                                            </Input>

                                            <template
                                                v-if="data.inquiry.followingCremation === 'dispersal'"
                                            >

                                                <Input
                                                    type="InputSelect"
                                                    :cols="6"
                                                    :label="$trans(globalStore.config.dispersalPlaceKind, 'label')"
                                                    :placeholder="$trans(globalStore.config.dispersalPlaceKind, 'label')"
                                                    v-model="data.inquiry.dispersalPlaceKind"
                                                    path="data.inquiry.dispersalPlaceKind"
                                                    :items="globalStore.config.dispersalPlaceKind.choices"
                                                    itemValue="value"
                                                    itemTitle="label"
                                                    :translated="true"
                                                    @change="handleDispersalPlaceKindChange"
                                                >
                                                </Input>

                                            </template>
                                        </template>

                                        <template v-if="
                                            (funeralKindRaw && funeralKindRaw.code === 'inhumation')
                                            || (data.inquiry.followingCremation === 'dispersal' && data.inquiry.dispersalPlaceKind === 'garden')
                                            || (['burial', 'columbarium', 'sealing'].includes(data.inquiry.followingCremation))
                                        ">
                                            <Input
                                                type="InputAutocomplete"
                                                :cols="6"
                                                :label="$t('app.page.inquiries.detail.funeral.form.cemetaryPlace_city.label')"
                                                :placeholder="$t('app.page.inquiries.detail.funeral.form.cemetaryPlace_city.placeholder')"
                                                :key="searchCemetaryPlaceCityClearer"
                                                v-model="data.inquiry.cemetaryPlace.city"
                                                v-model:modelRaw="searchCemetaryPlaceCityRaw"
                                                @search="autocompleteCity"
                                                itemValue="@id"
                                                itemTitle="text"
                                                clearable
                                                @update:modelValue="handleCemetaryPlaceCityUpdate"
                                                @change="handleCemetaryPlaceCityChange"
                                            >
                                            </Input>

                                        </template>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$trans(globalStore.config.lastTribute, 'label')"
                                            :placeholder="$trans(globalStore.config.lastTribute, 'label')"
                                            v-model="data.inquiry.lastTribute"
                                            path="data.inquiry.lastTribute"
                                            :items="globalStore.config.lastTribute.choices"
                                            itemValue="value"
                                            itemTitle="label"
                                            :translated="true"
                                        >
                                        </Input>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$trans(globalStore.config.plot, 'label')"
                                            :placeholder="$trans(globalStore.config.plot, 'label')"
                                            v-model="data.inquiry.plot"
                                            path="data.inquiry.plot"
                                            :items="globalStore.config.plot.choices"
                                            itemValue="value"
                                            itemTitle="label"
                                            :translated="true"
                                            @change="handlePlotChange"
                                        >
                                        </Input>


                                        <template
                                            v-if="data.inquiry.plot === 'yes'"
                                        >

                                            <Input
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$trans(globalStore.config.tomb, 'label')"
                                                :placeholder="$trans(globalStore.config.tomb, 'label')"
                                                v-model="data.inquiry.tomb"
                                                path="data.inquiry.tomb"
                                                :items="globalStore.config.tomb.choices"
                                                itemValue="value"
                                                itemTitle="label"
                                                :translated="true"
                                            >
                                            </Input>

                                            <Input
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$trans(globalStore.config.funeraryMonument, 'label')"
                                                :placeholder="$trans(globalStore.config.funeraryMonument, 'label')"
                                                v-model="data.inquiry.funeraryMonument"
                                                path="data.inquiry.funeraryMonument"
                                                :items="globalStore.config.funeraryMonument.choices"
                                                itemValue="value"
                                                itemTitle="label"
                                                :translated="true"
                                            >
                                            </Input>

                                            <Input
                                                type="InputSelect"
                                                :cols="6"
                                                :label="$trans(globalStore.config.masonry, 'label')"
                                                :placeholder="$trans(globalStore.config.masonry, 'label')"
                                                v-model="data.inquiry.masonry"
                                                path="data.inquiry.masonry"
                                                :items="globalStore.config.masonry.choices"
                                                itemValue="value"
                                                itemTitle="label"
                                                :translated="true"
                                            >
                                            </Input>

                                        </template>

                                    </v-row>

                                </v-window-item>

                                <v-window-item
                                    value="products"
                                    eager
                                    :transition="false"
                                    :reverse-transition="false"
                                >
                                    <Highlight class="title h2" :text="$t('app.page.inquiries.detail.products.title')"></Highlight>

                                    <template
                                        v-if="productCategories && productCategories.length"
                                        v-for="productCategory in productCategories"
                                    >

                                        <div class="product-category">

                                            <div class="heading">
                                                <div class="h3">
                                                    {{ productCategory.translations[$i18n.locale].name }}
                                                </div>
                                            </div>

                                            <div class="products">
                                                <template
                                                    v-if="undertakerProducts && undertakerProducts.length"
                                                    v-for="product in filterProductsByCategory(productCategory)"
                                                    :key="product.id"
                                                >
                                                    <v-checkbox
                                                        v-model="localProducts[productCategory.code]"
                                                        class="product"
                                                        :class="{ 'active': productIsChecked(product['@id'], productCategory.code) }"
                                                        :value="product['@id']"
                                                        :multiple="false"
                                                        true-icon="$radioOn"
                                                        false-icon="$radioOff"
                                                        @update:modelValue="handleProductChange"
                                                    >
                                                        <template v-slot:label>
                                                            <div class="wrapper">

                                                                <div class="picture-and-infos">
                                                                    <div class="picture">
                                                                        <img v-if="product?.mainPicture?.picture?.media_preview"
                                                                             :src="product.mainPicture.picture.media_preview"
                                                                        >
                                                                    </div>

                                                                    <div class="infos">
                                                                        <div class="name">
                                                                            {{ this.$trans(product, 'name') }}
                                                                        </div>

                                                                        <div class="material">
                                                                            {{ this.$trans(product, 'material') }}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="price">
                                                                    {{ product.price }} €
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </v-checkbox>

                                                </template>
                                            </div>
                                        </div>

                                    </template>

                                </v-window-item>

                                <v-window-item
                                    value="date-time"
                                    eager
                                    :transition="false"
                                    :reverse-transition="false"
                                >
                                    <Highlight class="title h2" :text="$t('app.page.inquiries.detail.date-time.title')"></Highlight>

                                    <v-row>

                                        <Input
                                            type="InputDate"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.date-time.form.funeralDate1.label')"
                                            v-model="data.inquiry.funeralDate1"
                                            path="data.inquiry.funeralDate1"
                                            disabled
                                        >
                                        </Input>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.date-time.form.funeralSlot1.label')"
                                            :placeholder="$t('app.page.inquiries.detail.date-time.form.funeralSlot1.placeholder')"
                                            v-model="data.inquiry.funeralSlot1"
                                            path="data.inquiry.funeralSlot1"
                                            :items="funeralSlots"
                                            itemValue="value"
                                            itemTitle="name"
                                            disabled
                                        >
                                        </Input>

                                        <Input
                                            type="InputDate"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.date-time.form.funeralDate2.label')"
                                            v-model="data.inquiry.funeralDate2"
                                            path="data.inquiry.funeralDate2"
                                            disabled
                                        >
                                        </Input>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.date-time.form.funeralSlot2.label')"
                                            :placeholder="$t('app.page.inquiries.detail.date-time.form.funeralSlot2.placeholder')"
                                            v-model="data.inquiry.funeralSlot2"
                                            path="data.inquiry.funeralSlot2"
                                            :items="funeralSlots"
                                            itemValue="value"
                                            itemTitle="name"
                                            disabled
                                        >
                                        </Input>

                                        <Input
                                            type="InputDate"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.date-time.form.funeralDate3.label')"
                                            v-model="data.inquiry.funeralDate3"
                                            path="data.inquiry.funeralDate3"
                                            disabled
                                        >
                                        </Input>

                                        <Input
                                            type="InputSelect"
                                            :cols="6"
                                            :label="$t('app.page.inquiries.detail.date-time.form.funeralSlot3.label')"
                                            :placeholder="$t('app.page.inquiries.detail.date-time.form.funeralSlot3.placeholder')"
                                            v-model="data.inquiry.funeralSlot3"
                                            path="data.inquiry.funeralSlot3"
                                            :items="funeralSlots"
                                            itemValue="value"
                                            itemTitle="name"
                                            disabled
                                        >
                                        </Input>

                                    </v-row>

                                    <div class="spacer"></div>

                                    <div class="heading">
                                        <Highlight class="title h2" :text="$t('app.page.inquiries.detail.date-time.summary.title')"></Highlight>

                                        <div class="tools">
                                            <v-btn
                                                v-if="validator"
                                                class="button button-small block"
                                                :disabled="validator.$dirty || isDateTimeEmpty"
                                                @click="handleOpenFormFuneralSummary"
                                            >
                                                {{ $t('app.page.inquiries.detail.date-time.summary.button.send') }}
                                            </v-btn>
                                        </div>
                                    </div>

                                    <Notice :isVisible="isDateTimeEmpty">{{ $t('app.page.inquiries.detail.date-time.summary.notice') }}</Notice>

                                    <div class="steps">

                                        <v-row class="step"
                                                v-if="getPlacementPlace"
                                        >
                                            <v-col :md="6" class="col">
                                                <div class="wrapper">
                                                    <div class="name h3">
                                                        <v-icon icon="icon-calendar"></v-icon>
                                                        {{ getPlacementPlace.label }}
                                                    </div>
                                                    <div class="place">{{ getPlacementPlace.name }}</div>
                                                    <div class="address">{{ getPlacementPlace.address }}</div>
                                                </div>
                                            </v-col>

                                            <Input
                                                type="InputDate"
                                                :cols="3"
                                                :label="$t('app.page.inquiries.detail.date-time.form.date.label') + ' ' + $t('app.page.inquiries.detail.date-time.summary.placement.title')"
                                                v-model="data.inquiry.placementDate"
                                                path="data.inquiry.placementDate"
                                                important
                                            >
                                            </Input>

                                            <Input
                                                type="InputTime"
                                                :cols="3"
                                                :label="$t('app.page.inquiries.detail.date-time.form.time.label') + ' ' + $t('app.page.inquiries.detail.date-time.summary.placement.title')"
                                                v-model="data.inquiry.placementTime"
                                                path="data.inquiry.placementTime"
                                                important
                                            >
                                            </Input>
                                        </v-row>

                                        <v-row class="step"
                                               v-if="['civil', 'religious'].includes(data.inquiry.ceremonyKind) && getCeremonyPlace"
                                        >
                                            <v-col :md="6" class="col">
                                                <div class="wrapper">
                                                    <div class="name h3">
                                                        <v-icon icon="icon-calendar"></v-icon>
                                                        {{ getCeremonyPlace.label }}
                                                    </div>
                                                    <div class="place">{{ getCeremonyPlace.name }}</div>
                                                    <div class="address">{{ getCeremonyPlace.address }}</div>
                                                </div>
                                            </v-col>

                                            <Input
                                                type="InputDate"
                                                :cols="3"
                                                :label="$t('app.page.inquiries.detail.date-time.form.date.label') + ' ' + $t('app.page.inquiries.detail.date-time.summary.ceremony.title')"
                                                v-model="data.inquiry.ceremonyDate"
                                                path="data.inquiry.ceremonyDate"
                                                important
                                            >
                                            </Input>

                                            <Input
                                                type="InputTime"
                                                :cols="3"
                                                :label="$t('app.page.inquiries.detail.date-time.form.time.label') + ' ' + $t('app.page.inquiries.detail.date-time.summary.ceremony.title')"
                                                v-model="data.inquiry.ceremonyTime"
                                                path="data.inquiry.ceremonyTime"
                                                important
                                            >
                                            </Input>
                                        </v-row>

                                        <v-row class="step"
                                               v-if="funeralKindRaw && funeralKindRaw.code === 'cremation' && getCremationPlace"
                                        >
                                            <v-col :md="6" class="col">
                                                <div class="wrapper">
                                                    <div class="name h3">
                                                        <v-icon icon="icon-calendar"></v-icon>
                                                        {{ getCremationPlace.label }}
                                                    </div>
                                                    <div class="place">{{ getCremationPlace.name }}</div>
                                                    <div class="address">{{ getCremationPlace.address }}</div>
                                                </div>
                                            </v-col>

                                            <Input
                                                type="InputDate"
                                                :cols="3"
                                                :label="$t('app.page.inquiries.detail.date-time.form.date.label') + ' ' + $t('app.page.inquiries.detail.date-time.summary.cremation.title')"
                                                v-model="data.inquiry.cremationDate"
                                                path="data.inquiry.cremationDate"
                                                important
                                            >
                                            </Input>

                                            <Input
                                                type="InputTime"
                                                :cols="3"
                                                :label="$t('app.page.inquiries.detail.date-time.form.time.label') + ' ' + $t('app.page.inquiries.detail.date-time.summary.cremation.title')"
                                                v-model="data.inquiry.cremationTime"
                                                path="data.inquiry.cremationTime"
                                                important
                                            >
                                            </Input>
                                        </v-row>

                                        <v-row class="step"
                                               v-if="getInhumationPlace"
                                        >
                                            <v-col :md="6" class="col">
                                                <div class="wrapper">
                                                    <div class="name h3">
                                                        <v-icon icon="icon-calendar"></v-icon>
                                                        {{ getInhumationPlace.label }}
                                                    </div>
                                                    <div class="place">{{ getInhumationPlace.name }}</div>
                                                    <div class="address">{{ getInhumationPlace.address }}</div>
                                                </div>
                                            </v-col>

                                            <Input
                                                type="InputDate"
                                                :cols="3"
                                                :label="$t('app.page.inquiries.detail.date-time.form.date.label') + ' ' + $t('app.page.inquiries.detail.date-time.summary.inhumation.title')"
                                                v-model="data.inquiry.inhumationDate"
                                                path="data.inquiry.inhumationDate"
                                                important
                                            >
                                            </Input>

                                            <Input
                                                type="InputTime"
                                                :cols="3"
                                                :label="$t('app.page.inquiries.detail.date-time.form.time.label') + ' ' + $t('app.page.inquiries.detail.date-time.summary.inhumation.title')"
                                                v-model="data.inquiry.inhumationTime"
                                                path="data.inquiry.inhumationTime"
                                                important
                                            >
                                            </Input>
                                        </v-row>

                                    </div>

                                </v-window-item>

                                <v-window-item
                                    value="death-notice"
                                    eager
                                    :transition="false"
                                    :reverse-transition="false"
                                >
                                    <div class="heading">
                                        <Highlight class="title h2" :text="$t('app.page.inquiries.detail.death-notice.title')"></Highlight>

                                        <div class="tools">
                                            <v-btn
                                                v-if="validator"
                                                class="button button-small button-neutral"
                                                icon="icon-download"
                                                :disabled="validator.$dirty"
                                                @click="handleClickDownloadDeathNotice"
                                            >
                                            </v-btn>
                                            <v-btn
                                                class="button button-small button-secondary"
                                                prepend-icon="icon-eye-opened"
                                                @click="handleClickOpenDeathNoticePreview"
                                            >
                                                {{ $t('app.page.inquiries.detail.death-notice.button.preview') }}
                                            </v-btn>
                                            <v-btn
                                                v-if="authStore?.undertaker?.announcementUrl"
                                                class="button button-small"
                                                prepend-icon="icon-link-external"
                                                target="_blank"
                                                :href="authStore?.undertaker?.announcementUrl"
                                            >
                                                {{ $t('app.page.inquiries.detail.death-notice.button.website') }}
                                            </v-btn>
                                        </div>
                                    </div>

                                    <Notice>{{ $t('app.page.inquiries.detail.death-notice.notice') }}</Notice>

                                    <v-row no-gutters>

                                        <v-col :md="6">

                                            <v-col :md="12">
                                                <div class="subtitle h3">{{ $t('app.page.inquiries.detail.death-notice.form.deathNotice.title') }}</div>
                                            </v-col>

                                            <Input
                                                type="InputAutocomplete"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeCities.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeCities.placeholder')"
                                                v-model="data.inquiry.deathNoticeCities"
                                                v-model:modelRaw="searchDeathNoticeCitiesRaw"
                                                path="data.inquiry.deathNoticeCities"
                                                @search="autocompleteCity"
                                                itemValue="@id"
                                                itemTitle="text"
                                                multiple
                                                @update:modelValue="handleDeathNoticeCitiesUpdate"
                                                @change="handleDeathNoticeCitiesChange"
                                            >
                                            </Input>

                                            <div class="cities">
                                                <span
                                                    class="city"
                                                    v-for="(selection, i) in searchDeathNoticeCitiesRaw"
                                                    :key="selection['@id']"
                                                >
                                                    <v-chip
                                                        closable
                                                        close-icon="$close"
                                                        size="small"
                                                        @click:close="handleRemoveDeathNoticeCity(i)"
                                                    >
                                                        {{ selection['text'] }}
                                                    </v-chip>
                                                </span>
                                            </div>

                                            <Input
                                                type="InputTextarea"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeQuotation.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeQuotation.placeholder')"
                                                v-model="data.inquiry.deathNoticeQuotation"
                                                :button="$t('app.page.inquiries.detail.death-notice.button.open_catchphrases')"
                                                @click:button="handleClickOpenCatchpraseCategory('quotation')"
                                                ref="quotation"
                                            >
                                            </Input>

                                            <Input
                                                type="InputTextarea"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeFamily.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeFamily.placeholder')"
                                                v-model="data.inquiry.deathNoticeFamily"
                                                path="data.inquiry.deathNoticeFamily"
                                                :button="$t('app.page.inquiries.detail.death-notice.button.open_catchphrases')"
                                                @click:button="handleClickOpenCatchpraseCategory('family')"
                                                ref="family"
                                            >
                                            </Input>

                                            <Input
                                                type="InputTextarea"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeAnnouncement.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeAnnouncement.placeholder')"
                                                v-model="data.inquiry.deathNoticeAnnouncement"
                                                path="data.inquiry.deathNoticeAnnouncement"
                                                :button="$t('app.page.inquiries.detail.death-notice.button.open_catchphrases')"
                                                @click:button="handleClickOpenCatchpraseCategory('announcement')"
                                                ref="announcement"
                                            >
                                            </Input>

                                            <Input
                                                type="InputText"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeDeceased.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeDeceased.placeholder')"
                                                v-model="data.inquiry.deathNoticeDeceased"
                                                path="data.inquiry.deathNoticeDeceased"
                                            >
                                            </Input>

                                            <Input
                                                type="InputTextarea"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeCircumstances.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeCircumstances.placeholder')"
                                                v-model="data.inquiry.deathNoticeCircumstances"
                                                path="data.inquiry.deathNoticeCircumstances"
                                                :button="$t('app.page.inquiries.detail.death-notice.button.open_catchphrases')"
                                                @click:button="handleClickOpenCatchpraseCategory('circumstances')"
                                                ref="circumstances"
                                            >
                                            </Input>

                                            <Input
                                                type="InputTextarea"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeCeremony.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeCeremony.placeholder')"
                                                v-model="data.inquiry.deathNoticeCeremony"
                                                :button="$t('app.page.inquiries.detail.death-notice.button.open_catchphrases')"
                                                @click:button="handleClickOpenCatchpraseCategory('ceremony')"
                                                ref="ceremony"
                                            >
                                            </Input>

                                            <Input
                                                type="InputTextarea"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeRemembrance.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeRemembrance.placeholder')"
                                                v-model="data.inquiry.deathNoticeRemembrance"
                                                :button="$t('app.page.inquiries.detail.death-notice.button.open_catchphrases')"
                                                @click:button="handleClickOpenCatchpraseCategory('remembrance')"
                                                ref="remembrance"
                                            >
                                            </Input>

                                            <Input
                                                type="InputTextarea"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeThanks.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeThanks.placeholder')"
                                                v-model="data.inquiry.deathNoticeThanks"
                                                :button="$t('app.page.inquiries.detail.death-notice.button.open_catchphrases')"
                                                @click:button="handleClickOpenCatchpraseCategory('thanks')"
                                                ref="thanks"
                                            >
                                            </Input>

                                            <Input
                                                type="InputTextarea"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeJoined.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeJoined.placeholder')"
                                                v-model="data.inquiry.deathNoticeJoined"
                                                :button="$t('app.page.inquiries.detail.death-notice.button.open_catchphrases')"
                                                @click:button="handleClickOpenCatchpraseCategory('joined')"
                                                ref="joined"
                                            >
                                            </Input>

                                        </v-col>
                                        <v-col :md="6">

                                            <v-col :md="12">
                                                <div class="subtitle h3">{{ $t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper1.title') }}</div>
                                            </v-col>

                                            <Input
                                                type="InputSelect"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper_departement.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper_departement.placeholder')"
                                                v-model="deathNoticeNewspaper1.department"
                                                :items="globalStore.config.departments"
                                                itemValue="@id"
                                                itemTitle="text"
                                                :returnObject="true"
                                                @change="handleDeathNoticeNewspaper1Change"
                                            >
                                            </Input>

                                            <Input
                                                v-if="deathNoticeNewspaper1.department"
                                                type="InputSelect"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper_newspaper.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper_newspaper.placeholder')"
                                                :key="deathNoticeNewspaper1.clearer"
                                                v-model="data.inquiry.deathNoticeNewspaper1"
                                                :items="filterNewspapersByDepartment(deathNoticeNewspaper1.department)"
                                                itemValue="@id"
                                                itemTitle="text"
                                                ref="deathNoticeNewspaper1"
                                            >
                                            </Input>

                                            <div class="spacer"></div>

                                            <v-col :md="12">
                                                <div class="subtitle h3">{{ $t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper2.title') }}</div>
                                            </v-col>

                                            <Input
                                                type="InputSelect"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper_departement.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper_departement.placeholder')"
                                                v-model="deathNoticeNewspaper2.department"
                                                :items="globalStore.config.departments"
                                                itemValue="@id"
                                                itemTitle="text"
                                                :returnObject="true"
                                                @change="handleDeathNoticeNewspaper2Change"
                                            >
                                            </Input>

                                            <Input
                                                v-if="deathNoticeNewspaper2.department"
                                                type="InputSelect"
                                                :label="$t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper_newspaper.label')"
                                                :placeholder="$t('app.page.inquiries.detail.death-notice.form.deathNoticeNewspaper_newspaper.placeholder')"
                                                :key="deathNoticeNewspaper2.clearer"
                                                v-model="data.inquiry.deathNoticeNewspaper2"
                                                :items="filterNewspapersByDepartment(deathNoticeNewspaper2.department)"
                                                itemValue="@id"
                                                itemTitle="text"
                                            >
                                            </Input>

                                        </v-col>
                                    </v-row>
                                </v-window-item>
                            </v-window>

                        </Form>

                    </div>

                </div>
            </transition>

            <teleport to="#contextual-drawer">
                <transition name="fade">
                    <div v-if="globalStore.isDrawerOpened">

                        <template v-if="globalStore.drawerMode === 'death-notice-catchphrase'">

                            <div class="title h2">{{ $trans(currentCatchphraseCategory, 'name') }}</div>

                            <div class="intro">{{ $t('app.page.inquiries.detail.death-notice.drawer.catchphrase.intro') }}</div>

                            <div class="catchphrases">
                                <v-card class="catchphrase"
                                        v-for="catchphrase in currentCatchphraseCategory.catchphrases"
                                        @click="handleClickAddCatchphrase(currentCatchphraseModel, $trans(catchphrase, 'content'))"
                                >
                                    {{ $trans(catchphrase, 'content') }}
                                </v-card>

                            </div>

                        </template>

                        <template v-if="globalStore.drawerMode === 'death-notice-preview'">

                            <div class="title h2">{{ $t('app.page.inquiries.detail.death-notice.drawer.preview.title') }}</div>

                            <div
                                class="death-notice-preview"
                                v-html="getDeathNoticeHtml">
                            </div>

                        </template>

                        <template v-if="globalStore.drawerMode === 'funeral-summary'">

                            <FormFuneralSummary
                                :inquiry="data.inquiry"
                                :placementPlace="getPlacementPlace"
                                :ceremonyPlace="getCeremonyPlace"
                                :cremationPlace="getCremationPlace"
                                :inhumationPlace="getInhumationPlace"
                                @cancel="handleCancelFormFuneralSummary"
                                @submit="handleSubmitFormFuneralSummary"
                            />

                        </template>

                    </div>
                </transition>
            </teleport>

            <div class="temp">
                <pre><small>{{ data }}</small></pre><br>
            </div>
        </PageContent>
    </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store'
import { useAuthStore } from '@/stores/auth.store'
import { useMessageStore } from '@/stores/message.store'

import PageHeader from "@/components/commons/PageHeader.vue"
import PageContent from "@/components/commons/PageContent.vue"
import Overlay from "@/components/commons/Overlay.vue"
import Highlight from "@/components/commons/Highlight.vue"
import Notice from "@/components/commons/Notice.vue"

import Form from "@/components/commons/Form.vue"
import Input from "@/components/commons/Input.vue"
import FormFuneralSummary from "@/components/forms/FormFuneralSummary.vue"

import { supportedStatuses } from '@/middleware/inquiries'
import InquiriesService from '@/middleware/inquiries'
import EstablishmentsService from '@/middleware/establishments'
import UndertakersService from '@/middleware/undertakers'
import ProductCategoriesServices from '@/middleware/productCategories'

import { autocompleteCity } from '@/helpers.autocomplete'

import { required, email } from '@/helpers.validators'
import { requiredIf } from '@vuelidate/validators'

export default {
    components: { PageHeader, PageContent, Overlay, Highlight, Notice, Form, Input, FormFuneralSummary },

    setup() {
        const globalStore = useGlobalStore()
        const authStore = useAuthStore()
        const messageStore = useMessageStore()

        return {
            globalStore,
            authStore,
            messageStore,
        }
    },

    props: {
        id: {
            required: true,
            type: Number,
        },
    },

    data() {

        return {

            data: {
                inquiry: {
                    authorityIdentity: {
                        lastName: null,
                        firstName: null,
                        address1: null,
                        zip: null,
                        city: null,
                        relationship: null,
                        email: null,
                        phone: null,
                        civility: null,
                    },
                    deceasedIdentity: {
                        birthName: null,
                        lastName: null,
                        firstName: null,
                        profession: null,
                        occupation: null,
                        address1: null,
                        zip: null,
                        city: null,
                        dateOfBirth: null,
                        placeOfBirth: null,
                        email: null,
                        phone: null,
                        civility: null,
                    },
                    deathDate: null,
                    deathTime: null,
                    deathPlaceKind: null,
                    deathPlace: {
                        establishment: null,
                    },
                    funeralHomeCurrent: null,
                    funeralHomeCurrentPlace: {
                        establishment: null,
                        name: null,
                        address1: null,
                        address2: null,
                        city: null,
                    },
                    funeralHomeFinal: null,
                    funeralHomeFinalPlace: {
                        establishment: null,
                    },
                    treatment: null,
                    ceremonyKind: null,
                    ceremonyPlaceKind: null,
                    religion: null,
                    ceremonyPlace: {
                        establishment: null,
                        name: null,
                        address1: null,
                        address2: null,
                        city: null,
                    },
                    funeralKind: null,
                    crematoriumPlace: {
                        establishment: null,
                    },
                    followingCremation: null,
                    dispersalPlaceKind: null,
                    cemetaryPlace: {
                        city: null,
                    },
                    lastTribute: null,
                    plot: null,
                    tomb: null,
                    funeraryMonument: null,
                    masonry: null,
                    products: [],
                    placementDate: null,
                    placementTime: null,
                    ceremonyDate: null,
                    ceremonyTime: null,
                    cremationDate: null,
                    cremationTime: null,
                    inhumationDate: null,
                    inhumationTime: null,
                    deathNoticeCities: [],
                    deathNoticeQuotation: null,
                    deathNoticeFamily: null,
                    deathNoticeAnnouncement: null,
                    deathNoticeDeceased: null,
                    deathNoticeCircumstances: null,
                    deathNoticeCeremony: null,
                    deathNoticeRemembrance: null,
                    deathNoticeThanks: null,
                    deathNoticeJoined: null,
                    deathNoticeNewspaper1: null,
                    deathNoticeNewspaper2: null,
                },
            },
            validations: {
                data: {
                    inquiry: {
                        authorityIdentity: {
                            civility: { required },
                            lastName: { required },
                            firstName: { required },
                            relationship: {
                                required: requiredIf(this.data?.inquiry?.inquiryKind?.code === 'death'),
                            },
                            // address1: { required },
                            // address2: { required },
                            // zip: { required },
                            // city: { required },
                            email: { required, email },
                            phone: { required },
                        },
                        deceasedIdentity: {
                            civility: { required },
                            // birthName: { required },
                            lastName: { required },
                            firstName: { required },
                            // profession: { required },
                            // occupation: { required },
                            // address1: { required },
                            // address2: { required },
                            // zip: { required },
                            // city: { required },
                            dateOfBirth: { required },
                            // placeOfBirth: { required },
                        },
                        deathDate: {
                            // required: requiredIf(this.data?.inquiry?.inquiryKind?.code === 'death'),
                        },
                        deathTime: {
                            // required: requiredIf(this.data?.inquiry?.inquiryKind?.code === 'death'),
                        },
                        // deathPlaceKind: { required }, // TODO! to make this required, we have to manage the choice 'other' with a specific value that is not null
                        // deathPlace: {
                        //     establishment: { required },
                        //     name: { required },
                        //     address1: { required },
                        //     address2: { required },
                        //     city: { required },
                        // },
                        // funeralHomeCurrent: { required },
                        // funeralHomeCurrentPlace: {
                        //     establishment: { required },
                        //     name: { required },
                        //     address1: { required },
                        //     address2: { required },
                        //     city: { required },
                        // },
                        // funeralHomeFinal: { required },
                        // funeralHomeFinalPlace: {
                        //     establishment: { required },
                        // },
                        // treatment: { required },
                        // ceremonyKind: { required },
                        // ceremonyPlaceKind: { required },
                        // religion: { required },
                        // ceremonyPlace: {
                        //     establishment: { required },
                        //     name: { required },
                        //     address1: { required },
                        //     address2: { required },
                        //     city: { required },
                        // },
                        // funeralKind: { required },
                        // crematoriumPlace: {
                        //     establishment: { required },
                        // },
                        // followingCremation: { required },
                        // dispersalPlaceKind: { required },
                        // cemetaryPlace: {
                        //     city: { required },
                        // },
                        // lastTribute: { required },
                        // plot: { required },
                        // tomb: { required },
                        // funeraryMonument: { required },
                        // masonry: { required },
                        // products: { required },
                        // funeralDate1: { required },
                        // funeralSlot1: { required },
                        // funeralDate2: { required },
                        // funeralSlot2: { required },
                        // funeralDate3: { required },
                        // funeralSlot3: { required },
                        // placementDate: { required },
                        // placementTime: { required },
                        // ceremonyDate: { required },
                        // ceremonyTime: { required },
                        // cremationDate: { required },
                        // cremationTime: { required },
                        // inhumationDate: { required },
                        // inhumationTime: { required },
                        // deathNoticeCities: { required },
                        // deathNoticeQuotation: { required },
                        // deathNoticeFamily: { required },
                        // deathNoticeAnnouncement: { required },
                        // deathNoticeDeceased: { required },
                        // deathNoticeCircumstances: { required },
                        // deathNoticeCeremony: { required },
                        // deathNoticeRemembrance: { required },
                        // deathNoticeThanks: { required },
                        // deathNoticeJoined: { required },
                        // deathNoticeNewspaper1: { required },
                        // deathNoticeNewspaper2: { required },

                    },
                },
            },
            validator: null,

            isWaiting: true,
            isInquiryLoaded: false,
            inquiryStatuses: null,
            relationships: null,
            currentTab: null,

            searchDeathPlaceCity: null,
            searchDeathPlaceCityRaw: null,
            searchDeathPlaceCityClearer: 0,
            searchDeathPlaceEstablishment: null,
            cityFilteredEstablishments: [],
            isLoadingDeathPlaceEstablishments: false,

            searchFuneralHomeCurrentCity: null,
            searchFuneralHomeCurrentCityRaw: null,
            searchFuneralHomeCurrentCityClearer: 0,
            searchFuneralHomeCurrent: null,
            cityFilteredFuneralHomes: [],
            isLoadingFuneralHomeCurrentEstablishments: false,

            searchFuneralHomeFinal: null,
            undertakerFuneralHomes: [],
            isUndertakerFuneralHomesLoaded: false,
            undertakerCrematoriums: [],
            isUndertakerCrematoriumsLoaded: false,

            searchCeremonyPlaceEstablishment: null,

            searchCeremonyPlaceCity: null,
            searchCeremonyPlaceCityRaw: null,
            searchCeremonyPlaceCityClearer: 0,
            cityFilteredCeremonyPlaces: [],
            isLoadingCeremonyPlaces: false,

            funeralKindRaw: null,
            searchCrematoriumPlaceEstablishment: null,
            searchCemetaryPlaceCityRaw: null,
            searchCemetaryPlaceCityClearer: 0,

            localProducts: {}, // Will contain the list of selected products, grouped by categories
            productCategories: null,
            isProductCategoriesLoaded: false,
            undertakerProducts: null,
            isUndertakerProductsLoaded: false,

            funeralSlots: [
                {
                    value: 'morning',
                    name: this.$t('app.page.inquiries.detail.date-time.funeralSlot.morning'),
                },
                {
                    value: 'afternoon',
                    name: this.$t('app.page.inquiries.detail.date-time.funeralSlot.afternoon'),
                },
            ],

            searchDeathNoticeCitiesRaw: null,
            currentCatchphraseCategory: null,
            currentCatchphraseModel: null,
            deathNoticeNewspaper1:{
                department: null,
                clearer: 0,
            },
            deathNoticeNewspaper2:{
                department: null,
                clearer: 0,
            },
        }

    },

    mounted() {

        // Load the initial data
        this.loadData()

        // Retrieve the inquiries statuses (but remove the unsupported ones)
        this.inquiryStatuses = this.globalStore.sf.inquiryStatuses.filter(status => supportedStatuses.includes(status))

    },

    computed: {

        // Helper to know that everything is loaded
        isLoaded() {
            return this.isInquiryLoaded
        },

        isPriceNeedRefresh() {
            // TODO! only check for fields that have an impact on the price
            return this.validator && this.validator.$dirty
        },

        isDateTimeEmpty() {
            return (this.getPlacementPlace && (this.$isNullOrEmpty(this.data.inquiry.placementDate) || this.$isNullOrEmpty(this.data.inquiry.placementTime)))
                || (this.getCeremonyPlace && (this.$isNullOrEmpty(this.data.inquiry.ceremonyDate) || this.$isNullOrEmpty(this.data.inquiry.ceremonyTime)))
                || (this.getInhumationPlace && (this.$isNullOrEmpty(this.data.inquiry.inhumationDate) || this.$isNullOrEmpty(this.data.inquiry.inhumationTime)))
                || (this.getCremationPlace && (this.$isNullOrEmpty(this.data.inquiry.cremationDate) || this.$isNullOrEmpty(this.data.inquiry.cremationTime)))
        },

        deceasedCivility() {
            return this.globalStore.config.civilities.find(item => item['@id'] === this.data.inquiry?.deceasedIdentity?.civility)
        },

        // Helper to retrieve the coffin placement place information based on previous answers. This methods returns the establishment or the place if no establishment is set.
        getPlacementPlace() {
            const label = this.$t('app.page.inquiries.detail.date-time.summary.placement.title')

            if (this.data.inquiry.funeralHomeFinal === 'funeral-home-select'){
                if(this.data.inquiry.funeralHomeFinalPlace.establishment && this.searchFuneralHomeFinal){
                    return {
                        label: label,
                        name: this.searchFuneralHomeFinal.name,
                        address: this.searchFuneralHomeFinal.fullAddress
                    }
                }
            }
            else if (this.data.inquiry.funeralHomeCurrent === 'yes'){
                if(this.data.inquiry.funeralHomeCurrentPlace.establishment && this.searchFuneralHomeCurrent){
                    return {
                        label: label,
                        name: this.searchFuneralHomeCurrent.name,
                        address: this.searchFuneralHomeCurrent.fullAddress
                    }
                }
                else{
                    return {
                        label: label,
                        name: this.data.inquiry.funeralHomeCurrentPlace.name,
                        address: this.data.inquiry.funeralHomeCurrentPlace.address1 + ', ' + this.data.inquiry.funeralHomeCurrentPlace.city?.zip + ' ' + this.data.inquiry.funeralHomeCurrentPlace.city?.name
                    }
                }
            }
            else {
                if(this.data.inquiry.deathPlace.establishment && this.searchDeathPlaceEstablishment){
                    return {
                        label: label,
                        name: this.searchDeathPlaceEstablishment.name,
                        address: this.searchDeathPlaceEstablishment.fullAddress
                    }
                }
                else if(this.data.inquiry.deathPlace.name && this.data.inquiry.deathPlace.address1 && this.data.inquiry.deathPlace.city){
                    return {
                        label: label,
                        name: this.data.inquiry.deathPlace.name,
                        address: this.data.inquiry.deathPlace.address1 + ', ' + this.data.inquiry.deathPlace.city?.zip + ' ' + this.data.inquiry.deathPlace.city?.name
                    }
                }
            }

            this.data.inquiry.placementDate = null
            this.data.inquiry.placementTime = null
            return false
        },

        getCeremonyPlace() {
            const label = this.$t('app.page.inquiries.detail.date-time.summary.ceremony.title')

            if(['civil', 'religious'].includes(this.data.inquiry.ceremonyKind)) {
                if(this.data.inquiry.ceremonyPlace.establishment && this.searchCeremonyPlaceEstablishment){
                    return {
                        label: label,
                        name: this.searchCeremonyPlaceEstablishment.name,
                        address: this.searchCeremonyPlaceEstablishment.fullAddress
                    }
                }
                else if(this.data.inquiry.ceremonyPlace.name && this.data.inquiry.ceremonyPlace.address1 && this.data.inquiry.ceremonyPlace.city){
                    return {
                        label: label,
                        name: this.data.inquiry.ceremonyPlace.name,
                        address: this.data.inquiry.ceremonyPlace.address1 + ', ' + this.data.inquiry.ceremonyPlace.city?.zip + ' ' + this.data.inquiry.ceremonyPlace.city?.name
                    }
                }
            }

            this.data.inquiry.ceremonyDate = null
            this.data.inquiry.ceremonyTime = null
            return false
        },

        getCremationPlace() {
            const label = this.$t('app.page.inquiries.detail.date-time.summary.cremation.title')

            if(this.funeralKindRaw && this.funeralKindRaw.code === 'cremation') {
                if(this.data.inquiry.crematoriumPlace.establishment && this.searchCrematoriumPlaceEstablishment){
                    return {
                        label: label,
                        name: this.searchCrematoriumPlaceEstablishment.name,
                        address: this.searchCrematoriumPlaceEstablishment.fullAddress
                    }
                }
                else if(this.data.inquiry.crematoriumPlace.name && this.data.inquiry.crematoriumPlace.address1 && this.data.inquiry.crematoriumPlace.city){
                    return {
                        label: label,
                        name: this.data.inquiry.crematoriumPlace.name,
                        address: this.data.inquiry.crematoriumPlace.address1 + ', ' + this.data.inquiry.crematoriumPlace.city?.zip + ' ' + this.data.inquiry.crematoriumPlace.city?.name
                    }
                }
            }

            this.data.inquiry.cremationDate = null
            this.data.inquiry.cremationTime = null
            return false
        },

        getInhumationPlace() {
            if(this.funeralKindRaw && this.funeralKindRaw.code === 'inhumation') {
                if(this.data.inquiry.cemetaryPlace && this.data.inquiry.cemetaryPlace.city && this.searchCemetaryPlaceCityRaw){
                    return {
                        label: this.$t('app.page.inquiries.detail.date-time.summary.inhumation.title'),
                        name: '',
                        address: this.searchCemetaryPlaceCityRaw?.zip + ' ' + this.searchCemetaryPlaceCityRaw?.name
                    }
                }
                else{
                    return false
                }
            }
            else if(this.funeralKindRaw && this.funeralKindRaw.code === 'cremation') {

                if(this.data.inquiry.cemetaryPlace && this.data.inquiry.cemetaryPlace.city && this.searchCemetaryPlaceCityRaw) {

                    if(this.data.inquiry.followingCremation === 'dispersal' && this.data.inquiry.dispersalPlaceKind === 'garden'){
                        return {
                            label: this.$t('app.page.inquiries.detail.date-time.summary.dispersal.title'),
                            name: '',
                            address: this.searchCemetaryPlaceCityRaw?.zip + ' ' + this.searchCemetaryPlaceCityRaw?.name
                        }
                    }
                    else if(this.data.inquiry.followingCremation === 'burial'){
                        return {
                            label: this.$t('app.page.inquiries.detail.date-time.summary.burial.title'),
                            name: '',
                            address: this.searchCemetaryPlaceCityRaw?.zip + ' ' + this.searchCemetaryPlaceCityRaw?.name
                        }
                    }
                    else if(this.data.inquiry.followingCremation === 'columbarium'){
                        return {
                            label: this.$t('app.page.inquiries.detail.date-time.summary.columbarium.title'),
                            name: '',
                            address: this.searchCemetaryPlaceCityRaw?.zip + ' ' + this.searchCemetaryPlaceCityRaw?.name
                        }
                    }
                    else if(this.data.inquiry.followingCremation === 'sealing'){
                        return {
                            label: this.$t('app.page.inquiries.detail.date-time.summary.sealing.title'),
                            name: '',
                            address: this.searchCemetaryPlaceCityRaw?.zip + ' ' + this.searchCemetaryPlaceCityRaw?.name
                        }
                    }
                }
            }

            this.data.inquiry.inhumationDate = null
            this.data.inquiry.inhumationTime = null
            return false
        },

        getDeathNoticeHtml() {
            let content = ''
            const separator = ' <br>'
            const weakseparator = ' <br>'

            if(this.searchDeathNoticeCitiesRaw.length){
                content += '<b>' + this.searchDeathNoticeCitiesRaw.map(city => city.name).join(', ') + '</b>'
                content += separator + separator
            }
            if(this.data.inquiry.deathNoticeQuotation){
                content += this.data.inquiry.deathNoticeQuotation
                content += weakseparator
            }
            if(this.data.inquiry.deathNoticeFamily){
                content += this.data.inquiry.deathNoticeFamily
                content += weakseparator
            }
            if(this.data.inquiry.deathNoticeAnnouncement){
                content += this.data.inquiry.deathNoticeAnnouncement
                content += weakseparator
            }
            if(this.data.inquiry.deathNoticeDeceased){
                content += separator
                content += '<b>' + this.data.inquiry.deathNoticeDeceased + '</b>'
                content += separator + separator
            }
            if(this.data.inquiry.deathNoticeCircumstances){
                content += this.data.inquiry.deathNoticeCircumstances
                content += weakseparator
            }
            if(this.data.inquiry.deathNoticeCeremony){
                content += this.data.inquiry.deathNoticeCeremony
                content += weakseparator
            }
            if(this.data.inquiry.deathNoticeRemembrance){
                content += this.data.inquiry.deathNoticeRemembrance
                content += weakseparator
            }
            if(this.data.inquiry.deathNoticeThanks){
                content += this.data.inquiry.deathNoticeThanks
                content += weakseparator
            }
            if(this.data.inquiry.deathNoticeJoined){
                content += this.data.inquiry.deathNoticeJoined
                content += weakseparator
            }

            return content
        },
    },

    methods: {

        /* region [imported method binding] */

        autocompleteCity: autocompleteCity,

        /* endregion */

        /* region [data manipulation] */

        loadData() {

            // Retrieve the inquiry
            this.refreshData()

        },

        refreshData() {
            this.isWaiting = true

            // Retrieve the inquiry
            InquiriesService.get(this.id)
            .then((response) => {
                this.data.inquiry = this.$extract(response)

                // Prefill raw models data
                this.searchDeathPlaceEstablishment = this.data.inquiry.deathPlace?.establishment
                this.searchFuneralHomeCurrent = this.data.inquiry.funeralHomeCurrentPlace?.establishment
                this.searchCeremonyPlaceEstablishment = this.data.inquiry.ceremonyPlace?.establishment
                this.searchCrematoriumPlaceEstablishment = this.data.inquiry.crematoriumPlace?.establishment

                // Load the city from the establishment or from the place
                this.searchDeathPlaceCity = this.data.inquiry.deathPlace?.establishment?.city || this.data.inquiry.deathPlace?.city
                this.searchFuneralHomeCurrentCity = this.data.inquiry.funeralHomeCurrentPlace?.establishment?.city || this.data.inquiry.funeralHomeCurrentPlace?.city
                this.searchCeremonyPlaceCity = this.data.inquiry.ceremonyPlace?.establishment?.city || this.data.inquiry.ceremonyPlace?.city

                // If we have null data, initialize with empty data (should be useless if everything is set in the configurator)
                if (!this.data.inquiry.deathPlace) {
                    this.data.inquiry.deathPlace = {
                        establishment: null,
                    }
                }
                if (!this.data.inquiry.funeralHomeCurrentPlace) {
                    this.data.inquiry.funeralHomeCurrentPlace = {
                        establishment: null,
                    }
                }
                if (!this.data.inquiry.funeralHomeFinalPlace) {
                    this.data.inquiry.funeralHomeFinalPlace = {
                        establishment: null,
                    }
                }
                if (!this.data.inquiry.ceremonyPlace) {
                    this.data.inquiry.ceremonyPlace = {
                        establishment: null,
                    }
                }
                if (!this.data.inquiry.crematoriumPlace) {
                    this.data.inquiry.crematoriumPlace = {
                        establishment: null,
                    }
                }
                if (!this.data.inquiry.cemetaryPlace) {
                    this.data.inquiry.cemetaryPlace = {
                        establishment: null,
                    }
                }

                // If no establishment is set, force the value to automatically select 'other'
                if(!this.data.inquiry.deathPlace.establishment){
                    this.data.inquiry.deathPlace.establishment = null
                }
                if(!this.data.inquiry.funeralHomeCurrentPlace.establishment){
                    this.data.inquiry.funeralHomeCurrentPlace.establishment = null
                }
                if(!this.data.inquiry.ceremonyPlace.establishment){
                    this.data.inquiry.ceremonyPlace.establishment = null
                }
                // if(!this.data.inquiry.crematoriumPlace.establishment){
                //     this.data.inquiry.crematoriumPlace.establishment = null
                // }



                // this.data.inquiry.deathPlace.establishment = this.data.inquiry.deathPlace.establishment['@id']

                // Loop on each product and insert it in a subcategory of the local list
                for (const product of this.data.inquiry.products) {
                    // Create the category array if it doesn't exist
                    if (!this.localProducts.hasOwnProperty(product.productCategory.code)) {
                        this.localProducts[product.productCategory.code] = []
                    }
                    // this.localProducts[product.productCategory.code].push(product['@id']) // If multiple items by category
                    this.localProducts[product.productCategory.code] = product['@id']
                }
                // Trigger a product change to convert all products of the model to IRIs
                this.handleProductChange()


                // Prefill the death notice cities raw model with te retrieved data (to display the initial chips list)
                this.searchDeathNoticeCitiesRaw = this.data.inquiry.deathNoticeCities


                // Prefill the deathNoticeDeceased name if it's empty
                if(!this.data.inquiry.deathNoticeDeceased && this.data.inquiry?.deceasedIdentity){
                    const civility = this.$trans(this.data.inquiry.deceasedIdentity.civility, 'label')
                    const firstname = this.data.inquiry.deceasedIdentity.firstName
                    const lastname = (this.data.inquiry.deceasedIdentity.lastName).toUpperCase()

                    this.data.inquiry.deathNoticeDeceased = civility + ' ' + firstname + ' ' + lastname
                }

                // Prefill the newspaper departments if the newspapers are available
                if(this.data.inquiry.deathNoticeNewspaper1){
                    this.deathNoticeNewspaper1.department = this.data.inquiry.deathNoticeNewspaper1.department
                }
                if(this.data.inquiry.deathNoticeNewspaper2){
                    this.deathNoticeNewspaper2.department = this.data.inquiry.deathNoticeNewspaper2.department
                }

                // console.log(this.data.inquiry)

                this.isInquiryLoaded = true
            })
            .finally(() => {
                this.isWaiting = false
            })

            // TODO! use Promise.all
            // Retrieve the undertaker funeral homes (no restriction on cities)
            EstablishmentsService.getFilteredCustom('funeral-home', null, this.authStore.id)
            .then((response) => {
                this.undertakerFuneralHomes = this.$extract(response)

                this.isUndertakerFuneralHomesLoaded = true
            })
            .finally(() => {
                this.isWaiting = false
            })

            // Retrieve the undertaker crematoriums (no restriction on cities)
            EstablishmentsService.getFilteredCustom('crematorium', null, this.authStore.id)
            .then((response) => {
                this.undertakerCrematoriums = this.$extract(response)

                this.isUndertakerCrematoriumsLoaded = true
            })
            .finally(() => {
                this.isWaiting = false
            })

            // Retrieve the product categories
            ProductCategoriesServices.getAll()
            .then((response) => {
                this.productCategories = this.$extract(response)
                this.isProductCategoriesLoaded = true
            })

            // Retrieve the current undertaker products
            UndertakersService.getProducts(this.authStore.undertakerId)
            .then((response) => {
                this.undertakerProducts = this.$extract(response)
                this.undertakerProducts = this.undertakerProducts.products
                this.isUndertakerProductsLoaded = true
            })
            .finally(() => {
                this.isWaiting = false
            })

        },

        refreshPrice() {
            this.isWaiting = true

            InquiriesService.getPrices(this.id)
            .then((response) => {
                const data = this.$extract(response)
                if(data && data.estimateTotalPrice && data.estimateProductsPrice){
                    this.data.inquiry.estimateTotalPrice = data.estimateTotalPrice
                    this.data.inquiry.estimateProductsPrice = data.estimateProductsPrice
                }
            })
            .finally(() => {
                this.isWaiting = false
            })
        },

        /* endregion */

        /* region [event handlers] */

        handleInquiryStatusChange(value) {
            this.isWaiting = true

            InquiriesService.updateStatus(this.id, this.data.inquiry)
            .then((response) => {
            })
            .finally(() => {
                this.isWaiting = false
            })

        },

        handleFormMounted(validator){
            this.validator = validator
        },

        handleSave(){
            this.$refs.form.submit()
        },

        handleSubmit(){

            this.isWaiting = true

            InquiriesService.update(this.id, this.data.inquiry)
            .then((response) => {
                this.messageStore.addInfo(this.$t('app.global.messages.saved_with_success'))
                this.validator.$reset(); // Reset the form validator state

                // We refresh the prices
                this.refreshPrice()
            })
            .finally(() => {
                this.isWaiting = false
            });
        },

        handleDeathPlaceKindChange(event) {

            this.data.inquiry.funeralHomeCurrentPlace.placeKind = 'death-place'
            this.searchDeathPlaceCity = null

            // Empty some fields
            switch (event) {
                case 'health-establishment':
                    this.data.inquiry.deathPlace.custom = false
                    this.data.inquiry.deathPlace.name = null
                    this.data.inquiry.deathPlace.address1 = null
                    this.data.inquiry.deathPlace.address2 = null

                    // this.data.inquiry.deathPlace.city = null
                    // this.data.inquiry.deathPlace.establishment = null
                    break;
                default:
                    this.data.inquiry.deathPlace.custom = true
                    this.data.inquiry.deathPlace.establishment = null
                    this.data.inquiry.deathPlace.name = null
                    this.data.inquiry.deathPlace.address1 = null
                    this.data.inquiry.deathPlace.address2 = null
                    this.data.inquiry.deathPlace.city = null
                    break;
            }

        },

        handleDeathPlaceEstablishmentCityUpdate(event) {

            // If a city is set
            if(this.searchDeathPlaceCity){

                const cityId = this.$extractIdFromIri(this.searchDeathPlaceCity)
                this.isLoadingDeathPlaceEstablishments = true

                // Retrieve the establishments
                EstablishmentsService.getFilteredCustom('health-establishment', cityId, this.authStore.id)
                .then((response) => {
                    this.cityFilteredEstablishments = this.$extract(response)
                })
                .finally(() => {
                    this.isLoadingDeathPlaceEstablishments = false
                });
            }
        },

        handleDeathPlaceEstablishmentCityChange() {

            this.validator?.$touch()

            // Reset the current selected establishment because the list changed
            this.data.inquiry.deathPlace.establishment = null
            this.searchDeathPlaceEstablishment = null
            this.cityFilteredEstablishments = []

        },

        handleDeathPlaceEstablishmentChange(event) {

            // If we choose other
            if(event === null) {
                this.data.inquiry.deathPlace.custom = true
                this.data.inquiry.deathPlace.city = this.searchDeathPlaceCityRaw // Automatically retrieve the search city to fill the inquiryPlace city
            }
            else if(event !== undefined) {
                this.data.inquiry.deathPlace.custom = false
                this.data.inquiry.deathPlace.name = null
                this.data.inquiry.deathPlace.address1 = null
                this.data.inquiry.deathPlace.address2 = null
            }

        },

        handleFuneralHomeCurrentChange(event) {

            // If we have null data, initialize with empty data
            if (!this.data.inquiry.funeralHomeCurrentPlace) {
                this.data.inquiry.funeralHomeCurrentPlace = {
                    establishment: null,
                }
            }

            // Empty some fields
            switch (event) {
                case 'yes':
                    this.data.inquiry.funeralHomeCurrentPlace.placeKind = 'funeral-home-place'

                    // Reset the final place (because we cant choose the current and the final at the same time)
                    this.data.inquiry.funeralHomeFinalPlace.placeKind = null
                    this.data.inquiry.funeralHomeFinalPlace.name = null
                    this.data.inquiry.funeralHomeFinalPlace.address1 = null
                    this.data.inquiry.funeralHomeFinalPlace.address2 = null
                    this.data.inquiry.funeralHomeFinalPlace.city = null
                    this.data.inquiry.funeralHomeFinalPlace.establishment = null
                    break;
                default:
                    this.data.inquiry.funeralHomeCurrentPlace.placeKind = null
                    this.data.inquiry.funeralHomeCurrentPlace.establishment = null
                    this.data.inquiry.funeralHomeCurrentPlace.name = null
                    this.data.inquiry.funeralHomeCurrentPlace.address1 = null
                    this.data.inquiry.funeralHomeCurrentPlace.address2 = null
                    this.data.inquiry.funeralHomeCurrentPlace.city = null
                    this.searchFuneralHomeCurrentCity = null
                    this.searchFuneralHomeCurrent = null
                    this.searchFuneralHomeCurrentCityClearer++ // Increment the key to trigger the autocomplete clear
                    break;
            }

        },

        handleFuneralHomeCurrentCityUpdate(event) {

            // If a city is set
            if(this.searchFuneralHomeCurrentCity){

                const cityId = this.$extractIdFromIri(this.searchFuneralHomeCurrentCity)
                this.isLoadingFuneralHomeCurrentEstablishments = true

                EstablishmentsService.getFilteredCustom('funeral-home', cityId, this.authStore.id)
                .then((response) => {
                    this.cityFilteredFuneralHomes = this.$extract(response)
                })
                .finally(() => {
                    this.isLoadingFuneralHomeCurrentEstablishments = false
                });
            }
        },

        handleFuneralHomeCurrentCityChange() {

            this.validator?.$touch()

            // Reset the state because the list changed
            this.data.inquiry.funeralHomeCurrentPlace.establishment = null // Si on fait ça, au chargement ça vide la valeur car la ville change
            this.searchFuneralHomeCurrent = null

        },

        handleFuneralHomeCurrentEstablishmentChange(event) {

            // If we choose other
            if(event === null) {
                this.data.inquiry.funeralHomeCurrentPlace.custom = true
                this.data.inquiry.funeralHomeCurrentPlace.city = this.searchFuneralHomeCurrentCityRaw
            }
            else if(event !== undefined) {
                this.data.inquiry.funeralHomeCurrentPlace.custom = false
                this.data.inquiry.funeralHomeCurrentPlace.name = null
                this.data.inquiry.funeralHomeCurrentPlace.address1 = null
                this.data.inquiry.funeralHomeCurrentPlace.address2 = null
            }
        },

        handleFuneralHomeFinalChange(event) {

            // If we have null data, initialize with empty data
            if (!this.data.inquiry.funeralHomeFinalPlace) {
                this.data.inquiry.funeralHomeFinalPlace = {
                    establishment: null,
                }
            }

            // Empty some fields
            switch (event) {
                case 'funeral-home-select':
                    this.data.inquiry.funeralHomeFinalPlace.placeKind = 'funeral-home-place'
                    break;
                default:
                    this.data.inquiry.funeralHomeFinalPlace.placeKind = null
                    this.data.inquiry.funeralHomeFinalPlace.name = null
                    this.data.inquiry.funeralHomeFinalPlace.address1 = null
                    this.data.inquiry.funeralHomeFinalPlace.address2 = null
                    this.data.inquiry.funeralHomeFinalPlace.city = null
                    this.data.inquiry.funeralHomeFinalPlace.establishment = null
                    break;
            }
        },

        handleFuneralHomeFinalEstablishmentChange(event) {
            this.data.inquiry.funeralHomeFinalPlace.custom = false // It's never a custom establishment
        },

        handleCeremonyKindChange(event) {

            // If we have null data, initialize with empty data
            if (!this.data.inquiry.ceremonyPlace) {
                this.data.inquiry.ceremonyPlace = {
                    establishment: null,
                }
            }

            // Empty some fields
            switch (event) {
                case 'religious':
                    break;
                default:
                    this.data.inquiry.religion = null
                    break;
            }

        },

        handleCeremonyPlaceKindChange(event) {

            this.data.inquiry.ceremonyPlace.placeKind = 'ceremony-place'

            this.data.inquiry.ceremonyPlace.establishment = null
            this.searchCeremonyPlaceEstablishment = null
            this.searchCeremonyPlaceCity = null
            this.searchCeremonyPlaceCityClearer++; // Increment the key to trigger the autocomplete clear

            switch (event) {
                case 'worship-place':
                    break;
                case 'crematorium':
                case 'funeral-home':
                    this.data.inquiry.religion = null
                    break;
                case 'unknown':
                    this.data.inquiry.religion = null
                    break;
            }

            this.handleCeremonyPlaceEstablishmentAutocomplete()

        },

        handleReligionChange(event) {

            // Reset the state because the list changed
            this.data.inquiry.ceremonyPlace.establishment = null
            this.searchCeremonyPlaceEstablishment = null

            this.handleCeremonyPlaceEstablishmentAutocomplete()

        },

        handleCeremonyPlaceCityUpdate(event) {

            this.handleCeremonyPlaceEstablishmentAutocomplete()

        },

        handleCeremonyPlaceCityChange(event) {

            this.validator?.$touch()

            // Reset the state because the list changed
            this.data.inquiry.ceremonyPlace.establishment = null
            this.searchCeremonyPlaceEstablishment = null
        },

        handleCeremonyPlaceEstablishmentAutocomplete(){
            if(this.searchCeremonyPlaceCity && this.data.inquiry.ceremonyPlaceKind){

                // Retrieve the city
                const cityId = this.$extractIdFromIri(this.searchCeremonyPlaceCity)
                this.isLoadingCeremonyPlaces = true

                // Retrieve the establishment category
                const establishmentCategory = this.data.inquiry.ceremonyPlaceKind

                // If a religion is set, retrieve it
                let religionId = null
                if (this.data.inquiry.religion) {
                    religionId = this.$extractIdFromIri(this.data.inquiry.religion)
                }

                EstablishmentsService.getFilteredCustom(establishmentCategory, cityId, this.authStore.id, religionId)
                .then((response) => {
                    this.cityFilteredCeremonyPlaces = this.$extract(response)
                })
                .finally(() => {
                    this.isLoadingCeremonyPlaces = false
                });
            }
        },

        handleCeremonyPlaceEstablishmentChange(event) {

            // If we choose other
            if(event === null) {
                this.data.inquiry.ceremonyPlace.custom = true
                this.data.inquiry.ceremonyPlace.city = this.searchCeremonyPlaceCityRaw // Automatically retrieve the search city to fill the inquiryPlace city
                this.data.inquiry.ceremonyPlace.establishment = null
            }
            else{
                this.data.inquiry.ceremonyPlace.custom = false
                this.data.inquiry.ceremonyPlace.name = null
                this.data.inquiry.ceremonyPlace.address1 = null
                this.data.inquiry.ceremonyPlace.address2 = null
                this.data.inquiry.ceremonyPlace.city = null
            }

        },

        handleFuneralKindChange(event) {

            this.data.inquiry.dispersalPlaceKind = null
            this.data.inquiry.followingCremation = null
            this.data.inquiry.cemetaryPlace.city = null
            this.searchCemetaryPlaceCityRaw = null
            this.searchCemetaryPlaceCityClearer++ // Increment the key to trigger the autocomplete clear

            this.data.inquiry.crematoriumPlace.establishment = null
            this.searchCrematoriumPlaceEstablishment = null

        },

        handleFollowingCremationChange(event) {

            this.data.inquiry.cemetaryPlace.city = null
            this.searchCemetaryPlaceCityRaw = null
            this.searchCemetaryPlaceCityClearer++ // Increment the key to trigger the autocomplete clear

            switch (event) {
                case 'dispersal':
                    break;
                default:
                    this.data.inquiry.dispersalPlaceKind = null
                    break;
            }

        },

        handleDispersalPlaceKindChange(event) {
            this.data.inquiry.cemetaryPlace.city = null
            this.searchCemetaryPlaceCityRaw = null
            this.searchCemetaryPlaceCityClearer++ // Increment the key to trigger the autocomplete clear

        },

        handleCemetaryPlaceCityUpdate(event) {
        },

        handleCemetaryPlaceCityChange(event) {
        },

        handlePlotChange(event) {

            switch (event) {
                case 'yes':
                    break;
                default:
                    this.data.inquiry.tomb = null
                    this.data.inquiry.funeraryMonument = null
                    this.data.inquiry.masonry = null
                    break;
            }
        },

        handleOpenFormFuneralSummary(event) {

            // Open the drawer
            this.globalStore.drawerMode = 'funeral-summary'
            this.globalStore.isDrawerOpened = true

        },

        handleCancelFormFuneralSummary(event) {

            // Close the drawer
            this.globalStore.isDrawerOpened = false

        },

        handleSubmitFormFuneralSummary(event) {
        },

        handleProductChange(event) {
            // Retrieve all values that are strings (remove the false values of empty categories) and store them in the inquiry
            this.data.inquiry.products = Object.values(this.localProducts).filter(value => typeof value === 'string');
        },


        handleDeathNoticeCitiesUpdate(event){
        },

        handleDeathNoticeCitiesChange(event){
        },

        handleRemoveDeathNoticeCity(index){
            this.searchDeathNoticeCitiesRaw.splice(index, 1)
            this.validator?.$touch()
        },

        handleClickOpenCatchpraseCategory(catchphraseCategory) {

            // Retrieve the catchphrase category by code
            this.currentCatchphraseCategory = this.globalStore.config.catchphraseCategories.find(item => item.code === catchphraseCategory)

            // Retrieve a reference to the model (named like catchphraseCategory)
            this.currentCatchphraseModel = this.$refs[catchphraseCategory]

            // Open the drawer
            this.globalStore.drawerMode = 'death-notice-catchphrase'
            this.globalStore.isDrawerOpened = true

        },

        handleClickAddCatchphrase(model, content){

            const separator = '\n'

            // Concatenate the new content to the old one and update the source model (by triggering the event on it)
            model.$emit('update:modelValue', (model.modelValue === null || model.modelValue === '') ? content : model.modelValue + separator + content)

            // Trigger a change
            this.validator?.$touch()

            // Close the drawer
            this.globalStore.isDrawerOpened = false
        },

        handleClickDownloadDeathNotice(event) {
            InquiriesService.getDeathNoticePdf(this.id)
            .then((response) => {

                // Retrieve the headers
                const contentDisposition = response.headers['content-disposition']

                // Extract the filename from the headers
                const filename = contentDisposition.match(/filename=(\w+)/)?.[1]

                // Open in a new tab
                // const blob = new Blob([response.data], { type: 'application/pdf' })
                // const url = window.URL.createObjectURL(blob)
                // window.open(url);

                // Download
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename+'.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
        },

        handleClickOpenDeathNoticePreview(event) {

            // Open the drawer
            this.globalStore.drawerMode = 'death-notice-preview'
            this.globalStore.isDrawerOpened = true

        },

        handleDeathNoticeNewspaper1Change(event) {

            // Reset the state because the list changed
            this.data.inquiry.deathNoticeNewspaper1 = null
            this.deathNoticeNewspaper1.clearer++ // To trigger a reset

        },

        handleDeathNoticeNewspaper2Change(event) {

            // Reset the state because the list changed
            this.data.inquiry.deathNoticeNewspaper2 = null
            this.deathNoticeNewspaper2.clearer++ // To trigger a reset

        },

        /* endregion */

        /* region [helpers] */

        // Filter the products list to retrieve only a specific category of products
        filterProductsByCategory(productCategory) {
            return this.undertakerProducts.filter((product) => {
                if(product.productCategory === productCategory['@id']) {
                    return product
                }
            })
            .sort((a, b) => {
                // Sort by id
                if (a.id !== b.id) {
                    return a.id - b.id;
                }
            })
        },

        filterNewspapersByDepartment(department){
            return this.globalStore.config.newspapers ? this.globalStore.config.newspapers.filter((newspaper) => newspaper.department?.['@id'] === department?.['@id']) : []
        },

        productIsChecked(product, category) {
            if(this.localProducts && this.localProducts[category]){
                return this.localProducts[category].includes(product)
            }
        },

        /* endregion */

    },
}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

$gap: 20px;

// TEMP
.page-content{
    display: flex;

    .temp{
        display: none;
        margin-left: 20px;
        font-size: 12px;
        line-height: 0.7;

        pre{
            position: sticky;
            top: 100px;
            max-width: 400px;
            white-space: break-spaces;
        }
    }
}

.section{
    gap: $gap;
    margin-bottom: $gap;
}

.block{
    background-color: #fff;
    padding: 20px;

    @media (min-width: $s-mobile) {
        padding: 30px;
    }
}

.section-summary{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "identity"
                         "price"
                         "status";

    @media (min-width: $s-tablet) {
        grid-template-columns: 1fr 2fr;
        grid-template-areas: "identity price"
                             "identity status";
    }


    .block-identity{
        grid-area: identity;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .picture{
            width: 90px;
            height: 90px;
            margin-bottom: 10px;

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .tags{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            margin-bottom: 10px;
        }

        .name{
            margin-bottom: 10px;
            text-align: center;
        }

        .date{
            font-size: 12px;
        }
    }

    .block-price{
        grid-area: price;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .main{
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
        }

        .accessories{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .price{
            position: relative;
            transition: color $t-duration;

            //&:before {
            //    content: '';
            //    position: absolute;
            //    width: 0%;
            //    height: 2px;
            //    top: 45%;
            //    right: 0;
            //    background-color: $c-gray;
            //    transition: width $t-duration;
            //}

            &.need-refresh {
                color: transparentize($c-gray, 0.5);

                //&:before {
                //    width: 100%;
                //}
            }
        }

        .notes{

        }

    }

    .block-status{
        grid-area: status;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title{
            margin-bottom: 12px;
        }

        .status{

        }

    }
}

.section-comments{
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $s-tablet) {
        flex-wrap: nowrap;
    }

    .block-comment{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title{
            margin-bottom: 10px;
        }

        .comment{
        }
    }

    .block-call{
        flex: 1 1 auto;
        display: flex;
        flex-direction: revert;
        justify-content: space-between;
        align-items: center;
        gap: 10px 20px;
        flex-wrap: wrap;

        @media (min-width: $s-tablet) {
            flex: 1 1 20%;
        }

        .title{
        }

        .time, .phone{
            font-size: 16px;
            font-weight: bold;

            &:not(:last-child):not(:only-child){
                margin-bottom: 20px;
            }

            .v-icon{
                font-size: 17px;
                margin-right: 8px;
            }
        }

        .phone{
            display: flex;
            align-items: center;
        }
    }
}

.section-tabs{

    .v-tabs {
        --v-tabs-height: 60px;

        @media (min-width: $s-mobile) {
            --v-tabs-height: 70px;
        }
    }

    .v-tab{
        font-size: 13px;

        &.v-tab:not(:last-child){
            border: none;
        }

        &.v-tab--selected{
            background-color: #fff;

            .v-btn__overlay{
                display: none;
            }
        }

    }

    .v-window-item{
        background-color: #fff;
        padding: 20px 20px;

        @media (min-width: $s-mobile) {
            padding: 30px 40px;
        }
    }

    .title{
        display: block;
        margin-bottom: 20px;
    }

    .subtitle{
        margin-bottom: 2px;
    }

    .heading{
        display: flex;
        flex-direction: column;
        gap: 0 20px;
        flex-wrap: wrap;

        @media (min-width: $s-mobile-sm) {
            flex-direction: row;
        }
        
        .title{
            flex: 1 0 auto;
        }

        .tools{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 10px;

            @media (min-width: $s-mobile-sm) {
                margin-left: auto;
            }
            
            .button:not(.v-btn--icon) {
                flex: 1 0 auto;
            }
        }
    }

}

.product-category{
    margin-top: 20px;
    margin-bottom: 30px;

    .heading{
        margin-bottom: 10px;
    }

    .products{
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(1, 1fr);

        @media (min-width: $s-tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        .product{
            padding: 15px 15px 15px 0px;
            border: 1px solid $c-border;
            background-color: $c-gray-light;
            display: flex;
            align-items: center;
            border-radius: 10px;
            transition: border $t-duration * 0.5 ease, background-color $t-duration * 0.5 ease;

            @media (min-width: $s-mobile) {
                padding: 15px 20px 15px 10px;
            }

            &::v-deep(.v-label) {
                width: 100%;
            }

            &::v-deep(.v-input__control){
                width: 100%;
            }

            &::v-deep(.v-selection-control) {
                width: 100%;

                .v-icon{
                    font-size: 16px;
                }
            }

            &::v-deep(.v-selection-control__wrapper) {
                align-self: flex-start;
                margin-top: 8px;
            }

            &:hover{
                border-color: $c-third;
            }

            &.active{
                border-color: $c-third;
                background-color: transparentize($c-third, 0.85);

                &::v-deep(.v-selection-control) {
                    .v-icon{
                        color: $c-third;
                    }
                }
            }

            .wrapper{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px 0;
                width: 100%;
            }

            .picture-and-infos{
                display: flex;
                gap: 10px 0;
            }

            .picture{
                flex: none;
                width: 50px;
                height: 50px;
                border-radius: 10px;
                border: solid 1px $c-border;
                background-color: white;
                overflow: hidden;
                margin-right: 20px;
                margin-left: 5px;

                > img{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }

            }
            .name{
                font-weight: bold;
                margin-right: 10px;
            }
            .material{
                text-transform: uppercase;
                font-weight: 300;
                font-size: 12px;
                margin-right: 10px;
            }
            .price{
                margin-left: auto;
                font-weight: bold;
                flex-wrap: wrap;
                gap: 10px 0;
            }

            .buttons{
                display: flex;
                align-items: center;
                margin-left: 10px;
                gap: 10px;
            }

        }
    }
}

.steps{
    .step{
        padding-top: 10px;

        &:not(:last-child):not(:only-child){
            padding-bottom: 20px;
            border-bottom: 1px solid $c-border;
        }

        .col{
            display: flex;
            align-items: center;
        }

        .name{
            display: flex;
            align-items: flex-start;
            gap: 10px;
            font-size: 18px;
            margin-bottom: 10px;

            i{
                font-size: 20px;
            }
        }

        .place{
            font-weight: bold;
        }
    }
}

.cities{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px;

    .city{

    }
}

.catchphrases{
    display: flex;
    flex-direction: column;
    gap: 10px;

    .catchphrase{

    }
}

.death-notice-preview{
    font-family: Verdana, Arial, sans-serif;
    max-width: 290px;
    padding: 30px;
    font-size: 16px;
    line-height: 1.25;
    border: 1px solid #101514;
    white-space: pre-line; // To preserve new lines
    margin-top: 16px;
}

</style>

