
// Function that deeply merge two object
export function objectDeepMerge(target, source) {
    for (const key of Object.keys(source)) {
        const currenttarget = target[key];
        const currentsource = source[key];

        if (currenttarget) {
            const objectsource  = typeof currentsource === 'object';
            const objecttarget  = typeof currenttarget === 'object';

            if (objectsource && objecttarget) {
                void (Array.isArray(currenttarget) && Array.isArray(currentsource)
                    ? void (target[key] = currenttarget.concat(currentsource))
                    : void objectDeepMerge(currenttarget, currentsource));

                continue;
            }
        }

        target[key] = currentsource;
    }

    return target;
}

/*
Function that transforms objects keys containing a dot in nested objects :
It's useful to transforms symfony translation keys into nested objects
{
    'a.b.c' : '1',
    'a.b.d' : '2
}
into :
a{
    b{
        c : '1',
        d : '2'
    }
}
*/
export function convertTranslationKeys(obj) {
    const newObj = {};
    for (const key in obj) {

        // If it's another nested object
        if (typeof obj[key] === 'object') {
            newObj[key] = convertTranslationKeys(obj[key]);
        }
        // If it's a translation
        else {

            // If the key don't include spaces and contains a dot but not at the end
            if(!key.includes(' ') && key.includes('.') && key.slice(-1) !== '.') {

                // Split the key by dot and recreate the object structure
                const keys = key.split('.');
                let tempObj = newObj;
                for (let i = 0; i < keys.length - 1; i++) {
                    const k = keys[i];
                    if (!tempObj[k]) {
                        tempObj[k] = {};
                    }
                    tempObj = tempObj[k];
                }
                tempObj[keys[keys.length - 1]] = obj[key];
            }
            // In the other cases, simply copy the key and the value
            else {
                newObj[key] = obj[key]
            }
        }
    }
    return newObj;
}

export function debounce(fn, wait){
    let timer;
    return function(...args){
        if(timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(()=>{
            fn.apply(context, args); // call the function if time expires
        }, wait);
    }
}