<template>
    <div class="page-wrapper">

        <PageHeader>

            <template v-slot:tools>
                <transition name="fade">
                    <v-tabs
                        class="tabs"
                        grow
                        v-model="tab"
                    >
                        <v-tab value="PageParametersAgency">{{ $t('app.page.parameters.agency.tab') }}</v-tab>
                        <v-tab value="PageParametersPricing">{{ $t('app.page.parameters.pricing.tab') }}</v-tab>
                        <v-tab value="PageParametersEstablishments">{{ $t('app.page.parameters.establishments.tab') }}</v-tab>
                        <v-tab value="PageParametersProducts">{{ $t('app.page.parameters.products.tab') }}</v-tab>
                    </v-tabs>
                </transition>
            </template>

        </PageHeader>

<!--        <transition name="slide" mode="out-in"> &lt;!&ndash; out-in : current element transitions out first, then, the new element transitions in &ndash;&gt;-->
            <!-- We use dynamic components to instantiate each tab separately -->
            <component :is="tab"></component>
<!--        </transition>-->

    </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store.js';

import PageHeader from "@/components/commons/PageHeader.vue"
import PageContent from "@/components/commons/PageContent.vue"
import PageParametersAgency from "@/components/pages/PageParametersAgency.vue"
import PageParametersPricing from "@/components/pages/PageParametersPricing.vue"
import PageParametersEstablishments from "@/components/pages/PageParametersEstablishments.vue"
import PageParametersProducts from "@/components/pages/PageParametersProducts.vue"

export default {
    components: { PageHeader, PageContent, PageParametersAgency, PageParametersPricing, PageParametersEstablishments, PageParametersProducts },

    setup() {
        const authStore = useAuthStore()
        return {
            authStore,
        }
    },

    data() {
        return {
            tab: null,
        }
    },

    // Navigation guard to prevent the user to directly access to the parameters page without password
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if(!vm.authStore.hasAccessToParameters){
                vm.$router.push({ name: 'home' })
            }
        })
    },

    // Navigation guard when the user leave this page
    beforeRouteLeave (to, from) {
        // The user leave the parameters, revoke the access
        if(this.authStore.hasAccessToParameters){
            this.authStore.hasAccessToParameters = false
        }
    },

}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.tabs{

    .v-tab.v-tab{
        min-width: inherit;
        padding: 0 2px;

        font-size: 14px;

        @media (min-width: $s-mobile) {
            font-size: 16px;
        }
    }
}
</style>

