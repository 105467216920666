<template>
    <v-pagination
        v-if="itemsTotal"
        v-model="currentPage"
        :length="pages"
        :total-visible="maxButtons"
        prev-icon="icon-arrow-left"
        next-icon="icon-arrow-right"
    >

        <template v-slot:prev="{ icon, onClick, disabled, ariaLabel, ariaDisabled }">
            <v-btn
                :icon="icon"
                @click="onClick"
                :disabled="disabled"
                :class="{ 'mobile' : $isSmallScreen() }"
            >
                <v-icon :icon="icon"></v-icon>
                <span>{{ $t('app.global.actions.previous') }}</span>
            </v-btn>
        </template>

        <template v-slot:next="{ icon, onClick, disabled, ariaLabel, ariaDisabled }">
            <v-btn
                :icon="icon"
                @click="onClick"
                :disabled="disabled"
                :class="{ 'mobile' : $isSmallScreen() }"
            >
                <span>{{ $t('app.global.actions.next') }}</span>
                <v-icon :icon="icon"></v-icon>
            </v-btn>
        </template>

    </v-pagination>
</template>

<script>
export default {
    props: {
        itemsTotal: null,
        itemsPerPage: null,
        maxButtons: {  // Number of visible pagination buttons
            default: 5,
        },
    },
    data() {
        return {
            currentPage: 1,
        }
    },
    computed: {
        pages() {
            return Math.ceil(this.itemsTotal / this.itemsPerPage)
        },
    },
}
</script>