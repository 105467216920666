import CitiesService from '@/middleware/cities';
import EstablishmentsService from '@/middleware/establishments';

export function autocompleteCity(value, component) {
    component.isSearching = true

    CitiesService.search(value)
    .then((response) => {
        component.items = this.$extract(response)
    })
    .finally(() => {
        component.isSearching = false
    });
}