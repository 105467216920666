<template>

    <Overlay :visible="isWaiting"></Overlay>

    <Transition name="fade">
        <div v-if="data">

            <Form
                :data="data"
                :validations="validations"
                ref="form"
                @mounted="handleFormMounted"
                @valid="handleValid"
                no-gutters
            >

                <template v-if="id">
                    <div class="title h2">{{ $t('app.page.parameters.products.form.edit.title') }}</div>
                    <div class="intro">{{ $t('app.page.parameters.products.form.edit.intro') }}</div>
                </template>
                <template v-else>
                    <div class="title h2">{{ $t('app.page.parameters.products.form.add.title') }}</div>
                    <div class="intro">{{ $t('app.page.parameters.products.form.add.intro') }}</div>
                </template>

                <Input
                    class="section"
                    type="InputUpload"
                    :label="$t('app.page.parameters.products.form.mainPicture.label')"
                    :placeholder="$t('app.page.parameters.products.form.mainPicture.format')"
                    v-model="data.mainPicture"
                    path="data.mainPicture"
                    subentity
                    required
                >
                </Input>

                <Input
                    class="section"
                    type="InputUpload"
                    :label="$t('app.page.parameters.products.form.pictures.label')"
                    :placeholder="$t('app.page.parameters.products.form.pictures.format')"
                    v-model="data.pictures"
                    path="data.pictures"
                    subentity
                    multiple
                >
                </Input>

                <div class="title h3">{{$t('app.page.parameters.products.content.informations.title') }}</div>

                <Input
                    type="InputSelect"
                    :cols="12"
                    :label="$t('app.page.parameters.products.form.productCategory.label')"
                    :placeholder="$t('app.page.parameters.products.form.productCategory.placeholder')"
                    v-model="data.productCategory"
                    path="data.productCategory"
                    :items="productCategories"
                    itemValue="@id"
                    itemTitle="name"
                    :translated="true"
                    required
                >
                </Input>

                <Input
                    type="InputSelect"
                    :cols="12"
                    :label="$t('app.page.parameters.products.form.funeralKinds.label')"
                    :placeholder="$t('app.page.parameters.products.form.funeralKinds.placeholder')"
                    v-model="data.funeralKinds"
                    path="data.funeralKinds"
                    :items="funeralKinds"
                    itemValue="@id"
                    itemTitle="name"
                    :translated="true"
                    multiple
                    required
                >
                </Input>

                <Input
                    type="InputSelect"
                    :cols="12"
                    :label="$t('app.page.parameters.products.form.productUsages.label')"
                    :placeholder="$t('app.page.parameters.products.form.productUsages.placeholder')"
                    v-model="data.productUsages"
                    path="data.productUsages"
                    :items="productUsages"
                    itemValue="@id"
                    itemTitle="name"
                    :translated="true"
                    multiple
                >
                </Input>

                <Input
                    type="InputTranslation"
                    :label="$t('app.page.parameters.products.form.name.label')"
                    :placeholder="$t('app.page.parameters.products.form.name.placeholder')"
                    v-model="data.translations"
                    field="name"
                    path="data.translations.{locale}.name"
                    required
                >
                </Input>

                <Input
                    type="InputTranslation"
                    :label="$t('app.page.parameters.products.form.material.label')"
                    :placeholder="$t('app.page.parameters.products.form.material.placeholder')"
                    v-model="data.translations"
                    field="material"
                    path="data.translations.{locale}.material"
                    required
                >
                </Input>

                <Input
                    class="price"
                    type="InputText"
                    :label="$t('app.page.parameters.products.form.price.label')"
                    :placeholder="$t('app.page.parameters.products.form.price.placeholder')"
                    v-model.number="data.price"
                    path="data.price"
                    suffix="€"
                    required
                >
                </Input>


                <Input
                    type="InputTranslation"
                    :label="$t('app.page.parameters.products.form.description.label')"
                    :placeholder="$t('app.page.parameters.products.form.description.placeholder')"
                    v-model="data.translations"
                    field="description"
                    path="data.translations.{locale}.description"
                    required
                    textarea
                >
                </Input>

                <v-col md="12">
                    <div class="text-notice">
                        {{ $t('app.global.messages.required_fields') }}
                    </div>
                </v-col>

<!--                <pre style="line-height: 1;"><small>{{ data }}</small></pre>-->
            </Form>

            <div class="buttons buttons-sticky">
                <v-btn
                    v-if="validator"
                    class="button button-small block"
                    type="submit"
                    :disabled="isWaiting || validator.$invalid || !validator.$dirty"
                    @click="handleSubmit()"
                >

                    <template v-if="id">
                        {{ $t('app.global.actions.save') }}
                    </template>
                    <template v-else>
                        {{ $t('app.global.actions.add') }}
                    </template>
                </v-btn>

                <v-btn
                    class="button button-small button-neutral block"
                    @click="handleCancel()"
                >
                    {{ $t('app.global.actions.cancel') }}
                </v-btn>
            </div>

        </div>
    </Transition>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store.js';

import ProductsService from '@/middleware/products'

import Overlay from "@/components/commons/Overlay.vue"
import Form from "@/components/commons/Form.vue"
import Input from "@/components/commons/Input.vue"

import { required, integer } from '@/helpers.validators'

export default {

    emits: ['cancel', 'create', 'update'],

    setup() {
        const authStore = useAuthStore()
        return {
            authStore,
        }
    },

    components: { Overlay, Form, Input },

    props: {
        id:{
            required: false,
            default: null,
        },
        productCategory:{
            required: true,
        },
        productCategories:{
            required: true,
        },
        productUsages:{
            required: true,
        },
        funeralKinds:{
            required: true,
        },
    },

    data() {
        return {
            isWaiting: true,
            data: null,
            dataDefault: {
                undertaker: this.authStore.undertaker['@id'],
                productCategory: this.productCategory, // To automatically preselect the product category based on the add button clicked
                price: null,
                mainPicture: null,
                published: true,
                translations: this.$initTranslations(),
            },
            validations: {
                data: {
                    productCategory: { required },
                    funeralKinds: { required },
                    price: { required, integer },
                    mainPicture: { required },
                    translations: {
                        fr: {
                            name: { required },
                            description: { required },
                            material: { required },
                        },
                    },
                },
            },
            validator: null,
        };
    },

    mounted() {

        // Check if an id is defined to choose between edit or create mode
        if(this.id){
            // Show the loader
            this.isWaiting = true

            // Load the data
            this.loadItem(this.id)
            .then((response) => {
                // Extract the data in the current item
                this.data = this.$extract(response)

                // Hide the loader
                this.isLoaded = true
            })
            .finally(() => {
                // The form is ready
                this.isWaiting = false
            })
        }
        else {
            // Initialize the current item with a copy of the initial state
            this.data = { ...this.dataDefault }

            // The form is ready
            this.isWaiting = false
        }

    },


    computed: {

    },

    methods: {

        /* region [data manipulation] */

        loadItem(id) {
            return ProductsService.get(id)
        },

        updateItem(id, data) {
            return ProductsService.update(id, data)
        },

        createItem(data) {
            return ProductsService.create(data)
        },

        /* endregion */

        /* region [event handlers] */

        handleFormMounted(validator){
            this.validator = validator
        },

        handleCancel(){
            this.$emit('cancel', event)
        },

        handleSubmit(){
            this.$refs.form.submit()
        },

        handleValid(){
            this.isWaiting = true

            // Check if an id is defined to choose between edit or create
            if(this.id) {
                this.updateItem(this.id, this.data)
                .then((response) => {
                    this.$emit('update', event)
                })
                .finally(() => {
                    this.isWaiting = false
                });
            }
            else{
                this.createItem(this.data)
                .then((response) => {
                    this.$emit('create', event)
                })
                .finally(() => {
                    this.isWaiting = false
                });
            }
        },

        /* endregion */

    },
}
</script>

<style lang="scss" scoped>

.section{
    margin-bottom: 20px;
}

</style>
