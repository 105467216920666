import { h } from 'vue'
import 'vuetify/styles'

export const vuetifyConfig = {
    defaults: {
        global: {
            ripple: false, // Disable the ripple effect
        },
        VTextField: {
            variant: 'solo',
            singleLine: true, // Hide the label on focus
            hideDetails: 'auto',
        },
        VTextarea: {
            variant: 'solo',
            singleLine: true, // Hide the label on focus
            hideDetails: 'auto',
        },
        VSelect: {
            variant: 'solo',
            singleLine: true, // Hide the label on focus
            hideDetails: 'auto',
        },
        VFileInput: {
            variant: 'solo',
            singleLine: true, // Hide the label on focus
            hideDetails: 'auto',
        },
        VAutocomplete: {
            variant: 'solo',
            singleLine: true, // Hide the label on focus
            hideDetails: 'auto',
        },
        VSnackbar: {
            variant: 'flat',
            location: 'top',
        },
        VSwitch: {
            inset: true,
            hideDetails: 'auto',
        },
        VTabs: {
            hideSlider: true,
        },
        VRow: {
            // noGutters: true,
        },
        VCol: {
            cols: 12, // All columns will take the full width on the smallest breakpoint
        }
    },
    // Custom icon set
    icons: {
        defaultSet: 'pure',
        aliases: {
            menu: 'burger',
            collapse: 'icon-arrow-angle-down',
            prev: 'icon-arrow-angle-left',
            next: 'icon-arrow-angle-right',
            left: 'icon-arrow-left',
            right: 'icon-arrow-right',
            info: 'icon-help',
            warning: 'icon-warning',
            close: 'icon-cross',
            delete: 'icon-trash',
            clear: 'icon-cross',
            checkboxOn: 'icon-checkbox-checked',
            checkboxOff: 'icon-checkbox',
            dropdown: 'icon-arrow-angle-down',
            radioOn: 'icon-radio-checked',
            radioOff: 'icon-radio',
            file: 'icon-download',
            settings: 'icon-settings',
            help: 'icon-help',
            phone: 'icon-phone',
            eyeOpened: 'icon-eye-opened',
            eyeClosed: 'icon-eye-closed',
        },
        sets: {
            pure: {
                component: (props) => h(props.tag, { class: ['pure', props.icon] })
            }
        }
    },
    theme: {
        defaultTheme: 'funix',
        themes: {
            funix: {
                dark: false,
                colors: {
                    primary: '#d1957c',
                    secondary: '#f8e4db',
                    third: '#9ad2c9',
                    fourth: '#d5ece8',
                    success: '#d1957c',
                    current: '#d1957c',
                }
            }
        }
    }
}