<template>
    <div>
<!--        <pre><small>Files : {{files}}</small></pre>-->
        <v-file-input
            :placeholder="placeholder"
            :required="required"
            :autocomplete="autocomplete"
            :error-messages="errorMessages"
            @change="handleFileChange($event)"
            :multiple="multiple"
            ref="input"
        >
        </v-file-input>

        <div class="heading">

            <div class="placeholder" v-if="placeholder">
                {{ placeholder }}
            </div>

            <v-btn v-if="multiple || (!multiple && (!subentity && (files.length === 0 || !files[0])) || (subentity && (files.length === 0 || !files[0] || !files[0].picture)))"
                   class="button button-small button-upload"
                   @click="onImport"
            >
                {{ $t('app.global.actions.import') }}
            </v-btn>

        </div>

        <div class="previews"
             :class="{ 'multiple': multiple }"
        >
            <template v-for="file in files"
                      :key="file?.picture?.id"
            >
                <div class="preview"
                     v-if="file && getFileModel(file)"
                >
                    <img :src="getFileModel(file).media_preview">

                    <div class="buttons">
                        <v-btn v-if="!multiple"
                               class="button button-small button-neutral"
                               icon="icon-edit"
                               @click="onImport"
                        >
                        </v-btn>

                        <v-btn v-if="multiple"
                               class="button button-small button-neutral"
                               icon="icon-trash"
                               @click="onDelete(file)"
                        >
                        </v-btn>
                    </div>

                </div>

            </template>
        </div>

        <div
            v-if="errorMessages.length"
            class="errors"
        >
            <div
                class="error text-error"
                v-for="errorMessage in errorMessages"
            >
                {{ errorMessage }}
            </div>
        </div>

    </div>
</template>

<script>
import MediaService from '@/middleware/media';

export default {

    inject: ['v$'],

    props: {

        modelValue: {
            required: true,
        },
        placeholder: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
        autocomplete: {
            required: false,
            type: String,
        },
        errorMessages: null,
        multiple: {
            required: false,
            type: Boolean,
            default: false,
        },
        subentity: {
            required: false,
            type: Boolean,
            default: false,
        },
        mediaType: {
            required: false,
            type: String,
            default: 'private',
        }
    },
    data() {
        return {
            dataSingle: null,
            dataMultiple: [],
        }
    },
    mounted() {
        // Load the passed medias into a local array
        if(this.modelValue){

            // Deep copy the model locally
            if(this.multiple){
                this.dataMultiple = this.modelValue.map(item => {
                    return {
                        ...item
                    }
                })
            }
            else{
                this.dataSingle = { ...this.modelValue }
            }

        }
    },

    emits: ['update:modelValue'],

    computed: {
        files: function () {
            // Helper that always return an array, easier for the looping on items
            return (this.multiple ? this.dataMultiple : [this.dataSingle])
        },
    },

    methods: {

        getFileModel: function (file) {
            return (this.subentity ? file.picture : file)
        },

        onImport() {
            // Trigger the click on the hidden input
            this.$refs.input.click()
        },

        onDelete(file) {
            // Remove the object from the array if it has the same ID
            if(this.multiple){
                this.dataMultiple = this.dataMultiple.filter((item) => item.picture.id !== file.picture.id)
                this.$emit('update:modelValue', this.dataMultiple)
            }
            else{
                this.dataSingle = null
                this.$emit('update:modelValue', this.dataSingle)
            }

            // Force validation refresh (because the model update will not automatically trigger the validation)
            this.v$.$touch()
        },

        handleFileChange(event) {

            // Retrieve input data
            const files = event.target.files

            // Loop through all added files
            for (let index = 0; index < event.target.files.length; index++) {
                const file = files[index]

                // Prepare a form data container that will hold all files to be uploaded
                let data = new FormData()
                data.append('type', this.mediaType)
                data.append('files', file)

                // Upload the form
                MediaService.upload(data)
                .then((response) => {
                    if (response.data && response.data.id) {

                        // Build the IRI from the media id
                        const mediaIRI = '/api/media/' + response.data.id

                        // Create the model of the newly added media
                        let addedfile = {
                            "@id": mediaIRI,
                            id: response.data.id,
                            name: response.data.media_name,
                            media_preview: response.data.media_preview,
                        }

                        // If it's a subentity, change the structure
                        if(this.subentity){
                            addedfile = {
                                picture: addedfile
                            }
                        }

                        // Add the newly created file to the model and submit an event
                        if(this.multiple){
                            this.dataMultiple.push(addedfile)
                            this.$emit('update:modelValue', this.dataMultiple)
                        }
                        else{
                            this.dataSingle = addedfile
                            this.$emit('update:modelValue', this.dataSingle)
                        }

                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.v-file-input {
    display: none;
}

.heading{
    display: flex;
    margin-bottom: 10px;
    gap: 10px;

    .placeholder{
        font-size: 14px;
        margin-right: auto;
    }
}

.button-upload{
    margin-bottom: 12px;
}

.previews{
    $gap: 20px;

    display: inline-block;

    .preview{
        width: 100%;
        position: relative;

        img{
            width: 100%;
        }

        .buttons{
            $gap: 10px;
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            gap: $gap;
            top: $gap;
            right: $gap;
        }
    }

    &:not(.multiple){
        img{
            height: 300px;
            //max-width: 100%;
            //width: auto;
            object-fit: cover;
        }
    }

    &.multiple{
        column-count: 1;
        column-gap: $gap;
        width: 100%;

        @media (min-width: $s-mobile-md) {
            column-count: 2;
        }

        .preview{
            margin-bottom: $gap;
        }
    }
}

.errors{
    font-size: 0.75rem;
    padding-left: 16px;
}

</style>
