<template>
    <div>

        <PageHeader noburger
                    :class="{ 'additional-offset': $isSmallScreen() }"
        >
            <template v-slot:left>
                <div class="h2">{{ $t('app.page.parameters.pricing.title') }}</div>
            </template>

            <template v-slot:right>
                <transition name="fade">
                    <div v-if="validator">
                        <v-btn class="button button-alt button-small no-text-mobile no-icon-desktop"
                               :disabled="isWaiting || validator.$invalid || !validator.$dirty"
                               :append-icon="'icon-save'"
                               @click="handleSave"
                        >
                            {{ $t('app.global.actions.save_modifications') }}
                        </v-btn>
                    </div>
                </transition>
            </template>
        </PageHeader>

        <PageContent>
            <Overlay :visible="isWaiting || !isLoaded"></Overlay>
            <transition name="fade">
                <div class="page-limiter"
                     v-if="isLoaded"
                >

                    <Form
                        :data="data"
                        :validations="validations"
                        ref="form"
                        @mounted="handleFormMounted"
                        @valid="handleSubmit"
                        no-gutters
                    >

                        <div>{{ $t('app.page.parameters.pricing.content.intro.text') }}</div>

                        <Notice :isVisible="isImportantFieldsEmpty">{{ $t('app.page.parameters.pricing.content.intro.notice') }}</Notice>

                        <div class="service-pricing-categories">
                            <template v-for="servicePricingCategory in services">
                                <div class="service-pricing-category"
                                     v-if="servicePricingCategory.published"
                                >
                                    <div class="name h2">
                                        {{ this.$trans(servicePricingCategory, 'name')}}
                                    </div>

                                    <div class="service-pricings">
                                        <template v-for="servicePricing in servicePricingCategory.servicePricings">
                                            <div class="service-pricing"
                                                 v-if="servicePricing.published"
                                            >
                                                <div class="name">
                                                    {{ this.$trans(servicePricing, 'name')}}
                                                    <span v-if="servicePricing.mandatory">(*)</span>
                                                </div>

                                                <div class="price field"
                                                     :class="{ 'required': servicePricing.mandatory, 'important': servicePricing.mandatory && $isNullOrEmpty(getServicePricingPrice(servicePricing.code))}"
                                                >
                                                    <v-text-field
                                                        class="price"
                                                        placeholder="0"
                                                        suffix="€"
                                                        :value="getServicePricingPrice(servicePricing.code)"
                                                        @input="setServicePricingPrice($event, servicePricing.code)"
                                                    ></v-text-field>
                                                </div>

<!--                                                    <Input-->
<!--                                                        type="InputText"-->
<!--                                                        :cols="6"-->
<!--                                                        :value="getServicePricingPrice(servicePricing.code)"-->
<!--                                                        @input="setServicePricingPrice($event, servicePricing.code)"-->
<!--                                                        suffix="€"-->
<!--                                                        :required="servicePricing.mandatory"-->
<!--                                                        :important="servicePricing.mandatory"-->
<!--                                                    >-->
<!--                                                    </Input>-->

                                            </div>
                                        </template>

                                        <div class="text-notice">
                                            {{ $t('app.global.messages.required_fields') }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>

                    </Form>

                </div>
            </transition>
        </PageContent>
    </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store'
import { useMessageStore } from '@/stores/message.store'

import PageHeader from "@/components/commons/PageHeader.vue"
import PageContent from "@/components/commons/PageContent.vue"
import Overlay from "@/components/commons/Overlay.vue"
import Form from "@/components/commons/Form.vue"
import Input from "@/components/commons/Input.vue"
import Notice from "@/components/commons/Notice.vue"

import ServicePricingCategoriesService from '@/middleware/servicePricingCategories';
import UndertakersService from '@/middleware/undertakers';


import { required, integer } from '@/helpers.validators'
import { helpers, requiredIf } from '@vuelidate/validators'

export default {
    components: { PageHeader, PageContent, Overlay, Form, Input, Notice },

    setup() {
        const authStore = useAuthStore()
        const messageStore = useMessageStore()

        return {
            authStore,
            messageStore,
        }
    },

    data() {
        return {
            services: null,
            data: null,
            validations: {
                data: {
                    servicePricings: {
                        $each: helpers.forEach({
                            price: {
                                required: requiredIf((modelValue, siblings, index, collectionSiblings, vm) => {
                                    return siblings.servicePricing.category.published && siblings.servicePricing.published && siblings.servicePricing.mandatory;
                                }),
                                integer,
                            },
                        })
                    },
                },
            },
            validator: null,
            isWaiting: false,
            isServicePricingCategoriesLoaded: false,
            isUndertakerServicePricingsLoaded: false,
        }
    },

    mounted() {

        // Load the initial data
        this.loadData()

    },

    computed: {

        // Helper to know that everything is loaded
        isLoaded() {
            return this.isServicePricingCategoriesLoaded && this.isUndertakerServicePricingsLoaded
        },

        isImportantFieldsEmpty() {
            return this.validator ? this.validator.$invalid : false
        }

    },

    methods: {

        /* region [data manipulation] */

        loadData() {

            // Retrieve all services that are not related to an establishment category
            ServicePricingCategoriesService.getAllGeneric()
            .then((response) => {
                this.services = this.$extract(response)
                this.isServicePricingCategoriesLoaded = true
            })

            // Retrieve the current undertaker pricings
            UndertakersService.getPricings(this.authStore.undertakerId)
            .then((response) => {
                this.data = this.$extract(response)
                this.isUndertakerServicePricingsLoaded = true
            })

        },

        /* endregion */

        /* region [event handlers] */

        handleFormMounted(validator){
            this.validator = validator
        },

        handleSave(){
            this.$refs.form.submit()
        },

        handleSubmit(){
            this.isWaiting = true

            UndertakersService.updatePricings(this.authStore.undertakerId, this.data)
            .then((response) => {
                this.messageStore.addInfo(this.$t('app.global.messages.saved_with_success'))
                this.validator.$reset(); // Reset the form validator state
            })
            .finally(() => {
                this.isWaiting = false
            });
        },

        /* endregion */

        /* region [helpers] */

        // NOTE : since the model is retrieved dynamically, we can't use the standard v-model attribute, instead we use a :value + event binding.
        getServicePricingModel(code) {
            return this.data.servicePricings.filter((item)=> item.servicePricing.code === code)[0]
        },
        getServicePricingPrice(code) {
            const tempData = this.getServicePricingModel(code)
            return tempData ? tempData.price : null
        },
        setServicePricingPrice (event, code) {
            let tempData = this.getServicePricingModel(code)
            tempData.price = parseFloat(event.target.value) ? parseFloat(event.target.value) : null
        },

        /* endregion */
    },

}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.page-header {
    top: $header-height + 1px; // Override the sticky position

    &.additional-offset{
        top: $header-height + $header-height + 1px;
    }
}

.service-pricing-categories{
    width: 100%;

    .service-pricing-category{


        > .name{
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .service-pricings{

            .service-pricing{
                display: flex;
                align-items: center;
                background-color: #ffffff;
                padding: 10px 20px;
                gap: 10px;

                @media (min-width: $s-mobile) {
                    padding: 10px 30px;
                }


                &:not(:last-child):not(:only-child){
                    margin-bottom: 10px;
                }

                .name{
                    margin-right: auto;
                    word-break: break-word;
                }

                .price{
                    width: 140px;
                }

            }

            .text-notice{
                margin-top: 20px;
            }
        }
    }
}
</style>

