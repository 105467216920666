<template>
    <div class="page-header">

        <div class="header" v-if="!!this.$slots['left'] || !!this.$slots['right'] || (!('noburger' in $attrs) && this.$isSmallScreen())">

            <v-icon
                v-if="!('noburger' in $attrs) && this.$isSmallScreen()"
                class="button-burger"
                :icon="'icon-burger'"
                @click.stop="globalStore.isBurgerOpened = true"
            >
            </v-icon>

            <div class="wrapper">

                <div class="left">
                    <slot name="left"></slot>
                </div>

                <div class="right">
                    <slot name="right"></slot>
                </div>

            </div>
        </div>

        <div class="tools" v-if="!!this.$slots['tools']">
            <slot name="tools"></slot>
        </div>

    </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store';

export default {

    setup() {
        const globalStore = useGlobalStore()
        return {
            globalStore,
        }
    },

    data() {
        return {
        }
    },
}
</script>


<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.page-header{
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;

    > * {
        border-bottom: 1px solid $c-border;
    }

    .header{
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        height: $header-height;
        background-color: white;
        padding: 0px $padding-x * 0.5;

        @media (min-width: $s-tablet) {
            padding: 0px $padding-x;
        }

        .wrapper{
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 10px;
        }

        .left, .right{
            display: flex;
            align-items: center;
            flex: 1;
        }

        .left{
            flex: 0 1 auto;
        }

        .right{
            justify-content: end;

            &::v-deep(.buttons) {
                display: flex;
                gap: 10px;
            }
        }
    }

    .tools{
    }
}

.button-burger{
    margin-right: 15px;
}

</style>