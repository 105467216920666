<template>
    <div>
        <PageHeader>
            <template v-slot:left>
                <div class="h2">Page Test</div>
            </template>

        </PageHeader>

        <PageContent>
            <transition name="slide">
                <div class="page-limiter">

                    <h2>Selects</h2>
                    <br>

                    <v-row>
                        <v-col md="6">
                            <Input
                                type="InputSelect"
                                :cols="6"
                                v-model="undertakerFuneralHome"
                                v-model:modelRaw="undertakerFuneralHomeRaw"
                                :items="undertakerFuneralHomes"
                                itemValue="@id"
                                itemTitle="name"
                                :translated="true"
                                :returnObject="false"
                                @change="handleUpdate"
                            >
                            </Input>

                        </v-col>
                        <v-col md="6">
                            model_data : <pre><small>{{ undertakerFuneralHome }}</small></pre><br>
                            model_raw : <pre><small>{{ undertakerFuneralHomeRaw }}</small></pre><br>
                        </v-col>
                    </v-row>

                    <hr>

                    <h2>Autocompletes</h2>
                    <br>

                    <v-row>
                        <v-col md="6">
                            <Input
                                type="InputAutocomplete"
                                :cols="6"
                                v-model="city"
                                @search="autocompleteCity"
                                itemValue="@id"
                                itemTitle="text"
                                :translated="true"
                                @change="handleAutocompleteChange"
                                @update:modelValue="handleAutocompleteUpdate"
                            >
                            </Input>
                        </v-col>
                        <v-col md="6">
                            model_data : <pre><small>{{ city }}</small></pre><br>
                        </v-col>
                    </v-row>

                    <!--
                    <hr>

                    <h2>Mails</h2>
                    <br>

                    <v-form
                        ref="form"
                    >
                        <v-container>
                            <v-row align="center">
                                <v-text-field
                                    v-model="firstname"
                                    label="Firtstname"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="lastname"
                                    label="Lastname"
                                    required
                                ></v-text-field>
                                <v-btn
                                    :disabled="firstname.length === 0 || lastname.length === 0"
                                    @click="sendEmail"
                                >Send email</v-btn>
                            </v-row>
                        </v-container>
                    </v-form>

                    <v-alert v-if="!!mailResponse" color="info" icon="icon-help">
                        {{ mailResponse }}
                    </v-alert>
                    -->

                    <!--
                    <br>
                    <hr>
                    <br>

                    <h2>Downloads</h2>
                    <br>

                    <v-btn @click="refreshDownloads">Refresh</v-btn>
                    <TransitionGroup name="list" tag="ul" :class="[$style['list-module'], 'list-scoped']">
                        <li v-for="download in downloads"
                            :key="download.id"
                        >
                            <div>{{ download.name }} :</div>
                            <a download v-bind:href="download.url">Download</a>
                        </li>
                    </TransitionGroup>
                    -->
                </div>
            </transition>
        </PageContent>

    </div>
</template>

<script>
import axios from "axios";

import PageHeader from "@/components/commons/PageHeader.vue"
import PageContent from "@/components/commons/PageContent.vue"
import Input from "@/components/commons/Input.vue"

import EstablishmentsService from '@/middleware/establishments'

import { autocompleteCity } from '@/helpers.autocomplete'

export default {
    components: { PageHeader, PageContent, Input },

    data() {
        return {
            firstname: '',
            lastname: '',
            mailResponse: null,
            downloads: [],

            // items_basic: [
            //     'test1',
            //     'test2',
            //     'test3',
            // ],
            // items_object: [
            //     {'@id': '/api/test/1', 'name': 'test1'},
            //     {'@id': '/api/test/2', 'name': 'test2'},
            //     {'@id': '/api/test/3', 'name': 'test3'},
            // ],
            // items_translated: [
            //     {'@id': '/api/test/1', 'translations': { 'fr' : { 'name' : 'test1 fr'} } },
            //     {'@id': '/api/test/2', 'translations': { 'fr' : { 'name' : 'test2 fr'} } },
            //     {'@id': '/api/test/3', 'translations': { 'fr' : { 'name' : 'test3 fr'} } },
            // ],
            // items_translated_delayed: [],
            // loaded_data_iri: '/api/test/3',
            // loaded_data_object: {
            //     '@id': '/api/test/3',
            //     'name': 'test3',
            // },
            // model_data: null,
            // model_raw: null,

            // undertakerFuneralHome: null,
            undertakerFuneralHome: '/api/establishments/44',
            city: {
                '@id': '/api/cities/29393',
                'text': 'Annecy (74000)'
            },
            // undertakerFuneralHome: {
            //     "@id": "/api/establishments/44",
            //     "id": 44,
            //     "text": "Funérarium Phasellus",
            //     "fullAddress": "Phasellus vitae eros dignissim, commodo augue, 74000 Annecy",
            //     "translations": {
            //         "fr": {
            //             "name": "Funérarium Phasellus"
            //         }
            //     },
            //     "name": "Funérarium Phasellus"
            // },
            undertakerFuneralHomeRaw: null,
            undertakerFuneralHomes: [],
            isUndertakerFuneralHomesLoaded: false,
            isLoaded: false,
        }
    },
    mounted() {

        this.loadFakeData()

        this.refreshDownloads()

    },
    methods: {
        /* region [imported method binding] */

        autocompleteCity: autocompleteCity,

        /* endregion */

        handleUpdate(value) {
            console.log(value)
        },

        handleAutocompleteChange(value) {
            console.log('handleAutocompleteChange', value)
        },

        handleAutocompleteUpdate(value) {
            console.log('handleAutocompleteUpdate', value)
        },


        loadFakeData() {

            setTimeout(function () {

                // this.undertakerFuneralHome = '/api/establishments/44'
                // this.undertakerFuneralHome = {
                //     "@id": "/api/establishments/44",
                //     "id": 44,
                //     "text": "Funérarium Phasellus",
                //     "fullAddress": "Phasellus vitae eros dignissim, commodo augue, 74000 Annecy",
                //     "translations": {
                //         "fr": {
                //             "name": "Funérarium Phasellus"
                //         }
                //     },
                //     "name": "Funérarium Phasellus"
                // }
            }.bind(this), 0)

            setTimeout(function () {

                // Retrieve the undertaker funeral homes (no restriction on cities)
                EstablishmentsService.getFilteredCustom('funeral-home', null, 2)
                .then((response) => {
                    this.undertakerFuneralHomes = this.$extract(response)
                    this.isUndertakerFuneralHomesLoaded = true
                })
                .finally(() => {
                    this.isLoaded = true
                })

            }.bind(this), 1000)

            //
            //
            // setTimeout(function () {
            //
            //     this.model_data = this.loaded_data_object
            //     // this.model_data = this.loaded_data_iri
            //
            // }.bind(this), 1000)
            //
            //
            // setTimeout(function () {
            //
            //     this.items_translated_delayed = this.items_translated
            //
            // }.bind(this), 4000)
            //
            //
            // setTimeout(function () {
            //
            //     // this.items_translated = []
            //     // this.model_data = null
            //
            //
            // }.bind(this), 6000)

            console.log('loadedFakeData')
        },

        refreshDownloads() {
            axios.get('/api/downloads').then((response) => {
                console.log(response)
                this.downloads = this.$extract(response)
            })
        },
        sendEmail() {
            axios.post('/api/mail', {
                firstname: this.firstname,
                lastname: this.lastname,
                email: 'aurelien@pure-illusion.com',
            }).then((response) => {
                this.mailResponse = response.data.message
            })
        },
    },
}
</script>

<style lang="scss" module>
    .list-module{
        border: 1px solid red;
    }
</style>

<style lang="scss" scoped>
    .list-scoped{
        background: cyan;

        list-style-type: none;
        //display: grid;
        //grid-template-columns: repeat(10, 1fr);


        img{
            height: 80px;
        }
    }
</style>



