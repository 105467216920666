<template>
    <TemplateLogin>
        <template v-slot:picture>
            <img src="@images/splash-1.jpg">
        </template>

        <template v-slot:content>

            <div class="container container-large">

                <div class="title h1">{{ $t('app.page.user_register.title') }}</div>

                <div class="subtitle">{{ $t('app.page.user_register.subtitle') }}</div>

                <Form
                    @valid="handleValid"
                    :data="data"
                    :validations="validations"
                    v-slot="{ isFilled }"
                >

                    <Input
                        :cols="6"
                        :label="$t('app.page.user_register.form.lastname.label')"
                        :placeholder="$t('app.page.user_register.form.lastname.placeholder')"
                        v-model="data.lastname"
                        path="data.lastname"
                        required
                        autocomplete="family-name"
                    >
                    </Input>

                    <Input
                        :cols="6"
                        :label="$t('app.page.user_register.form.firstname.label')"
                        :placeholder="$t('app.page.user_register.form.firstname.placeholder')"
                        v-model="data.firstname"
                        path="data.firstname"
                        required
                        autocomplete="given-name"
                    >
                    </Input>

                    <Input
                        :cols="12"
                        :label="$t('app.page.user_register.form.company.label')"
                        :placeholder="$t('app.page.user_register.form.company.placeholder')"
                        v-model="data.company"
                        path="data.company"
                        required
                        autocomplete="organization"
                    >
                    </Input>

                    <Input
                        :cols="6"
                        :label="$t('app.page.user_register.form.email.label')"
                        :placeholder="$t('app.page.user_register.form.email.placeholder')"
                        v-model="data.email"
                        path="data.email"
                        required
                        autocomplete="email"
                    >
                    </Input>

                    <Input
                        type="InputPhone"
                        :cols="6"
                        :label="$t('app.page.user_register.form.phone.label')"
                        :placeholder="$t('app.page.user_register.form.phone.placeholder')"
                        v-model="data.phone"
                        path="data.phone"
                        required
                        autocomplete="tel"
                    >
                    </Input>

                    <v-col md="12">
                        <div class="text-notice">
                            {{ $t('app.global.messages.required_fields') }}
                        </div>
                    </v-col>

                    <v-col md="12" class="text-center">
                        <v-btn
                            class="button"
                            type="submit"
                            :disabled="!isFilled || isWaiting"
                        >
                            {{ $t('app.page.user_register.form.button_submit') }}
                        </v-btn>

                        <div>
                            <v-btn class="link" :href="$t('app.page.user_register.privacy.link')" target="_blank">
                                {{ $t('app.page.user_register.privacy.text') }}
                            </v-btn>
                        </div>

                    </v-col>

                </Form>

            </div>

            <div class="container container-large container-dense">

                <div class="text">
                    {{ $t('app.page.user_login.access_text') }}
                </div>

                <v-btn :to="{ name: 'login' }" class="button button-secondary">
                    {{ $t('app.page.user_login.access_button') }}
                </v-btn>

            </div>

        </template>
    </TemplateLogin>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store'
import { useMessageStore } from '@/stores/message.store'

import { required, email, maxLength } from '@/helpers.validators'

import TemplateLogin from "@/components/commons/TemplateLogin.vue"
import Form from "@/components/commons/Form.vue"
import Input from "@/components/commons/Input.vue"

import UserRegistrationsService from '@/middleware/userRegistrations'
import GlobalService from '@/middleware/global'

export default {

    components: { TemplateLogin, Form, Input },

    setup() {
        const authStore = useAuthStore()
        const messageStore = useMessageStore()

        return {
            authStore,
            messageStore,
        }
    },

    data() {
        return {
            data: {
                lastname: null,
                firstname: null,
                company: null,
                email: null,
                phone: null,
            },
            validations : {
                data: {
                    lastname: { required, maxLength: maxLength(50) },
                    firstname: { required, maxLength: maxLength(50) },
                    company: { required, maxLength: maxLength(255) },
                    email: { required, email, maxLength: maxLength(255) },
                    phone: { required },
                },
            },
            isWaiting: false,
        }
    },

    methods: {

        handleValid(){
            this.isWaiting = true
            UserRegistrationsService.create(this.data)
            .then((response) => {
                this.messageStore.addInfo(this.$t('app.page.user_register.notice_success'))

                GlobalService.sendEmailRegistration(
                    this.data.firstname,
                    this.data.lastname,
                    this.data.company,
                    this.data.email,
                    this.data.phone,
                )
                .then((response) => {
                    this.$router.push({ name: 'login' })
                })
                .finally(() => {
                    this.isWaiting = false
                });
            })
        },

    },

}
</script>

<style lang="scss" scoped>

.link{
    margin-top: 20px;
}

</style>

