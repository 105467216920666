import { useAuthStore } from '@/stores/auth.store.js';
import { useGlobalStore } from '@/stores/global.store.js';
import { useDisplay } from 'vuetify';

export default {
    install: (app, options) => {

        // To make injections automatically unwrap computed refs. This will become the default behavior in Vue 3.3.
        app.config.unwrapInjectedRef = true

        // Helper function that extract the result from an API Platform response
        app.config.globalProperties.$extract = (response) => {
            if(response && response.data){
                if(response.data['hydra:member']){ // If it's an hydra response (the HTTP header Accept = application/ld+json) (default)
                    return response.data['hydra:member']
                }
                else{ // If it's a basic response (the HTTP header Accept = application/json)
                    return response.data;
                }
            }
            else{
                console.warn('helper $extract : no data found, returning the raw response')
                return response
            }
        }

        // Helper function that check if the user has the required role (useful for conditional display based on roles)
        app.config.globalProperties.$isRoleGranted = (role) => {
            const authStore = useAuthStore()
            return (authStore.isRoleGranted(role));
        }

        // Helper function that init the translation object and all the locales to prevent form issues and doctrine issues when adding a new item
        app.config.globalProperties.$initTranslations = () => {
            const globalStore = useGlobalStore()

            let translations = {}
            for (const [index, locale] of globalStore.locales.entries()) {
                translations[locale] = {}
                translations[locale]['locale'] = locale
            }

            return translations
        }

        // Helper function that look for the current locale translation of a specific key in the translation structure of an object. If no value is found for the current locale, the value of the fallback locale is returned.
        app.config.globalProperties.$trans = (object, key) => {

            let translation = null

            if(object && object['translations']){
                // Translation is found in the current locale
                if(object['translations'][app.config.globalProperties.$i18n.locale]){
                    return object['translations'][app.config.globalProperties.$i18n.locale][key]
                }
                // Translation is found in the fallback locale
                else if(object['translations'][app.config.globalProperties.$i18n.fallbackLocale]){
                    return object['translations'][app.config.globalProperties.$i18n.fallbackLocale][key]
                }
                else {
                    console.warn("No translation found for key", key, "in object", object)
                }
            }
            else {
                console.warn("No translations structure found in object", object)
            }

            return translation
        }

        // Helper function that apply highlighting on specific characters. It must always be used inside a tag with the v-html directive
        // Usage : <span v-html="this.$highlight($t('app.test'))"></span>
        const highlightDelimiters = {
            '\\(\\(\\(': '<mark>',
            '\\)\\)\\)': '</mark>',
            '\\[\\[\\[': '<mark class="alt">',
            '\\]\\]\\]': '</mark>',
        };
        app.config.globalProperties.$highlight = (text) => {
            for (const [delimiter, replacement] of Object.entries(highlightDelimiters)) {
                text = text.replace(new RegExp(delimiter, 'g'), replacement);
            }
            return text;
        }

        app.config.globalProperties.$extractIdFromIri = (iri) => {
            return iri ? parseInt(iri.match(/\d+$/)[0]) : null
        }

        // Check if the variable is null or empty
        app.config.globalProperties.$isNullOrEmpty = (value, field = null) => {
            return value === null
                || value === undefined
                || (typeof value === 'string' && value.trim() === '')
                || (typeof value === 'object' && !Object.values(value).every(translation => translation.hasOwnProperty(field) && translation[field] != null && translation[field].trim() !== ''))
        }

        app.config.globalProperties.$isSmallScreen = () => {
            const { mdAndUp: isMdAndUp } = useDisplay();
            return !isMdAndUp.value
        }

    }
}