<template>
    <v-form
        class="form"
        @submit.stop.prevent="submit"
        @keyup.native.enter="submit"
        @input="v$.$touch()"
    >
        <v-row
            v-if="!noRow"
            :no-gutters="noGutters"
        >
            <slot :isFilled="isFilled"></slot>
        </v-row>
        <slot v-else :isFilled="isFilled"></slot>

    </v-form>
    <pre style="display:none;"><small>{{ v$.$errors }}</small></pre>
</template>

<script>
import { computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'

export default {

    emits: ['mounted', 'valid'],

    props: {
        data: { // NOTE : it's important that the object passed on the props 'data' is also called 'data', since vuelidate will look for an object called data
            required: true,
        },
        validations: {
            required: true,
        },
        noGutters: {
            required: false,
            default: false,
        },
        noRow: {
            required: false,
            default: false,
        },
    },

    setup() {
        return {
            v$: useVuelidate(),
        }
    },

    mounted() {
        this.$emit('mounted', computed(() => this.v$));
    },

    provide() {
        return {
            v$: computed(() => this.v$) // To make injections reactively linked to the provider, we need to provide a computed property using the computed() function + To make injections automatically unwrap computed refs in Vue < 3.3, we need to set app.config.unwrapInjectedRef to true.
        }
    },

    validations() {
        return this.validations
    },

    computed: {

        // Helper to lock the submit buttons when the form is not filled, it will be forwarded via the slot and will be available in the parent component
        isFilled() {
            return !this.v$.$invalid
        },

    },
    methods: {

        // Form validation
        async submit (event) {
            const isValid = await this.v$.$validate()
            if (isValid) {
                this.$emit('valid'); // Emit an event when the form is valid
            }
        },

    },
}
</script>

<style lang="scss" scoped>

</style>
