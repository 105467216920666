import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {
    get: (id) => {
        return axios.get(`${fosRouter.generate('api_undertaker_establishments_get_item')}/${id}`)
    },
    create: (data) => {
        return axios.post(fosRouter.generate('api_undertaker_establishments_post_collection'), data)
    },
    update: (id, data) => {
        return axios.put(`${fosRouter.generate('api_undertaker_establishments_put_item')}/${id}`, data)
    },
    delete: (id) => {
        return axios.delete(`${fosRouter.generate('api_undertaker_establishments_delete_item')}/${id}`)
    },
}