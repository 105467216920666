<template>
    <v-col :md="cols">
        <div
            class="field"
            :class="{ 'required': required, 'important': important ? $isNullOrEmpty(modelLocal, $attrs['field']) : false }"
        >
            <v-label v-if="label">{{ label }}</v-label>

            <component
                :is="type"
                v-model="modelLocal"
                :placeholder="placeholder"
                :error-messages="getErrors()"
                :required="required"
                :autocomplete="autocomplete"
                :path="path"
                v-bind="$attrs"
                @change="v$?.$touch()"
            ></component>

        </div>
    </v-col>
</template>

<script>
import InputText from "@/components/commons/InputText.vue"
import InputTextarea from "@/components/commons/InputTextarea.vue"
import InputPhone from "@/components/commons/InputPhone.vue"
import InputPassword from "@/components/commons/InputPassword.vue"
import InputPasswordSecure from "@/components/commons/InputPasswordSecure.vue"
import InputTranslation from "@/components/commons/InputTranslation.vue"
import InputAutocomplete from "@/components/commons/InputAutocomplete.vue"
import InputUpload from "@/components/commons/InputUpload.vue"
import InputSelect from "@/components/commons/InputSelect.vue"
import InputDate from "@/components/commons/InputDate.vue"
import InputTime from "@/components/commons/InputTime.vue"

export default {

    inject: ['v$'],

    emits: ['update:modelValue'],

    components: { InputText, InputTextarea, InputPhone, InputPassword, InputPasswordSecure, InputTranslation, InputAutocomplete, InputUpload, InputSelect, InputDate, InputTime },

    props: {
        modelValue: {
            required: false,
        },
        type: {
            required: false,
            type: String,
            default: "InputText"
        },
        cols: {
            required: false,
            type: Number,
            default: 12,
        },
        label: {
            required: false,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
            default: "",
        },
        path: {
            required: false,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
            default: false,
        },
        important: {
            required: false,
            type: Boolean,
            default: false,
        },
        autocomplete: {
            required: false,
            type: String,
        },
    },

    computed: {

        modelLocal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            },
        },

    },

    methods: {

        getErrors(){
            let errors = []

            // If the errors object is available
            if(this.path && this.v$.$errors){

                // Look for an error corresponding to the current input key
                errors = this.v$.$errors.filter((item)=> item.$propertyPath === this.path).map(e => e.$message)
            }

            return errors
        }

    }

}
</script>
