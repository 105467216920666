import axios from "axios"
import fosRouter from '@/routers/api.router'

export default {
    upload: (data) => {
        return axios.post(fosRouter.generate('_uploader_upload_media'), data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        )
    },
}