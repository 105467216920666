<template>
    <TemplateLogin>
        <template v-slot:picture>
            <img src="@images/splash-1.jpg">
        </template>

        <template v-slot:content>

            <div class="container">

                <div class="title h1">{{ $t('app.page.password_change.title') }}</div>

                <div class="subtitle">{{ $t('app.page.password_change.subtitle') }}</div>

                <Form
                    @valid="handleValid"
                    :data="data"
                    :validations="validations"
                    v-slot="{ isFilled }"
                >
                    <Input
                        type="InputPasswordSecure"
                        :cols="12"
                        :label="$t('app.page.password_change.form.password.label')"
                        :placeholder="$t('app.page.password_change.form.password.placeholder')"
                        v-model="data.password"
                        path="data.password"
                        required
                        autocomplete="new-password"
                    >
                    </Input>

                    <v-col md="12" class="text-center">
                        <v-btn
                            class="button"
                            type="submit"
                            :disabled="!isFilled || isWaiting"
                        >
                            {{ $t('app.page.password_change.form.button_submit') }}
                        </v-btn>

                    </v-col>

                </Form>

            </div>

        </template>
    </TemplateLogin>
</template>

<script>
import { useMessageStore } from '@/stores/message.store'

import { required, maxLength } from '@/helpers.validators'

import TemplateLogin from "@/components/commons/TemplateLogin.vue"
import Form from "@/components/commons/Form.vue"
import Input from "@/components/commons/Input.vue"

import UsersService from '@/middleware/users'

export default {

    // Navigation guard to validate the token passed in parameter
    beforeRouteEnter (to, from, next) {

        // Validate the token format, redirect if not valid
        const token = to.query.token
        if (!token || !token.match(/^[a-f0-9]{32}$/gi)) { // Regex that match a 32 characters hexadecimal string
            // Because this navigation guard is called before the component is created, we access to component's instance using `vm`.
            next(vm => {
                vm.$router.push({ name: 'login' })
            })
        }
        else {
            // Valid token, normal process
            next()
        }
    },

    components: { TemplateLogin, Form, Input },

    setup() {
        const messageStore = useMessageStore()

        return {
            messageStore,
        }
    },

    props: {
        token: null,
    },

    data() {
        return {
            data: {
                password: null,
            },
            validations : {
                data: {
                    password: { required, maxLength: maxLength(255) },
                },
            },
            isWaiting: false,
        }
    },

    methods: {

        handleValid(){
            this.isWaiting = true
            UsersService.changePassword(this.token, this.data)
            .then((response) => {
                this.messageStore.addInfo(this.$t('app.page.password_change.notice_success'))
                this.$router.push({ name: 'login' })
            })
            .finally(() => {
                this.isWaiting = false
            });
        },

    },
}
</script>

<style lang="scss" scoped>

</style>
