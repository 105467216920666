import { convertTranslationKeys, objectDeepMerge } from './helpers.global'

export const locales = ['fr'] // Define available locales
export const defaultLocale = locales[0] // Define the default locale
let messages = {} // Will contain translations

// Init the messages array with locales
for (const [index, locale] of locales.entries()) {
    if (!messages.hasOwnProperty(locale)) {
        messages[locale] = {}
    }
}

// Load vuetify translations
// NOTE : those translation could be override in symfony translations (see below)
import { fr } from 'vuetify/locale';
messages.fr.$vuetify = fr

// Load translation catalogues from symfony
// NOTE : that the phrases containing a dot character will not be supported as keys, since the dot notation is used for nested object. (ex : $t('Error.') will not work)
messages = objectDeepMerge(messages, convertTranslationKeys(window.sf.translations))

export const i18nConfig = {
    legacy: false, // Vuetify does not support the legacy mode of vue-i18n
    locale: defaultLocale, // the current locale
    fallbackLocale: defaultLocale, // the fallback locale
    messages: messages, // translation catalogue
}


